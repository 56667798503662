import { useState, useCallback } from 'react';

/**
 * @param {string | number} text
 */
const useTruncated = (text: string | number):
  [(node: HTMLDivElement) => void, boolean | undefined] => {
  const [truncated, setTruncated] = useState(false);
  const ref = useCallback(
    (node: { scrollWidth: number; offsetWidth: number; }) => {
      if (!node) return;
      setTruncated(node.scrollWidth > node.offsetWidth);
    }, [text]
  );
  return [ref, truncated];
};

export default useTruncated;
