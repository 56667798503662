import { FC, useContext, useEffect, useState } from "react";
import { API, graphqlOperation } from 'aws-amplify';
import { corporateDashboardDataByUploadId } from 'graphql/queries';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SessionTimeoutMsg, viewCorporateListData } from 'App';
import { RISK_RANGE, RATING_RANGE } from 'constant';
import checkExpiredStatus from 'shared/checkExpiredStatus';
import RatingRiskCrossTab from 'pages/dashboard/RatingRiskCrossTab';
import RatingDistributionTab from 'pages/dashboard/RatingDistributionTab';

const Dashboard: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  /** 企業情報総数 */
  const [corporateSum, setCorporateSum] = useState<number>();
  /** 成形後の企業情報リスト */
  const [scoreDistribution, setScoreDistribution] = useState<CorporatesScoreDistribution[]>([]);
  // セッションタイムアウトメッセージ
  const { hidden, setHidden } = useContext(SessionTimeoutMsg);
  const [barDataNumber, setBarDataNumber] = useState<any>();
  const [pieDataNumber, setPieDataNumber] = useState<any>();
  /** 納品番号 */
  const id = searchParams.get("id");
  /** アップロードID */
  const uploadId = searchParams.get("uploadId");
  /** 商品名 */
  const productName = searchParams.get("productName");
  /** 納品日付 */
  const deliveredDate = searchParams.get("deliveredDate");
  /** 有効期限日 */
  const expireDate = searchParams.get("expireDate");
  /** 企業データ一覧画面、検索結果表示固定 */
  const { viewCorporateList, setViewCorporateList } = useContext(viewCorporateListData)
  /** 企業データ初期化 */
  useEffect(() => {
    // 企業データ一覧画面、検索結果表示固定を初期化F
    setViewCorporateList("")
    /** 企業データのロード */
    const getCorporatesData = async () => {
      // セッションタイムアウトエラーメッセージを非表示
      setHidden('hidden');
      if (!(id && uploadId && productName && deliveredDate && expireDate) || checkExpiredStatus(expireDate)) {
        navigate('/error');
        return;
      };
      // 企業データ取得
      let response;
      let resData: CorporateDashboardData;
      try {
        response = await API.graphql(graphqlOperation(corporateDashboardDataByUploadId,
          {
            uploadId: uploadId,
            limit: 1000000,
          }
        ));
        // @ts-ignore
        resData = response.data.corporateDashboardDataByUploadId.items[0];
        if (resData === undefined) {
          navigate('/error');
          return;
        }
      } catch (e) {
        navigate('/error');
        return;
      }
      setCorporateSum(resData.sum);
      createTableData(resData);
      ratingGraphData(resData);
    }
    getCorporatesData();
  }, []);

  /** 評点×リスクスコアテーブル用企業データ成形 */
  const createTableData = (resData: CorporateDashboardData) => {
    const distributionList: CorporatesScoreDistribution[] = [];
    const resDataList = [
      resData.crossNoRatingNoScore,
      resData.crossNoRatingScore110,
      resData.crossNoRatingScore1120,
      resData.crossNoRatingScore2149,
      resData.crossNoRatingScore50100,
      resData.crossRating039NoScore,
      resData.crossRating039Score110,
      resData.crossRating039Score1120,
      resData.crossRating039Score2149,
      resData.crossRating039Score50100,
      resData.crossRating4044NoScore,
      resData.crossRating4044Score110,
      resData.crossRating4044Score1120,
      resData.crossRating4044Score2149,
      resData.crossRating4044Score50100,
      resData.crossRating4549NoScore,
      resData.crossRating4549Score110,
      resData.crossRating4549Score1120,
      resData.crossRating4549Score2149,
      resData.crossRating4549Score50100,
      resData.crossRating5064NoScore,
      resData.crossRating5064Score110,
      resData.crossRating5064Score1120,
      resData.crossRating5064Score2149,
      resData.crossRating5064Score50100,
      resData.crossRating65100NoScore,
      resData.crossRating65100Score110,
      resData.crossRating65100Score1120,
      resData.crossRating65100Score2149,
      resData.crossRating65100Score50100
    ];
    // データフォーマット作成
    RATING_RANGE.forEach((rating) => {
      RISK_RANGE.forEach((risk) => {
        distributionList.push({
          ratingRange: rating,
          riskRange: risk,
          rangeSum: 0
        })
      })
    })
    // データ投入
    distributionList.forEach((distribution, index) => {
      distribution.rangeSum = resDataList[index];
    })
    setScoreDistribution(distributionList);
  }

  /** 評点分布用企業データ成形 */
  const ratingGraphData = (resData: CorporateDashboardData) => {
    const noData = resData.distributionNoData;
    const data09 = resData.distributionData09;
    const data1019 = resData.distributionData1019;
    const data2029 = resData.distributionData2029;
    const data3039 = resData.distributionData3039;
    const data4044 = resData.distributionData4044;
    const data4549 = resData.distributionData4549;
    const data5054 = resData.distributionData5054;
    const data5559 = resData.distributionData5559;
    const data6069 = resData.distributionData6069;
    const data7079 = resData.distributionData7079;
    const data8089 = resData.distributionData8089;
    const data90100 = resData.distributionData90100;
    const data4049 = resData.distributionData4044 + resData.distributionData4549;
    const data5059 = resData.distributionData5054 + resData.distributionData5559;
    // 棒グラフデータ
    const barData = [noData, data09, data1019, data2029, data3039, data4049, data5059, data6069, data7079, data8089, data90100];
    // 円グラフデータ、パーセンテージ整形
    const moldingNum = 100
    const pieData = [
      Math.trunc(noData * 10 / resData.sum * moldingNum) / 10,
      Math.trunc(data09 * 10 / resData.sum * moldingNum) / 10,
      Math.trunc(data1019 * 10 / resData.sum * moldingNum) / 10,
      Math.trunc(data2029 * 10 / resData.sum * moldingNum) / 10,
      Math.trunc(data3039 * 10 / resData.sum * moldingNum) / 10,
      Math.trunc(data4044 * 10 / resData.sum * moldingNum) / 10,
      Math.trunc(data4549 * 10 / resData.sum * moldingNum) / 10,
      Math.trunc(data5054 * 10 / resData.sum * moldingNum) / 10,
      Math.trunc(data5559 * 10 / resData.sum * moldingNum) / 10,
      Math.trunc(data6069 * 10 / resData.sum * moldingNum) / 10,
      Math.trunc(data7079 * 10 / resData.sum * moldingNum) / 10,
      Math.trunc(data8089 * 10 / resData.sum * moldingNum) / 10,
      Math.trunc(data90100 * 10 / resData.sum * moldingNum) / 10
    ];
    setBarDataNumber(barData);
    setPieDataNumber(pieData);
  };

  /* ------------------------- 
    components
  ----------------------------*/
  /* TabsArea */
  const TabsArea: FC = () => {
    const contents = [
      {
        text: "評点分布",
        component: <RatingDistributionTab barDataNumber={barDataNumber} pieDataNumber={pieDataNumber} />
      },
      {
        text: "評点×リスクスコア",
        component: <RatingRiskCrossTab corporateSum={corporateSum} scoreDistribution={scoreDistribution} />
      }
    ]
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    return (
      <>
        <nav className="tabs mx-auto lg:mx-10">
          {contents.map((content, index) => {
            return (
              <button
                key={index}
                className={`tab font-medium
                  ${index === activeTabIndex ? "tab-active bg-gray-200" : ""}
                `}
                onClick={() => setActiveTabIndex(index)}
              >
                {content.text}
              </button>
            )
          })}
        </nav>
        <div className="h-fit border-y-8 border-x-[15px] border-gray-200 mx-auto lg:ml-10">
          {contents[activeTabIndex].component}
        </div>
      </>
    )
  }
  return (
    <>
      <div className="w-11/12 my-10 mx-auto lg:mx-10">
        <div className="lg:flex mb-12 lg:mb-0">
          <div className="mb-5 lg:mb-8 mx-auto lg:mx-10">
            <span>企業データ件数</span>
            <span className="font-bold text-xl ml-4">{corporateSum}件</span>
          </div>
          <div className="flex flex-col md:flex-row mr-0 ml-auto gap-4 my-3 lg:my-0">
            <button
              tabIndex={-1}
              className="btn btn-sm w-[17rem] text-white flex-0 rounded-full border-none bg-gradient-to-b from-main to-main-to"
              onClick={() => navigate(`/index/?id=${id}&uploadId=${uploadId}&productName=${productName}&deliveredDate=${deliveredDate}&expireDate=${expireDate}`)}>
              <span className="material-symbols-outlined pr-1 md:pr-3 ">expand_circle_right</span>
              <span>商品ダウンロード画面に戻る</span>
            </button>
            <button
              tabIndex={-1}
              className="btn btn-sm w-[17rem] text-white flex-0 rounded-full border-none bg-gradient-to-b from-main to-main-to"
              onClick={() =>
                navigate(
                  `/corporatelist/?id=${id}&uploadId=${uploadId}&productName=${productName}&deliveredDate=${deliveredDate}&expireDate=${expireDate}`,
                  { state: { ratingRange: null, riskRange: null } }
                )
              }>
              <span className="material-symbols-outlined pr-1 md:pr-3 ">database</span>
              <span>企業データ一覧画面に戻る</span>
            </button>
          </div>
        </div>
        <TabsArea />
      </div>
    </>
  );
}

export default Dashboard;