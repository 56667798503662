const LogoutIcon = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24.596" height="26.706" viewBox="0 0 24.596 26.706">
            <g id="グループ_2199" data-name="グループ 2199" transform="translate(-1040.603 -117.031)">
                <path id="パス_1786" data-name="パス 1786" d="M1065.2,130.363a.981.981,0,0,0-.358-.751l-4.909-4.91a1,1,0,0,0-1.414,1.414l3.246,3.247h-10.92a1,1,0,0,0,0,2h10.962l-3.288,3.289a1,1,0,1,0,1.414,1.414l4.974-4.975a.994.994,0,0,0,.291-.718Z" fill="#fff" />
                <path id="パス_1787" data-name="パス 1787" d="M1060.076,138.472a1,1,0,0,0-1,1v1.068a1.193,1.193,0,0,1-1.2,1.2H1043.8a1.2,1.2,0,0,1-1.195-1.2V120.231a1.2,1.2,0,0,1,1.2-1.2h14.075a1.2,1.2,0,0,1,1.2,1.2V121.4a1,1,0,0,0,2,0v-1.169a3.2,3.2,0,0,0-3.2-3.2H1043.8a3.2,3.2,0,0,0-3.2,3.2v20.318a3.2,3.2,0,0,0,3.192,3.188h14.094a3.194,3.194,0,0,0,3.187-3.2v-1.065A1,1,0,0,0,1060.076,138.472Z" fill="#fff" />
            </g>
        </svg>
    )
}

export default LogoutIcon


