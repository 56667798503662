import { NOT_PURCHASED } from "../constant";

/** 数値にカンマ付与 */
const formatNumberToLocaleString = (num: number | null) => {
  let formattedNum = null;
  if (num === NOT_PURCHASED) {
    formattedNum = "";
  } else if (num !== null) {
    formattedNum = num.toLocaleString();
  }
  return formattedNum;
}
export default formatNumberToLocaleString;
