import React, { FC, useContext, useRef, useState } from 'react';
import dayjs from 'shared/dayjs'
import { API, Auth, graphqlOperation, Storage } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { updateDeliverInfo, updateFileInfo } from 'graphql/mutations';
import { useNavigate } from 'react-router-dom';
import { DOUBLE_CLICK_WAIT, DOWNLOAD_STATUS } from 'constant';
import { getDeliverInfo, getFileInfo } from 'graphql/queries';
import executeApi from "shared/executeApi";
import { SessionTimeoutMsg } from 'App';

export type Props = {
  bulkDownloadTrigger: boolean,
  setBulkDownloadTrigger: React.Dispatch<React.SetStateAction<boolean>>,
  selected: DeliverInfo | undefined,
  files: FileInfo[],
  setFiles: React.Dispatch<React.SetStateAction<FileInfo[]>>;
  downloadAuthority: any,
};
type paletteButtonProps = {
  icon: string;
  text: string;
  handleClick: () => void
}

/** ファイル情報リスト */
const FileList: FC<Props> = (props) => {
  const navigate = useNavigate();
  // ログインユーザー情報
  const { user } = useAuthenticator(context => [context.user]);
  const session = user.getSignInUserSession();
  const token = session?.getIdToken().getJwtToken();
  // パレット対象
  const paletteTarget = props.selected?.paletteTarget;
  // セッションタイムアウトメッセージ
  const { hidden, setHidden } = useContext(SessionTimeoutMsg);
  // ダブルクリック制御用
  const clickBulkDownload = useRef(false);
  const [clickFileDownload, setClickFileDownload] = useState<boolean>(false);
  // ファイルダウンロード状況
  const downloadStatusList: boolean[] = props.files.map(
    (file) => (file.downloadStatus === DOWNLOAD_STATUS.DOWNLOADED) ? true : false
  );
  const downloadStatus = downloadStatusList.every(status => status === true) && downloadStatusList.length > 0;

  /** ファイルダウンロード */
  const handleClickFile = async () => {
    let sessionToken: any
    // セッションタイムアウトチェック
    try {
      sessionToken = await Auth.currentSession();
    } catch (noSession) {
      setHidden('');
      navigate("/");
      return false;
    }
    // ダブルクリック制御
    if (clickFileDownload) return false;
    setClickFileDownload(true);
    // API実行
    const baseURL = process.env.REACT_APP_DASHBOARD_API_ROOT!;
    const path = "/dashboad/document/dbplus/layout/execution";
    const response = await executeApi(baseURL, path, sessionToken.idToken.jwtToken, 1);
    if (!response || response.data.data.url === "") {
      navigate('/error');
      return;
    }
    // ダウンロード実行
    const url = response.data.data.url;
    let link = document.createElement('a');
    link.download = url;
    link.href = url;
    link.click();
    // ダブルクリック制御解除
    setClickFileDownload(false);
  };

  /** Palette用ボタン */
  const PaletteButton: FC<paletteButtonProps> = ({ icon, text, handleClick }) => {
    return (
      <button tabIndex={-1}
        className={`flex items-center rounded-none border-none w-48 h-fit text-white flex-0 ${props.downloadAuthority ? downloadStatus ? "bg-main" : "bg-inapplicable" : "bg-main"}`}
        onClick={() => handleClick()}
        disabled={props.downloadAuthority ? downloadStatus === false ? true : false : false}>
        <span className={`material-symbols-outlined p-1.5 ml-7 my-0.5  ${props.downloadAuthority ? downloadStatus ? "bg-main" : "bg-inapplicable" : "bg-main"}`}>
          {icon}
        </span>
        <span className='text-sm ml-1'>{text}</span>
      </button>
    )
  };

  /** 初回ダウンロードの場合ファイル情報を更新 */
  const updateFileDownloadDate = async (downloaded: FileInfo) => {
    if (downloaded.downloadStatus === DOWNLOAD_STATUS.DOWNLOADED) {
      return 0;
    }
    const now = new Date();
    // ファイル情報のバージョンを再取得
    const res = await API.graphql(graphqlOperation(getFileInfo, { id: downloaded.id }));
    // @ts-ignore
    const version = res.data.getFileInfo._version;
    const input = {
      id: downloaded.id,
      downloadStatus: '1',
      firstDownloadDate: dayjs(now).format('YYYYMMDD'),
      firstDownloadTime: dayjs(now).format('HHmmss'),
      firstDownloadDateTime: dayjs(now).format('YYYY/MM/DD HH:mm:ss'),
      _version: version
    };
    await API.graphql({ query: updateFileInfo, variables: { input: input } });
    // 取得日レンダリングのため更新
    downloaded.downloadStatus = DOWNLOAD_STATUS.DOWNLOADED;
    downloaded.firstDownloadDate = dayjs(now).format('YYYY/MM/DD');
    downloaded.version = version + 1;
    props.setFiles(
      props.files.map((org) => (org.id === downloaded.id) ? downloaded : org)
    );
    return 1;
  }

  /** ダウンロード通知を更新 */
  const updateNoticeDownload = async () => {
    if (!props.selected) {
      return;
    }
    // 納品情報のバージョンを再取得
    const res = await API.graphql(graphqlOperation(getDeliverInfo, { id: props.selected.id }));
    // @ts-ignore
    const version = res.data.getDeliverInfo._version;
    const input = {
      id: props.selected.id,
      noticeDownloaded: '1',
      _version: version
    };
    await API.graphql({ query: updateDeliverInfo, variables: { input: input } });
  }

  /** 一括ファイルダウンロード */
  const handleClickBulk = async (event: any) => {
    // セッションタイムアウトチェック
    try {
      await Auth.currentSession();
    } catch (noSession) {
      setHidden('');
      navigate("/");
      return false;
    }
    // ダブルクリック制御
    if (clickBulkDownload.current) return false;
    clickBulkDownload.current = true;
    setTimeout(() => clickBulkDownload.current = false, DOUBLE_CLICK_WAIT);

    if (props.selected?.productName) {
      // 納品日の整形
      const fileDownloadDeliveredDate = props.selected.deliveredDate.replaceAll('/', '')
      // 署名付きURL取得
      const urls: Promise<string> = Storage.get('Zip' + props.selected!.systemId! + props.selected!?.uploadId! + '/' + props.selected?.productName + '_' + fileDownloadDeliveredDate + '.zip', { level: "private", contentType: "application/octet-stream" });
      const link = document.createElement('a')
      link.download = (await urls).toString()
      link.href = (await urls).toString()
      link.click()

      // ファイル情報更新
      let existsFirst = false;
      props.files.forEach(file => {
        if (file.downloadStatus === DOWNLOAD_STATUS.NOT_DONE) {
          existsFirst = true;
        }
        updateFileDownloadDate(file);
      });
      if (existsFirst) {
        updateNoticeDownload();
      }
      // ダウンロード未/済ステータス更新
      props.setBulkDownloadTrigger(!props.bulkDownloadTrigger);
    }
  };

  /** 企業分布表示 */
  const handleClickDashboard = () => {
    navigate(`/dashboard/?id=${props.selected?.id}&uploadId=${props.selected?.uploadId}&productName=${props.selected?.productName}&deliveredDate=${props.selected?.deliveredDate}&expireDate=${props.selected?.expireDate}`);
  };
  /** 企業情報表示 */
  const handleClickCorporeteList = () => {
    navigate(
      `/corporatelist/?id=${props.selected?.id}&uploadId=${props.selected?.uploadId}&productName=${props.selected?.productName}&deliveredDate=${props.selected?.deliveredDate}&expireDate=${props.selected?.expireDate}`,
      { state: { ratingRange: null, riskRange: null } }
    );
  };

  return (
    <>
      {/** タブ */}
      <div className='flex bg-folder ml-5 p-1 pl-5 w-72 shadow-lg xl:ml-0.5' style={{ borderRadius: '10px 10px 0 0' }}>
        <span className='text-sm mt-0.5 w-32 truncate' title={props.selected?.productName}>{props.selected?.productName}</span>
        <span className='material-symbols-outlined'>navigate_next</span>
        <span className='text-sm mt-0.5'>ファイル一覧</span>
      </div>
      {/** ファイル一覧 */}
      <div className=' bg-folder ml-5 xl:ml-0.5 shadow-lg overflow-y-auto overflow-x-hidden' style={{ borderRadius: '0 5px 5px 5px', height: 476 }}>
        <div className='flex flex-col gap-4 md:gap-0 md:flex-row md:justify-between my-3 mx-4'>
          <button tabIndex={-1}
            disabled={!(props.downloadAuthority)}
            className={props.downloadAuthority ? "btn btn-sm w-64 flex-0 rounded-full border-none bg-gradient-to-b from-action-from to-action-to" : "btn btn-sm w-64 flex-0 rounded-full border-none bg-slate-300"}
            onClick={(event) => handleClickBulk(event)}>
            <span className="material-symbols-outlined text-white">
              file_download
            </span>
            <span className='ml-1 text-white'>ファイル一括ダウンロード</span>
          </button>
          {paletteTarget ?
            <p className='w-44 text-sm text-main font-bold h-fit'>企業情報ビューアー 対応</p> :
            <p className='w-44 text-sm text-inapplicable font-bold h-fit'>企業情報ビューアー 非対応</p>
          }
        </div>
        <div className="flex flex-col mb-10 mx-4">
          {props.files.map(file => {
            return (
              <div className='flex mr-2 mt-3 w-full items-center' key={file.id}>
                <div className='mx-1 text-base truncate' title={file.filename}>{file.filename}</div>
                <div className='ml-1 w-20 text-sm text-gray-500 whitespace-nowrap'>
                  {file.filesize}
                </div>
                {file.firstDownloadDate &&
                  <div className="badge text-xs w-28 ml-1.5 -mt-0.5 text-white bg-gray-500 border-gray-500">
                    <span className="w-24">{file.firstDownloadDate} 取得</span>
                  </div>}
              </div>
            )
          })}
        </div>
        {paletteTarget &&
          <div className="mx-4 mb-4">
            <div className="w-full border-b border-gray-400 mb-5"></div>
            <p className="text-sm text-main font-bold mb-3">企業情報ビューアー</p>
            <div className="flex flex-col md:flex-row gap-4 mb-3">
              <PaletteButton icon="equalizer" text="企業分布表示" handleClick={() => handleClickDashboard()} />
              <PaletteButton icon="equalizer" text="企業情報表示" handleClick={() => handleClickCorporeteList()} />
            </div>
            <p hidden={!(props.downloadAuthority)} className="text-sm mb-10">※ファイルをダウンロード後に利用可能となります</p>
            <div>
              <a tabIndex={-1} className='flex md:justify-end items-baseline cursor-pointer' onClick={() => handleClickFile()}>
                <span className="text-link material-symbols-outlined mb-0 mt-auto">
                  file_download
                </span>
                <span className="text-sm text-blue-700 ml-1 border-b border-blue-700">ファイルレイアウトダウンロード</span>
              </a>
            </div>
          </div>
        }
      </div>
    </>
  );
}

export default FileList;
