const getCurrentDateTime = () => {
    // ファイル取得日付作成
    const date = new Date();
    // 年
    const year = date.getFullYear();
    // 月
    const month = date.getMonth() + 1;
    // 日
    const day = date.getDate();
    // 時間
    const hour = date.getHours();
    // 分
    const Min = date.getMinutes();
    // 秒
    const sec = date.getSeconds();

    const currentDateTimedate =
        year +
        String(month).padStart(2, "0") +
        String(day).padStart(2, "0") +
        String(hour).padStart(2, "0") +
        String(Min).padStart(2, "0") +
        String(sec).padStart(2, "0");
    return currentDateTimedate

}
export default getCurrentDateTime;

