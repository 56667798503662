import { FC } from "react";
import { useNavigate } from 'react-router-dom';

const NotFound: FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="hero h-auto bg-base-200">
        <div className="hero-content text-center">
          <div className="max-w-lg">
            <h1 className="text-2xl ">お探しのページは見つかりませんでした。</h1>
            <div className='btn btn-md h-8 w-36 text-lg mt-10 rounded-3xl bg-gradient-to-b from-action-from to-action-to border-none hover:bg-orange-400 hover:border-orange-400'
              onClick={event => navigate('/')}>
              TOP画面へ
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotFound;

