import { FC } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RISK_RANGE, RATING_RANGE } from 'constant';

const rateRowColorSet: string[][] = [
  // 評点無し
  [
    "bg-inapplicable",
    "bg-inapplicable",
    "bg-inapplicable",
    "bg-inapplicable",
    "bg-inapplicable"
  ],
  // 0～39
  [
    "bg-inapplicable",
    "bg-alert",
    "bg-alert",
    "bg-alert",
    "bg-alert"
  ],
  // 40～44
  [
    "bg-inapplicable",
    "bg-alert",
    "bg-alert",
    "bg-warn",
    "bg-warn"
  ],
  // 45～49
  [
    "bg-inapplicable",
    "bg-alert",
    "bg-warn",
    "bg-stay",
    "bg-stay"
  ],
  // 50～64
  [
    "bg-inapplicable",
    "bg-alert",
    "bg-warn",
    "bg-available",
    "bg-available"
  ],
  // 65～100
  [
    "bg-inapplicable",
    "bg-warn",
    "bg-warn",
    "bg-active",
    "bg-active"
  ]
]
type TableRowDataProps = {
  rowIndex: number;
}
type Props = {
  corporateSum: number | undefined;
  scoreDistribution: CorporatesScoreDistribution[];
}

const RatingRiskCrossTab: FC<Props> = ({ corporateSum, scoreDistribution }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  /** 納品番号 */
  const id = searchParams.get("id");
  /** アップロードID */
  const uploadId = searchParams.get("uploadId");
  /** 商品名 */
  const productName = searchParams.get("productName");
  /** 納品日付 */
  const deliveredDate = searchParams.get("deliveredDate");
  /** 有効期限日 */
  const expireDate = searchParams.get("expireDate");
  // 各レンジの件数リスト作成
  const distributionList: number[][] = [];
  for (let i = 0; i < RATING_RANGE.length * RISK_RANGE.length; i += RISK_RANGE.length) {
    const start = i;
    const end = i + RISK_RANGE.length;
    let ratingList = scoreDistribution.slice(start, end).map((score) => {
      return score.rangeSum;
    });
    distributionList.push(ratingList);
  }
  // 各リスクスコアレンジの合計件数リスト作成
  const sumList: number[] = [];
  for (let i = 0; i < RISK_RANGE.length; i++) {
    let sum = 0;
    distributionList.forEach((distribution) => {
      sum += distribution[i]
    });
    sumList.push(sum);
  }
  /* ------------------------- 
    event
  ----------------------------*/
  const handleClickTableData = (rowIndex: number, colIndex: number) => {
    navigate(
      `/corporatelist/?id=${id}&uploadId=${uploadId}&productName=${productName}&deliveredDate=${deliveredDate}&expireDate=${expireDate}`,
      {
        state: {
          ratingRange: RATING_RANGE[rowIndex],
          riskRange: RISK_RANGE[colIndex]
        }
      }
    );
  }
  /* ------------------------- 
    components
  ----------------------------*/
  const TableRowData: FC<TableRowDataProps> = ({ rowIndex }) => {
    let header = "評点無し";
    let thbgColor = "bg-gray-200";
    if (0 < rowIndex) {
      header = `${RATING_RANGE[rowIndex].min}～${RATING_RANGE[rowIndex].max}`;
      thbgColor = "bg-white";
    }
    const dataList = distributionList[rowIndex];
    const sum = dataList.reduce((sum, num) => (sum + num), 0);
    return (
      <>
        <th className={`border border-x-2 border-slate-900 ${thbgColor} text-center font-normal`}>{header}</th>
        {rateRowColorSet[rowIndex].map((bgColor, colIndex) => {
          return (
            <td className={`border border-slate-900 ${bgColor} pr-1`} key={colIndex}>
              <button className="w-full text-right" onClick={() => handleClickTableData(rowIndex, colIndex)} disabled={dataList[colIndex] === 0}>{dataList[colIndex]}</button>
            </td>
          )
        })}
        <td className="border border-x-2 border-slate-900 bg-white text-right pr-1">{sum}</td>
      </>
    )
  }
  return (
    <>
      <div className="m-6 my-10 lg:m-10">
        <p className="text-xl font-bold mb-2">評点×リスクスコア分布表</p>
        <p className="block text-sm">
          評点とリスクスコアのクロス集計を表示します。件数項目をクリックすると、
          <br className="lg:hidden" />対象データ一覧を表示します。
        </p>
      </div>
      <div className="lg:flex lg:flex-row m-6 my-10 lg:m-10">
        <div className="overflow-x-scroll md:overflow-hidden pb-5 md:pb-0 lg:w-9/12">
          <table border={1} className="border-collapse whitespace-nowrap w-max md:w-auto">
            <thead>
              <tr>
                <th className="w-8"></th>
                <th className="w-28 lg:w-32 2xl:w-40"></th>
                <th className="border-2 border-slate-900 bg-sky-100 text-center font-semibold" colSpan={6}>リスクスコア</th>
              </tr>
              <tr>
                <th></th>
                <th className="border-b-2 border-slate-900"></th>
                <th className="border border-b-2 border-l-2 border-slate-900 bg-gray-200 text-center font-normal w-28 lg:w-32 2xl:w-40">スコア無し</th>
                {RISK_RANGE.slice(1).map((r) => {
                  return (<th className="border border-b-2 border-slate-900 bg-white text-center font-normal w-28 lg:w-32 2xl:w-40" key={`${r.min}～${r.max}`}>{r.min}～{r.max}</th>)
                })}
                <th className="border-2 border-slate-900 bg-gray-200 text-center font-normal w-28 lg:w-32 2xl:w-40">合計</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="border-2 border-slate-900 bg-lime-200 font-semibold" rowSpan={6}>
                  <span className="[writing-mode:vertical-rl] tracking-[1rem] -mb-4 pl-0.5">評点</span>
                </th>
                <TableRowData rowIndex={5} />
              </tr>
              {RATING_RANGE.slice(0, 5).map((val, i) => {
                const rowIndex = Math.abs(i - 4);
                return (
                  <tr key={rowIndex}><TableRowData rowIndex={rowIndex} /></tr>
                )
              })}
              <tr>
                <th className="border-b-0"></th>
                <th className="border-2 border-slate-900 bg-gray-200 text-center font-normal">合計</th>
                {sumList.map((sum, index) => {
                  return (<td className="border border-y-2 border-slate-900 bg-white text-right pr-1" key={index}>{sum}</td>)
                })}
                <td className="border-2 border-slate-900 bg-white text-right pr-1">{corporateSum}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="w-11/12 lg:w-2/12 mt-6 lg:mt-0 md:ml-auto lg:ml-10 bg-gray-100">
          <div className="p-5 lg:p-0 m-5 md:flex md:flex-wrap md:gap-2 lg:block">
            <p className="text-gray-600"><span className="text-active text-lg">■</span> 積極取引</p>
            <p className="text-gray-600"><span className="text-available text-lg">■</span> 取引可能</p>
            <p className="text-gray-600"><span className="text-stay text-lg">■</span> 現状維持</p>
            <p className="text-gray-600"><span className="text-warn text-lg">■</span> 要警戒</p>
            <p className="text-gray-600"><span className="text-alert text-lg">■</span> 要現地訪問</p>
            <p className="text-gray-600"><span className="text-inapplicable text-lg">■</span> 対象外</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default RatingRiskCrossTab;
