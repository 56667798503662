import { FC, useContext, useEffect, useState } from 'react';
import dayjs from 'shared/dayjs';
import appendByteUnit from 'shared/appendByteUnit';
import awsconfig from 'aws-exports';
import { Amplify, API, Storage, graphqlOperation, Auth } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { useSearchParams } from 'react-router-dom';
import DeliverList from 'pages/deliver/DeliverList';
import FileList from 'pages/deliver/FileList';
import { useNavigate } from 'react-router-dom';
import { DELIVERY_STATUS, DOWNLOAD_STATUS } from 'constant';
import { SessionTimeoutMsg, viewCorporateListData } from 'App';
import executeApi from "shared/executeApi";

Amplify.configure(awsconfig);

export type Props = {
  user: any
};
const listDeliverInfosByUsername = /* GraphQL */ `query ListDeliverInfosByUsername(
  $username: String!
  $expireDate: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelDeliverInfoFilterInput
  $limit: Int
  $nextToken: String
) {
  listDeliverInfosByUsername(
    username: $username
    expireDate: $expireDate
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      systemId
      uploadId
      orderDate
      productName
      deliveredDate
      expireDate
      deliveryStatus
      paletteTarget
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
  }
}
`
const listFileInfosByDeliverinfoID = /* GraphQL */ `query ListFileInfosByDeliverinfoID(
  $deliverinfoID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelFileInfoFilterInput
  $limit: Int
  $nextToken: String
) {
  listFileInfosByDeliverinfoID(
    deliverinfoID: $deliverinfoID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      systemId
      uploadId
      filename
      downloadStatus
      firstDownloadDate
      filesize
      deliverinfoID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
  }
}
`
const listFileInfosByUsername = /* GraphQL */ `query ListFileInfosByUsername(
  $username: String!
  $uploadId: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFileInfoFilterInput
  $limit: Int
  $nextToken: String
) {
  listFileInfosByUsername(
    username: $username
    uploadId: $uploadId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      uploadId
      username
      downloadStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
  }
}
`
const Deliver: FC<Props> = props => {
  /** 納品ID（クエリパラメータ） */
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  let id = searchParams.get("id");
  /** 納品情報データ */
  const [delivers, setDelivers] = useState<DeliverInfo[]>([]);
  /** 選択された納品情報 */
  const [selected, setSelected] = useState<DeliverInfo>();
  /** ファイル一覧 */
  const [files, setFiles] = useState<FileInfo[]>([]);
  // セッションタイムアウトメッセージ
  const { hidden, setHidden } = useContext(SessionTimeoutMsg);
  // ファイル一括ダウンロード実施
  const [bulkDownloadTrigger, setBulkDownloadTrigger] = useState<boolean>(false);
  /** 企業データ一覧画面、検索結果表示固定 */
  const { viewCorporateList, setViewCorporateList } = useContext(viewCorporateListData)
  // ファイルダウンロードの権限
  const [downloadAuthority, setDownloadAuthority] = useState<boolean>(false);

  /** 納品情報初期化 */
  useEffect(() => {
    // 企業データ一覧画面、検索結果表示固定を初期化
    setViewCorporateList("")
    // セッションタイムアウトエラーメッセージを非表示
    setHidden('hidden');
    const deliverResData: DeliverInfo[] = [];
    /** 納品物リストのロード */
    const listDeliverInfo = async () => {
      // 納品情報取得
      const today = dayjs(new Date()).format('YYYYMMDD');
      let response;
      let nextToken: string | null = null;
      try {
        do {
          // nextTokenが存在するまでdeliverResDataに追加
          response = await API.graphql(graphqlOperation(listDeliverInfosByUsername,
            {
              username: props.user?.sub,
              expireDate: { ge: today },
              filter: {
                or: [
                  { deliveryStatus: { eq: DELIVERY_STATUS.COMPLETE } },
                  { deliveryStatus: { eq: DELIVERY_STATUS.DOWNLOADED } }
                ]
              },
              limit: 1000000,
              nextToken: nextToken
            }
          ));
          // @ts-ignore
          nextToken = response.data.listDeliverInfosByUsername.nextToken;
          Array.prototype.push.apply(deliverResData,
            // @ts-ignore
            response.data.listDeliverInfosByUsername.items.filter((deliverInfo) => !deliverInfo._deleted).map(deliverInfo => {
              return {
                id: deliverInfo.id,
                systemId: deliverInfo.systemId,
                uploadId: deliverInfo.uploadId,
                productName: deliverInfo.productName,
                orderDate: dayjs(deliverInfo.orderDate).format('YYYY/MM/DD'),
                deliveredDate: dayjs(deliverInfo.deliveredDate).format('YYYY/MM/DD'),
                expireDate: dayjs(deliverInfo.expireDate).format('YYYY/MM/DD'),
                deliveryStatus: "",
                paletteTarget: deliverInfo.paletteTarget,
                untilExpireDate: calcUntilExpireDate(deliverInfo.expireDate),
                version: deliverInfo._version
              };
            })
          );
        } while (nextToken);
      } catch (e) {
        navigate('/error');
        return;
      }
    };
    /** 納品物に紐づくファイル情報から最新のダウンロードステータスを追加 */
    // ※納品ステータスはリアルタイム反映されないため
    const updatelistDeliverInfo = async () => {
      let response;
      let nextToken: string | null = null;
      const fileResData: FileInfo[] = [];
      try {
        do {
          // ファイル情報を取得（nextTokenが存在するまでfileResDataに追加）
          response = await API.graphql(graphqlOperation(listFileInfosByUsername,
            {
              username: props.user?.sub,
              limit: 1000000,
              nextToken: nextToken
            }
          ));
          // @ts-ignore
          nextToken = response.data.listFileInfosByUsername.nextToken;
          Array.prototype.push.apply(fileResData,
            // @ts-ignore
            response.data.listFileInfosByUsername.items.filter(fileInfo => !fileInfo._deleted).map(fileInfo => {
              return {
                id: fileInfo.id,
                uploadId: fileInfo.uploadId,
                downloadStatus: fileInfo.downloadStatus
              }
            }));
        } while (nextToken);
        deliverResData.forEach((deliver) => {
          let downloadStatusList: (string | undefined | null)[] = [];
          fileResData.forEach((file) => {
            if (deliver.uploadId === file.uploadId) {
              downloadStatusList.push(file.downloadStatus);
            }
          });
          deliver.deliveryStatus = downloadStatusList.every((status) => status === DOWNLOAD_STATUS.DOWNLOADED) && downloadStatusList.length > 0
            ? DELIVERY_STATUS.DOWNLOADED : DELIVERY_STATUS.COMPLETE;
        })
      } catch (e) {
        navigate('/error');
        return;
      }
      setDelivers(deliverResData as DeliverInfo[]);
    };
    const initDeliverInfo = async () => {
      await listDeliverInfo();
      await updatelistDeliverInfo();
    };
    initDeliverInfo();
  }, [props.user, navigate, bulkDownloadTrigger]);

  /** 納品情報の選択でファイル一覧を更新 */
  useEffect(() => {
    /** ファイル情報ロード */
    const loadFiles = async () => {
      if (!selected) {
        return;
      }
      let keys: (string | undefined)[];
      let response;
      let nextToken: string | null = null;
      const resData: FileInfo[] = [];
      try {
        // ファイル存在チェックのためS3からファイル一覧取得
        await Storage.list(selected.systemId + selected.uploadId, { level: 'private', pageSize: 'ALL' })
          .then(res => keys = res.results.map(file => file.key));
        // ファイル情報を取得（nextTokenが存在するまでresDataに追加）
        do {
          response = await API.graphql(graphqlOperation(listFileInfosByDeliverinfoID,
            {
              deliverinfoID: selected.id,
              limit: 1000000,
              nextToken: nextToken
            }
          ));
          // @ts-ignore
          nextToken = response.data.listFileInfosByDeliverinfoID.nextToken;
          Array.prototype.push.apply(resData,
            // @ts-ignore
            response.data.listFileInfosByDeliverinfoID.items.filter(fileInfo => !fileInfo._deleted).map(fileInfo => {
              const firstDownloadDate = (!fileInfo.firstDownloadDate) ? '' : dayjs(fileInfo.firstDownloadDate).format('YYYY/MM/DD');
              return {
                id: fileInfo.id,
                systemId: fileInfo.systemId,
                uploadId: fileInfo.uploadId,
                deliverinfoID: fileInfo.deliverinfoID,
                filename: fileInfo.filename,
                filesize: appendByteUnit(fileInfo.filesize),
                downloadStatus: fileInfo.downloadStatus,
                firstDownloadDate: firstDownloadDate,
                version: fileInfo._version,
              };
            }).filter((fileInfo: { filename: string; }) => keys.includes(selected.systemId + selected.uploadId + '/' + fileInfo.filename)));
        } while (nextToken);
      } catch (e) {
        navigate('/error');
        return;
      }
      // ファイル名昇順
      resData.sort((first, second) => {
        if (first.filename > second.filename) { return 1; }
        else if (first.filename < second.filename) { return -1; }
        else { return 0; }
      });
      setFiles(resData);
    }
    /** 選択中の納品物情報をセット */
    const setSelectedInfo = () => {
      if (!selected) {
        return;
      }
      setSearchParams(
        {
          id: selected.id,
          uploadId: selected.uploadId,
          productName: selected.productName,
          deliveredDate: selected.deliveredDate,
          expireDate: selected.deliveredDate
        }
      )
    }
    loadFiles();
    setSelectedInfo();
  }, [selected, navigate]);

  /** ダウンロード有効期限までの日数算出 */
  const calcUntilExpireDate = (expireDate: string | null | undefined) => {
    if (!expireDate) {
      return '';
    }
    const today = dayjs(new Date()).startOf('date');
    const diff = dayjs(expireDate).diff(today, 'day');
    return '残り ' + diff + '日';
  }
  // ファイルダウンロードの権限判定
  useEffect(() => {
    if (!selected) return
    let sessionToken:any
    const data = async () => {
      // セッションタイムアウトチェック
      try {
        sessionToken = await Auth.currentSession();
      } catch (noSession) {
        setHidden('');
        navigate("/");
        return;
      }
      // API実行 
      const baseURL = process.env.REACT_APP_DASHBOARD_API_ROOT!;
      const path = "/dashboad/usagerole/execution";
      const request = {
        "id": selected.id,
        "function": "DownloadDeliveredFile"
      };
      const response = await executeApi(baseURL, path, sessionToken.idToken.jwtToken, 1, request);
      if (!response || response.data.data.available === "") {
        navigate('/error');
        return;
      }
      setDownloadAuthority(response.data.data.available)
    }
    data()
  }, [selected]);

  return (
    <>
      <div className='xl:w-7/12 w-full'>
        <div className='mx-5 mt-3.5'>
          <DeliverList id={id} delivers={delivers} selected={selected} setSelected={setSelected} />
        </div>
      </div>
      <div className='xl:w-5/12 w-full' >
        <div className='my-4 mr-5'>
          <FileList bulkDownloadTrigger={bulkDownloadTrigger} setBulkDownloadTrigger={setBulkDownloadTrigger} selected={selected} files={files} setFiles={setFiles} downloadAuthority={downloadAuthority} />
        </div>
      </div>
    </>
  );
}

export default Deliver;
