import { useContext } from "react";
import { SessionTimeoutMsg } from "App";
import { useTheme, Text, View, Alert } from "@aws-amplify/ui-react";

export const components = {
  Header() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding={tokens.space.large}>
        <div className="flex mt-4.5 justify-center items-center">
          <img src="/symbol-color-min.jpg" alt="株式会社東京商工リサーチ" title="東京商工リサーチ" className="h-8"/>
        </div>
      </View>
    );
  },
  Footer() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          &copy; 2023 TOKYO SHOKO RESEARCH, LTD.
        </Text>
      </View>
    );
  },
  SignIn: {
    Header() {
      const { tokens } = useTheme();
      const { hidden, setHidden } = useContext(SessionTimeoutMsg);
      return (
        <div className={hidden}>
          <div className='mx-6 mt-4 -mb-4'>
            <Alert
              hasIcon={true}
              variation="error">
              一定時間操作が行なわれなかったためログアウトしました。再度ログインしてください。
            </Alert>
          </div>
        </div>
      );
    }
  }
};