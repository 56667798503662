/** コード変換 */
const convertCodeIntoValue = (code: string | null, convertObj: Object) => {
  let value = null;
  if (code === "") {
    value = "";
  } else if (code) {
    value = convertObj[code as keyof {}];
  }
  return value;
}
export default convertCodeIntoValue;
