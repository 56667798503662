/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createFileInfo = /* GraphQL */ `mutation CreateFileInfo(
  $input: CreateFileInfoInput!
  $condition: ModelFileInfoConditionInput
) {
  createFileInfo(input: $input, condition: $condition) {
    id
    systemId
    uploadId
    email
    username
    kcd
    filename
    downloadStatus
    firstDownloadDate
    firstDownloadTime
    firstDownloadDateTime
    filesize
    encode
    deliverinfoID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFileInfoMutationVariables,
  APITypes.CreateFileInfoMutation
>;
export const updateFileInfo = /* GraphQL */ `mutation UpdateFileInfo(
  $input: UpdateFileInfoInput!
  $condition: ModelFileInfoConditionInput
) {
  updateFileInfo(input: $input, condition: $condition) {
    id
    systemId
    uploadId
    email
    username
    kcd
    filename
    downloadStatus
    firstDownloadDate
    firstDownloadTime
    firstDownloadDateTime
    filesize
    encode
    deliverinfoID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFileInfoMutationVariables,
  APITypes.UpdateFileInfoMutation
>;
export const deleteFileInfo = /* GraphQL */ `mutation DeleteFileInfo(
  $input: DeleteFileInfoInput!
  $condition: ModelFileInfoConditionInput
) {
  deleteFileInfo(input: $input, condition: $condition) {
    id
    systemId
    uploadId
    email
    username
    kcd
    filename
    downloadStatus
    firstDownloadDate
    firstDownloadTime
    firstDownloadDateTime
    filesize
    encode
    deliverinfoID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFileInfoMutationVariables,
  APITypes.DeleteFileInfoMutation
>;
export const createDeliverInfo = /* GraphQL */ `mutation CreateDeliverInfo(
  $input: CreateDeliverInfoInput!
  $condition: ModelDeliverInfoConditionInput
) {
  createDeliverInfo(input: $input, condition: $condition) {
    id
    systemId
    uploadId
    email
    username
    kcd
    kname
    tcd
    orderDate
    deliveryDateTime
    nomail
    mailFrom
    mailComment
    mailNotice
    productName
    mailSendNo
    noDeliverMail
    noCustomerRemind
    noStaffRemind
    noDownloadNotice
    identityId
    deliveredDate
    deliveredTime
    expireDate
    deliveryStatus
    noticeDownloaded
    remindMailCount
    paletteTarget
    FileInfos {
      nextToken
      startedAt
      __typename
    }
    corporateFilenameList
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDeliverInfoMutationVariables,
  APITypes.CreateDeliverInfoMutation
>;
export const updateDeliverInfo = /* GraphQL */ `mutation UpdateDeliverInfo(
  $input: UpdateDeliverInfoInput!
  $condition: ModelDeliverInfoConditionInput
) {
  updateDeliverInfo(input: $input, condition: $condition) {
    id
    systemId
    uploadId
    email
    username
    kcd
    kname
    tcd
    orderDate
    deliveryDateTime
    nomail
    mailFrom
    mailComment
    mailNotice
    productName
    mailSendNo
    noDeliverMail
    noCustomerRemind
    noStaffRemind
    noDownloadNotice
    identityId
    deliveredDate
    deliveredTime
    expireDate
    deliveryStatus
    noticeDownloaded
    remindMailCount
    paletteTarget
    FileInfos {
      nextToken
      startedAt
      __typename
    }
    corporateFilenameList
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDeliverInfoMutationVariables,
  APITypes.UpdateDeliverInfoMutation
>;
export const deleteDeliverInfo = /* GraphQL */ `mutation DeleteDeliverInfo(
  $input: DeleteDeliverInfoInput!
  $condition: ModelDeliverInfoConditionInput
) {
  deleteDeliverInfo(input: $input, condition: $condition) {
    id
    systemId
    uploadId
    email
    username
    kcd
    kname
    tcd
    orderDate
    deliveryDateTime
    nomail
    mailFrom
    mailComment
    mailNotice
    productName
    mailSendNo
    noDeliverMail
    noCustomerRemind
    noStaffRemind
    noDownloadNotice
    identityId
    deliveredDate
    deliveredTime
    expireDate
    deliveryStatus
    noticeDownloaded
    remindMailCount
    paletteTarget
    FileInfos {
      nextToken
      startedAt
      __typename
    }
    corporateFilenameList
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDeliverInfoMutationVariables,
  APITypes.DeleteDeliverInfoMutation
>;
export const createCorporate = /* GraphQL */ `mutation CreateCorporate(
  $input: CreateCorporateInput!
  $condition: ModelCorporateConditionInput
) {
  createCorporate(input: $input, condition: $condition) {
    id
    uploadId
    username
    filename
    expireDate
    KCD
    SG_HJKK_ZGKB
    SG_HJCD
    RK_SZKN
    SG_INKN
    HJTK_SGKT
    SG_INKT
    KGY_JSHCD_TSRCD
    JST_SZIC_JSCD
    JST_SZIC_ZIP
    JST_ADKN
    JST_SZIC_BCNO
    JST_SZIC_ZNTI_TEL
    JJ_KBN
    KBCD
    EDCD
    SGY_NNGT
    EDJD_IZN_SYNY
    STRT_YMD
    SHNKN
    JGYINS
    KJYS
    JGYSH_S
    CHS_NNGP
    R_CS_YMD
    KDB_UP_YMD
    SYRK_GKNY
    GKTK_SIJO_MI
    HSKN_1
    HSKN_2
    HSKN_3
    HSKN_4
    HSKN_5
    HSKN_6
    SSKN_1
    SSKN_2
    SSKN_3
    SSKN_4
    SSKN_5
    SSKN_6
    YIMI_1
    YSMI_1
    YKIN_HJKB_1
    YKSH_KGMI_1
    YIMI_2
    YSMI_2
    YKIN_HJKB_2
    YKSH_KGMI_2
    YIMI_3
    YSMI_3
    YKIN_HJKB_3
    YKSH_KGMI_3
    YIMI_4
    YSMI_4
    YKIN_HJKB_4
    YKSH_KGMI_4
    YIMI_5
    YSMI_5
    YKIN_HJKB_5
    YKSH_KGMI_5
    YIMI_6
    YSMI_6
    YKIN_HJKB_6
    YKSH_KGMI_6
    YIMI_7
    YSMI_7
    YKIN_HJKB_7
    YKSH_KGMI_7
    YIMI_8
    YSMI_8
    YKIN_HJKB_8
    YKSH_KGMI_8
    YIMI_9
    YSMI_9
    YKIN_HJKB_9
    YKSH_KGMI_9
    YIMI_10
    YSMI_10
    YKIN_HJKB_10
    YKSH_KGMI_10
    KNMI_1
    CTPR_MCWR_1
    KNMI_2
    CTPR_MCWR_2
    KNMI_3
    CTPR_MCWR_3
    KNMI_4
    CTPR_MCWR_4
    KNMI_5
    CTPR_MCWR_5
    KNMI_6
    CTPR_MCWR_6
    JGNY_1
    JGNY_2
    JGNY_3
    JGNY_4
    JGNY_5
    JGNY_6
    JGNY_KSHR_1
    JGNY_KSHR_2
    JGNY_KSHR_3
    JGNY_KSHR_4
    JGNY_KSHR_5
    JGNY_KSHR_6
    GSCD_1
    GSCD_2
    GSCD_3
    GSCD_4
    GSCD_5
    GSCD_6
    GYSH_FBMI_1
    GYSH_FBMI_2
    GYSH_FBMI_3
    GYSH_FBMI_4
    GYSH_FBMI_5
    GYSH_FBMI_6
    AGCD_1
    AGCD_2
    AGCD_3
    AGCD_4
    AGCD_5
    AGCD_6
    ATKN_1
    ATKN_2
    ATKN_3
    ATKN_4
    ATKN_5
    ATKN_6
    GYSI_KSN_YM_1
    GYSI_MSU_1
    SNTI_GYSI_URAG_1
    GYSI_ZIKB_1
    SNTI_GYSI_REK_1
    GYSK_JSHR_1
    HISG_1
    ZUKB_1
    GYSI_KSN_YM_2
    GYSI_MSU_2
    SNTI_GYSI_URAG_2
    GYSI_ZIKB_2
    SNTI_GYSI_REK_2
    GYSK_JSHR_2
    HISG_2
    ZUKB_2
    GYSI_KSN_YM_3
    GYSI_MSU_3
    SNTI_GYSI_URAG_3
    GYSI_ZIKB_3
    SNTI_GYSI_REK_3
    GYSK_JSHR_3
    HISG_3
    ZUKB_3
    URJN_TSHO_KSN_YM
    ZNKK_URJN
    ZNKK_URJN_TSHO_SASU
    KNBT_URJN
    KNBT_URJN_TSHO_SASU
    FICD_1
    FICD_2
    FICD_3
    FICD_4
    FICD_5
    FICD_6
    FICD_7
    FICD_8
    FICD_9
    FICD_10
    FIKN_1
    TNKN_1
    FIKN_2
    TNKN_2
    FIKN_3
    TNKN_3
    FIKN_4
    TNKN_4
    FIKN_5
    TNKN_5
    FIKN_6
    TNKN_6
    FIKN_7
    TNKN_7
    FIKN_8
    TNKN_8
    FIKN_9
    TNKN_9
    FIKN_10
    TNKN_10
    DHS_SIMIKN
    DHS_YSMI
    SNNGP_SRK
    SEBT_KBN
    DHS_SIMIKT
    DHS_KGMI
    DHS_SHUN_YMD
    DHYSH_JSHCD_TSRCD
    DHS_JSCD
    DHS_ADD_ZIP
    DHS_ADKN
    DHS_JSKT
    DHS_ADD_BCNO
    DHS_ZNTI_TEL
    TSN_KRK_KBN
    SSNCHCD
    DHS_HCMI
    SISH_GKRK_GAKCD
    DHS_SIS_EDU_GKMI
    STGY_KBN
    DHS_GRKN
    ETCD
    DHS_ETMI
    JKYO_CD
    DHS_JUMI
    DHS_HOCD_1
    DHS_HOCD_2
    DHS_HOCD_3
    DHS_HOMI_1
    DHS_HOMI_2
    DHS_HOMI_3
    HYTN
    DUNS
    HJNO
    TKSK_HKOJ_INNO
    KGY_URL
    SGEI
    JST_ADEI
    DHS_SIMIEI
    RSK_SCR
    KKCD
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCorporateMutationVariables,
  APITypes.CreateCorporateMutation
>;
export const updateCorporate = /* GraphQL */ `mutation UpdateCorporate(
  $input: UpdateCorporateInput!
  $condition: ModelCorporateConditionInput
) {
  updateCorporate(input: $input, condition: $condition) {
    id
    uploadId
    username
    filename
    expireDate
    KCD
    SG_HJKK_ZGKB
    SG_HJCD
    RK_SZKN
    SG_INKN
    HJTK_SGKT
    SG_INKT
    KGY_JSHCD_TSRCD
    JST_SZIC_JSCD
    JST_SZIC_ZIP
    JST_ADKN
    JST_SZIC_BCNO
    JST_SZIC_ZNTI_TEL
    JJ_KBN
    KBCD
    EDCD
    SGY_NNGT
    EDJD_IZN_SYNY
    STRT_YMD
    SHNKN
    JGYINS
    KJYS
    JGYSH_S
    CHS_NNGP
    R_CS_YMD
    KDB_UP_YMD
    SYRK_GKNY
    GKTK_SIJO_MI
    HSKN_1
    HSKN_2
    HSKN_3
    HSKN_4
    HSKN_5
    HSKN_6
    SSKN_1
    SSKN_2
    SSKN_3
    SSKN_4
    SSKN_5
    SSKN_6
    YIMI_1
    YSMI_1
    YKIN_HJKB_1
    YKSH_KGMI_1
    YIMI_2
    YSMI_2
    YKIN_HJKB_2
    YKSH_KGMI_2
    YIMI_3
    YSMI_3
    YKIN_HJKB_3
    YKSH_KGMI_3
    YIMI_4
    YSMI_4
    YKIN_HJKB_4
    YKSH_KGMI_4
    YIMI_5
    YSMI_5
    YKIN_HJKB_5
    YKSH_KGMI_5
    YIMI_6
    YSMI_6
    YKIN_HJKB_6
    YKSH_KGMI_6
    YIMI_7
    YSMI_7
    YKIN_HJKB_7
    YKSH_KGMI_7
    YIMI_8
    YSMI_8
    YKIN_HJKB_8
    YKSH_KGMI_8
    YIMI_9
    YSMI_9
    YKIN_HJKB_9
    YKSH_KGMI_9
    YIMI_10
    YSMI_10
    YKIN_HJKB_10
    YKSH_KGMI_10
    KNMI_1
    CTPR_MCWR_1
    KNMI_2
    CTPR_MCWR_2
    KNMI_3
    CTPR_MCWR_3
    KNMI_4
    CTPR_MCWR_4
    KNMI_5
    CTPR_MCWR_5
    KNMI_6
    CTPR_MCWR_6
    JGNY_1
    JGNY_2
    JGNY_3
    JGNY_4
    JGNY_5
    JGNY_6
    JGNY_KSHR_1
    JGNY_KSHR_2
    JGNY_KSHR_3
    JGNY_KSHR_4
    JGNY_KSHR_5
    JGNY_KSHR_6
    GSCD_1
    GSCD_2
    GSCD_3
    GSCD_4
    GSCD_5
    GSCD_6
    GYSH_FBMI_1
    GYSH_FBMI_2
    GYSH_FBMI_3
    GYSH_FBMI_4
    GYSH_FBMI_5
    GYSH_FBMI_6
    AGCD_1
    AGCD_2
    AGCD_3
    AGCD_4
    AGCD_5
    AGCD_6
    ATKN_1
    ATKN_2
    ATKN_3
    ATKN_4
    ATKN_5
    ATKN_6
    GYSI_KSN_YM_1
    GYSI_MSU_1
    SNTI_GYSI_URAG_1
    GYSI_ZIKB_1
    SNTI_GYSI_REK_1
    GYSK_JSHR_1
    HISG_1
    ZUKB_1
    GYSI_KSN_YM_2
    GYSI_MSU_2
    SNTI_GYSI_URAG_2
    GYSI_ZIKB_2
    SNTI_GYSI_REK_2
    GYSK_JSHR_2
    HISG_2
    ZUKB_2
    GYSI_KSN_YM_3
    GYSI_MSU_3
    SNTI_GYSI_URAG_3
    GYSI_ZIKB_3
    SNTI_GYSI_REK_3
    GYSK_JSHR_3
    HISG_3
    ZUKB_3
    URJN_TSHO_KSN_YM
    ZNKK_URJN
    ZNKK_URJN_TSHO_SASU
    KNBT_URJN
    KNBT_URJN_TSHO_SASU
    FICD_1
    FICD_2
    FICD_3
    FICD_4
    FICD_5
    FICD_6
    FICD_7
    FICD_8
    FICD_9
    FICD_10
    FIKN_1
    TNKN_1
    FIKN_2
    TNKN_2
    FIKN_3
    TNKN_3
    FIKN_4
    TNKN_4
    FIKN_5
    TNKN_5
    FIKN_6
    TNKN_6
    FIKN_7
    TNKN_7
    FIKN_8
    TNKN_8
    FIKN_9
    TNKN_9
    FIKN_10
    TNKN_10
    DHS_SIMIKN
    DHS_YSMI
    SNNGP_SRK
    SEBT_KBN
    DHS_SIMIKT
    DHS_KGMI
    DHS_SHUN_YMD
    DHYSH_JSHCD_TSRCD
    DHS_JSCD
    DHS_ADD_ZIP
    DHS_ADKN
    DHS_JSKT
    DHS_ADD_BCNO
    DHS_ZNTI_TEL
    TSN_KRK_KBN
    SSNCHCD
    DHS_HCMI
    SISH_GKRK_GAKCD
    DHS_SIS_EDU_GKMI
    STGY_KBN
    DHS_GRKN
    ETCD
    DHS_ETMI
    JKYO_CD
    DHS_JUMI
    DHS_HOCD_1
    DHS_HOCD_2
    DHS_HOCD_3
    DHS_HOMI_1
    DHS_HOMI_2
    DHS_HOMI_3
    HYTN
    DUNS
    HJNO
    TKSK_HKOJ_INNO
    KGY_URL
    SGEI
    JST_ADEI
    DHS_SIMIEI
    RSK_SCR
    KKCD
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCorporateMutationVariables,
  APITypes.UpdateCorporateMutation
>;
export const deleteCorporate = /* GraphQL */ `mutation DeleteCorporate(
  $input: DeleteCorporateInput!
  $condition: ModelCorporateConditionInput
) {
  deleteCorporate(input: $input, condition: $condition) {
    id
    uploadId
    username
    filename
    expireDate
    KCD
    SG_HJKK_ZGKB
    SG_HJCD
    RK_SZKN
    SG_INKN
    HJTK_SGKT
    SG_INKT
    KGY_JSHCD_TSRCD
    JST_SZIC_JSCD
    JST_SZIC_ZIP
    JST_ADKN
    JST_SZIC_BCNO
    JST_SZIC_ZNTI_TEL
    JJ_KBN
    KBCD
    EDCD
    SGY_NNGT
    EDJD_IZN_SYNY
    STRT_YMD
    SHNKN
    JGYINS
    KJYS
    JGYSH_S
    CHS_NNGP
    R_CS_YMD
    KDB_UP_YMD
    SYRK_GKNY
    GKTK_SIJO_MI
    HSKN_1
    HSKN_2
    HSKN_3
    HSKN_4
    HSKN_5
    HSKN_6
    SSKN_1
    SSKN_2
    SSKN_3
    SSKN_4
    SSKN_5
    SSKN_6
    YIMI_1
    YSMI_1
    YKIN_HJKB_1
    YKSH_KGMI_1
    YIMI_2
    YSMI_2
    YKIN_HJKB_2
    YKSH_KGMI_2
    YIMI_3
    YSMI_3
    YKIN_HJKB_3
    YKSH_KGMI_3
    YIMI_4
    YSMI_4
    YKIN_HJKB_4
    YKSH_KGMI_4
    YIMI_5
    YSMI_5
    YKIN_HJKB_5
    YKSH_KGMI_5
    YIMI_6
    YSMI_6
    YKIN_HJKB_6
    YKSH_KGMI_6
    YIMI_7
    YSMI_7
    YKIN_HJKB_7
    YKSH_KGMI_7
    YIMI_8
    YSMI_8
    YKIN_HJKB_8
    YKSH_KGMI_8
    YIMI_9
    YSMI_9
    YKIN_HJKB_9
    YKSH_KGMI_9
    YIMI_10
    YSMI_10
    YKIN_HJKB_10
    YKSH_KGMI_10
    KNMI_1
    CTPR_MCWR_1
    KNMI_2
    CTPR_MCWR_2
    KNMI_3
    CTPR_MCWR_3
    KNMI_4
    CTPR_MCWR_4
    KNMI_5
    CTPR_MCWR_5
    KNMI_6
    CTPR_MCWR_6
    JGNY_1
    JGNY_2
    JGNY_3
    JGNY_4
    JGNY_5
    JGNY_6
    JGNY_KSHR_1
    JGNY_KSHR_2
    JGNY_KSHR_3
    JGNY_KSHR_4
    JGNY_KSHR_5
    JGNY_KSHR_6
    GSCD_1
    GSCD_2
    GSCD_3
    GSCD_4
    GSCD_5
    GSCD_6
    GYSH_FBMI_1
    GYSH_FBMI_2
    GYSH_FBMI_3
    GYSH_FBMI_4
    GYSH_FBMI_5
    GYSH_FBMI_6
    AGCD_1
    AGCD_2
    AGCD_3
    AGCD_4
    AGCD_5
    AGCD_6
    ATKN_1
    ATKN_2
    ATKN_3
    ATKN_4
    ATKN_5
    ATKN_6
    GYSI_KSN_YM_1
    GYSI_MSU_1
    SNTI_GYSI_URAG_1
    GYSI_ZIKB_1
    SNTI_GYSI_REK_1
    GYSK_JSHR_1
    HISG_1
    ZUKB_1
    GYSI_KSN_YM_2
    GYSI_MSU_2
    SNTI_GYSI_URAG_2
    GYSI_ZIKB_2
    SNTI_GYSI_REK_2
    GYSK_JSHR_2
    HISG_2
    ZUKB_2
    GYSI_KSN_YM_3
    GYSI_MSU_3
    SNTI_GYSI_URAG_3
    GYSI_ZIKB_3
    SNTI_GYSI_REK_3
    GYSK_JSHR_3
    HISG_3
    ZUKB_3
    URJN_TSHO_KSN_YM
    ZNKK_URJN
    ZNKK_URJN_TSHO_SASU
    KNBT_URJN
    KNBT_URJN_TSHO_SASU
    FICD_1
    FICD_2
    FICD_3
    FICD_4
    FICD_5
    FICD_6
    FICD_7
    FICD_8
    FICD_9
    FICD_10
    FIKN_1
    TNKN_1
    FIKN_2
    TNKN_2
    FIKN_3
    TNKN_3
    FIKN_4
    TNKN_4
    FIKN_5
    TNKN_5
    FIKN_6
    TNKN_6
    FIKN_7
    TNKN_7
    FIKN_8
    TNKN_8
    FIKN_9
    TNKN_9
    FIKN_10
    TNKN_10
    DHS_SIMIKN
    DHS_YSMI
    SNNGP_SRK
    SEBT_KBN
    DHS_SIMIKT
    DHS_KGMI
    DHS_SHUN_YMD
    DHYSH_JSHCD_TSRCD
    DHS_JSCD
    DHS_ADD_ZIP
    DHS_ADKN
    DHS_JSKT
    DHS_ADD_BCNO
    DHS_ZNTI_TEL
    TSN_KRK_KBN
    SSNCHCD
    DHS_HCMI
    SISH_GKRK_GAKCD
    DHS_SIS_EDU_GKMI
    STGY_KBN
    DHS_GRKN
    ETCD
    DHS_ETMI
    JKYO_CD
    DHS_JUMI
    DHS_HOCD_1
    DHS_HOCD_2
    DHS_HOCD_3
    DHS_HOMI_1
    DHS_HOMI_2
    DHS_HOMI_3
    HYTN
    DUNS
    HJNO
    TKSK_HKOJ_INNO
    KGY_URL
    SGEI
    JST_ADEI
    DHS_SIMIEI
    RSK_SCR
    KKCD
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCorporateMutationVariables,
  APITypes.DeleteCorporateMutation
>;
export const createCorporateDashboardData = /* GraphQL */ `mutation CreateCorporateDashboardData(
  $input: CreateCorporateDashboardDataInput!
  $condition: ModelCorporateDashboardDataConditionInput
) {
  createCorporateDashboardData(input: $input, condition: $condition) {
    id
    uploadId
    sum
    distributionNoData
    distributionData09
    distributionData1019
    distributionData2029
    distributionData3039
    distributionData4044
    distributionData4549
    distributionData5054
    distributionData5559
    distributionData6069
    distributionData7079
    distributionData8089
    distributionData90100
    crossNoRatingNoScore
    crossNoRatingScore110
    crossNoRatingScore1120
    crossNoRatingScore2149
    crossNoRatingScore50100
    crossRating039NoScore
    crossRating039Score110
    crossRating039Score1120
    crossRating039Score2149
    crossRating039Score50100
    crossRating4044NoScore
    crossRating4044Score110
    crossRating4044Score1120
    crossRating4044Score2149
    crossRating4044Score50100
    crossRating4549NoScore
    crossRating4549Score110
    crossRating4549Score1120
    crossRating4549Score2149
    crossRating4549Score50100
    crossRating5064NoScore
    crossRating5064Score110
    crossRating5064Score1120
    crossRating5064Score2149
    crossRating5064Score50100
    crossRating65100NoScore
    crossRating65100Score110
    crossRating65100Score1120
    crossRating65100Score2149
    crossRating65100Score50100
    expireDate
    username
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCorporateDashboardDataMutationVariables,
  APITypes.CreateCorporateDashboardDataMutation
>;
export const updateCorporateDashboardData = /* GraphQL */ `mutation UpdateCorporateDashboardData(
  $input: UpdateCorporateDashboardDataInput!
  $condition: ModelCorporateDashboardDataConditionInput
) {
  updateCorporateDashboardData(input: $input, condition: $condition) {
    id
    uploadId
    sum
    distributionNoData
    distributionData09
    distributionData1019
    distributionData2029
    distributionData3039
    distributionData4044
    distributionData4549
    distributionData5054
    distributionData5559
    distributionData6069
    distributionData7079
    distributionData8089
    distributionData90100
    crossNoRatingNoScore
    crossNoRatingScore110
    crossNoRatingScore1120
    crossNoRatingScore2149
    crossNoRatingScore50100
    crossRating039NoScore
    crossRating039Score110
    crossRating039Score1120
    crossRating039Score2149
    crossRating039Score50100
    crossRating4044NoScore
    crossRating4044Score110
    crossRating4044Score1120
    crossRating4044Score2149
    crossRating4044Score50100
    crossRating4549NoScore
    crossRating4549Score110
    crossRating4549Score1120
    crossRating4549Score2149
    crossRating4549Score50100
    crossRating5064NoScore
    crossRating5064Score110
    crossRating5064Score1120
    crossRating5064Score2149
    crossRating5064Score50100
    crossRating65100NoScore
    crossRating65100Score110
    crossRating65100Score1120
    crossRating65100Score2149
    crossRating65100Score50100
    expireDate
    username
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCorporateDashboardDataMutationVariables,
  APITypes.UpdateCorporateDashboardDataMutation
>;
export const deleteCorporateDashboardData = /* GraphQL */ `mutation DeleteCorporateDashboardData(
  $input: DeleteCorporateDashboardDataInput!
  $condition: ModelCorporateDashboardDataConditionInput
) {
  deleteCorporateDashboardData(input: $input, condition: $condition) {
    id
    uploadId
    sum
    distributionNoData
    distributionData09
    distributionData1019
    distributionData2029
    distributionData3039
    distributionData4044
    distributionData4549
    distributionData5054
    distributionData5559
    distributionData6069
    distributionData7079
    distributionData8089
    distributionData90100
    crossNoRatingNoScore
    crossNoRatingScore110
    crossNoRatingScore1120
    crossNoRatingScore2149
    crossNoRatingScore50100
    crossRating039NoScore
    crossRating039Score110
    crossRating039Score1120
    crossRating039Score2149
    crossRating039Score50100
    crossRating4044NoScore
    crossRating4044Score110
    crossRating4044Score1120
    crossRating4044Score2149
    crossRating4044Score50100
    crossRating4549NoScore
    crossRating4549Score110
    crossRating4549Score1120
    crossRating4549Score2149
    crossRating4549Score50100
    crossRating5064NoScore
    crossRating5064Score110
    crossRating5064Score1120
    crossRating5064Score2149
    crossRating5064Score50100
    crossRating65100NoScore
    crossRating65100Score110
    crossRating65100Score1120
    crossRating65100Score2149
    crossRating65100Score50100
    expireDate
    username
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCorporateDashboardDataMutationVariables,
  APITypes.DeleteCorporateDashboardDataMutation
>;
