import { FC, useContext, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { CorporateInfo, CorporateTableData } from "./Corporate";
import { JJ_SJU_NAME, NOT_PURCHASED } from 'constant';
import formatDate from "shared/formatDate";
import formatPhoneNumber from "shared/formatPhoneNumber";
import formatNumberToLocaleString from "shared/formatNumberToLocaleString";
import convertCodeIntoValue from "shared/convertCodeIntoValue";
import formatZipcode from "shared/formatZipcode";
import executeApi from "shared/executeApi";
import { SessionTimeoutMsg } from 'App';

const CorporateOverview: FC = () => {
  const navigate = useNavigate();
  // ログインユーザー情報
  const { user } = useAuthenticator(context => [context.user]);
  const session = user.getSignInUserSession();
  const token = session?.getIdToken().getJwtToken();
  // セッションタイムアウトメッセージ
  const { hidden, setHidden } = useContext(SessionTimeoutMsg);
  // ダブルクリック制御用
  const [clickFileDownload, setClickFileDownload] = useState<boolean>(false);
  /** ファイルダウンロード */
  const handleClickFile = async (id: string) => {
    let sessionToken: any
    // セッションタイムアウトチェック
    try {
      sessionToken = await Auth.currentSession();
    } catch (noSession) {
      setHidden('');
      navigate("/");
      return false;
    }
    // ダブルクリック制御
    if (clickFileDownload) return false;
    setClickFileDownload(true);
    // API実行
    const baseURL = process.env.REACT_APP_DASHBOARD_API_ROOT!;
    const path = "/dashboad/corporate/pdf/execution";
    const request = { "id": id };
    const response = await executeApi(baseURL, path, sessionToken.idToken.jwtToken, 1, request);
    if (!response || response.data.data.url === "") {
      navigate('/error');
      return;
    }
    // ダウンロード実行
    const url = response.data.data.url;
    let link = document.createElement('a');
    link.download = url;
    link.href = url;
    link.click();
    // ダブルクリック制御解除
    setClickFileDownload(false);
  }

  // 企業情報
  const corporateInfo = useContext(CorporateInfo);
  if (!corporateInfo) {
    return (
      <></>
    )
  }
  // 評点
  let HYTN: number | null | string = null;
  if (corporateInfo.HYTN === NOT_PURCHASED) {
    HYTN = "";
  } else if (corporateInfo.HYTN !== null) {
    HYTN = corporateInfo.HYTN;
  }
  // リスクスコア
  let RSK_SCR: number | null | string = null;
  if (corporateInfo.RSK_SCR === NOT_PURCHASED) {
    RSK_SCR = "";
  } else if (corporateInfo.RSK_SCR !== null) {
    RSK_SCR = corporateInfo.RSK_SCR;
  }
  // レポート情報テーブル
  const CorporateReportInfoTable: FC = () => {
    return (
      <>
        {/* SP画面 */}
        <div className="md:hidden">
          <table className="w-full">
            <tbody>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2 w-6/12">調査年月日</th>
                <CorporateTableData data={formatDate(corporateInfo.CHS_NNGP)} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2 w-6/12">レポート調査年月日</th>
                <CorporateTableData data={formatDate(corporateInfo.R_CS_YMD)} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2 w-6/12">企業DB更新年月日</th>
                <CorporateTableData data={formatDate(corporateInfo.KDB_UP_YMD)} className="pl-2" />
              </tr>
            </tbody>
          </table>
        </div>
        {/* PC・タブレット画面 */}
        <div className="hidden md:flex md:justify-end">
          <table className="w-full lg:w-5/12">
            <thead>
              <tr className="text-sm h-4 bg-header border-b-2 text-left">
                <th className="font-normal pl-2 w-4/12">調査年月日</th>
                <th className="font-normal pl-2 w-4/12">レポート調査年月日</th>
                <th className="font-normal pl-2 w-4/12">企業DB更新年月日</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b-2 h-6">
                <CorporateTableData data={formatDate(corporateInfo.CHS_NNGP)} className="pl-2" />
                <CorporateTableData data={formatDate(corporateInfo.R_CS_YMD)} className="pl-2" />
                <CorporateTableData data={formatDate(corporateInfo.KDB_UP_YMD)} className="pl-2" />
              </tr>
            </tbody>
          </table>
        </div>
      </>
    )
  }
  // 基本情報テーブル
  const CorporateBasicInfoTable: FC = () => {
    return (
      <>
        {/* SP・タブレット画面 */}
        <div className="lg:hidden">
          <table className="w-full table-fixed mt-8">
            <caption className="text-left mb-1 md:-ml-3">基本情報</caption>
            <tbody>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2 w-6/12 md:w-3/12">TSRコード</th>
                <CorporateTableData data={corporateInfo.KCD} className="pl-2 w-6/12 md:w-9/12" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">D-U-N-S® Number</th>
                <CorporateTableData data={corporateInfo.DUNS} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">法人番号</th>
                <CorporateTableData data={corporateInfo.HJNO} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">顧客コード</th>
                <CorporateTableData data={corporateInfo.KKCD} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">インボイス登録番号</th>
                <CorporateTableData data={corporateInfo.TKSK_HKOJ_INNO} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">評点</th>
                <CorporateTableData data={HYTN} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">リスクスコア</th>
                <CorporateTableData data={RSK_SCR} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">カナ商号</th>
                <CorporateTableData data={corporateInfo.HJTK_SGKT} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">英文商号</th>
                <CorporateTableData data={corporateInfo.SGEI} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">郵便番号</th>
                <CorporateTableData data={formatZipcode(corporateInfo.JST_SZIC_ZIP)} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">所在地</th>
                <CorporateTableData data={corporateInfo.JST_ADKN} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">英文所在地</th>
                <CorporateTableData data={corporateInfo.JST_ADEI} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">電話番号</th>
                <CorporateTableData data={formatPhoneNumber(corporateInfo.JST_SZIC_ZNTI_TEL)} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">企業URL</th>
                <CorporateTableData data={corporateInfo.KGY_URL} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">資本金（千円）</th>
                <CorporateTableData data={formatNumberToLocaleString(corporateInfo.SHNKN)} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">上場区分</th>
                <CorporateTableData data={convertCodeIntoValue(corporateInfo.JJ_KBN, JJ_SJU_NAME)} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">株式コード</th>
                <CorporateTableData data={corporateInfo.KBCD} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">EDINETコード</th>
                <CorporateTableData data={corporateInfo.EDCD} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">議決権最上位者名</th>
                <CorporateTableData data={corporateInfo.GKTK_SIJO_MI} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">従業員数</th>
                <CorporateTableData data={formatNumberToLocaleString(corporateInfo.JGYINS)} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">事業所数</th>
                <CorporateTableData data={formatNumberToLocaleString(corporateInfo.JGYSH_S)} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">工場数</th>
                <CorporateTableData data={formatNumberToLocaleString(corporateInfo.KJYS)} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">創業年月</th>
                <CorporateTableData data={formatDate(corporateInfo.SGY_NNGT)} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">設立年月日</th>
                <CorporateTableData data={formatDate(corporateInfo.STRT_YMD)} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">概況</th>
                <CorporateTableData data={corporateInfo.SYRK_GKNY} className="pl-2" />
              </tr>
            </tbody>
          </table>
        </div>
        {/* PC画面 */}
        <div className="hidden lg:block">
          <table className="w-full table-fixed">
            <caption className="text-left mb-1 -ml-3">基本情報</caption>
            <tbody>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2 w-32">TSRコード</th>
                <CorporateTableData data={corporateInfo.KCD} className="pl-2" colSpan={2} />
                <th className="font-normal text-sm bg-header pl-2" colSpan={2}>D-U-N-S® Number</th>
                <CorporateTableData data={corporateInfo.DUNS} className="pl-2" colSpan={2} />
                <th className="font-normal text-sm bg-header pl-2">法人番号</th>
                <CorporateTableData data={corporateInfo.HJNO} className="pl-2" colSpan={3} />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">顧客コード</th>
                <CorporateTableData data={corporateInfo.KKCD} className="pl-2" colSpan={2} />
                <th className="font-normal text-sm bg-header pl-2" colSpan={2}>インボイス登録番号</th>
                <CorporateTableData data={corporateInfo.TKSK_HKOJ_INNO} className="pl-2" colSpan={2} />
                <th className="font-normal text-sm bg-header pl-2">評点</th>
                <CorporateTableData data={HYTN} className="pl-2" />
                <th className="font-normal text-sm bg-header pl-2">リスクスコア</th>
                <CorporateTableData data={RSK_SCR} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">カナ商号</th>
                <CorporateTableData data={corporateInfo.HJTK_SGKT} className="pl-2" colSpan={10} />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">英文商号</th>
                <CorporateTableData data={corporateInfo.SGEI} className="pl-2" colSpan={10} />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">郵便番号</th>
                <CorporateTableData data={formatZipcode(corporateInfo.JST_SZIC_ZIP)} className="pl-2" />
                <th className="font-normal text-sm bg-header pl-2">所在地</th>
                <CorporateTableData data={corporateInfo.JST_ADKN} className="pl-2" colSpan={8} />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">英文所在地</th>
                <CorporateTableData data={corporateInfo.JST_ADEI} className="pl-2" colSpan={10} />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">電話番号</th>
                <CorporateTableData data={formatPhoneNumber(corporateInfo.JST_SZIC_ZNTI_TEL)} className="pl-2" colSpan={2} />
                <th className="font-normal text-sm bg-header pl-2">企業URL</th>
                <CorporateTableData data={corporateInfo.KGY_URL} className="pl-2" colSpan={7} />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">資本金（千円）</th>
                <CorporateTableData data={formatNumberToLocaleString(corporateInfo.SHNKN)} className="pl-2" colSpan={2} />
                <th className="font-normal text-sm bg-header pl-2">上場区分</th>
                <CorporateTableData data={convertCodeIntoValue(corporateInfo.JJ_KBN, JJ_SJU_NAME)} className="pl-2" colSpan={2} />
                <th className="font-normal text-sm bg-header pl-2">株式コード</th>
                <CorporateTableData data={corporateInfo.KBCD} className="pl-2" />
                <th className="font-normal text-sm bg-header pl-2">EDINETコード</th>
                <CorporateTableData data={corporateInfo.EDCD} className="pl-2" colSpan={2} />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">議決権最上位者名</th>
                <CorporateTableData data={corporateInfo.GKTK_SIJO_MI} className="pl-2" colSpan={10} />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">従業員数</th>
                <CorporateTableData data={formatNumberToLocaleString(corporateInfo.JGYINS)} className="pl-2" />
                <th className="font-normal text-sm bg-header pl-2">事業所数</th>
                <CorporateTableData data={formatNumberToLocaleString(corporateInfo.JGYSH_S)} className="pl-2" />
                <th className="font-normal text-sm bg-header pl-2">工場数</th>
                <CorporateTableData data={formatNumberToLocaleString(corporateInfo.KJYS)} className="pl-2" />
                <th className="font-normal text-sm bg-header pl-2">創業年月</th>
                <CorporateTableData data={formatDate(corporateInfo.SGY_NNGT)} className="pl-2" />
                <th className="font-normal text-sm bg-header pl-2">設立年月日</th>
                <CorporateTableData data={formatDate(corporateInfo.STRT_YMD)} className="pl-2" colSpan={2} />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">概況</th>
                <CorporateTableData data={corporateInfo.SYRK_GKNY} className="pl-2" colSpan={10} />
              </tr>
            </tbody>
          </table>
        </div>
      </>
    )
  }
  return (
    <>
      <div className="lg:flex lg:justify-between">
        <div className="text-base md:text-xl font-bold mb-4">
          <span className="align-middle">{corporateInfo.RK_SZKN}</span>
        </div>
        <div className="mb-4 md:mb-8 h-8">
          <button tabIndex={-1}
            className="btn btn-sm w-[17rem] text-white flex-0 rounded-full border-none 
            bg-gradient-to-b from-action-from to-action-to"
            onClick={() => handleClickFile(corporateInfo.id)}>
            {clickFileDownload ?
              <svg className="animate-spin h-5 w-5 m-1 border-[3px] border-white rounded-full border-t-transparent mr-2"></svg> :
              <span className="material-symbols-outlined mr-1">
                file_download
              </span>
            }
            <span>PDFダウンロード</span>
          </button>
        </div>
      </div>
      <CorporateReportInfoTable />
      <CorporateBasicInfoTable />
    </>
  );
}

export default CorporateOverview;