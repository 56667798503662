/** バイト単位を付与 */
const appendByteUnit = (strValue: string) => {
  const value = Number(strValue);
  let filesize = '';
  if (!value) {
    filesize = '';
  } else if (value >= 1024 * 1024 * 1024) {
    filesize = '(' + Math.floor(value / 1024 / 1024 / 1024) + ' GB)';
  } else if (value >= 1024 * 1024) {
    filesize = '(' + Math.floor(value / 1024 / 1024) + ' MB)';
  } else if (value >= 1024) {
    filesize = '(' + Math.floor(value / 1024) + ' KB)';
  } else {
    filesize = '(' + value + ' Byte)'
  }
  return filesize;
}

export default appendByteUnit;