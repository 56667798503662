import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import usePagination from 'hooks/Pagination';
import { DELIVER_LIST, DELIVERY_STATUS } from 'constant';
import { Auth } from 'aws-amplify';
import { SessionTimeoutMsg } from 'App';
import { useNavigate, useSearchParams } from 'react-router-dom';

export type Props = {
  id: string | null,
  delivers: DeliverInfo[],
  selected: DeliverInfo | undefined,
  setSelected: React.Dispatch<React.SetStateAction<DeliverInfo | undefined>>,
};

/** 納品情報リスト */
const DeliverList: FC<Props> = props => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // セッションタイムアウトメッセージ
  const { hidden, setHidden } = useContext(SessionTimeoutMsg);
  /** 納品番号 */
  const id = searchParams.get("id");
  /** 絞り込み結果_納品情報一覧 */
  const [displayItems, setDisplayItems] = useState<DeliverInfo[]>([]);
  /** 表示データ */
  const data = usePagination(displayItems);
  /** 表示中のページ */
  const [page, setPage] = useState(1);
  /** ソート項目・ソート順(1:昇順, -1:降順) */
  const [sort, setSort] = useState({ 'item': 'deliveredDate', 'order': -1 });
  /** 検索キーワード */
  const [keyword, setKeyword] = useState("");
  /** 絞り込み結果 */
  const [notFound, setNotFound] = useState(false);

  /** 納品リスト初期化 */
  useEffect(() => {
    // 絞り込み用に納品情報をディープコピー
    const items = props.delivers.map(item => { return { ...item } });
    // 初期表示の場合は納品日降順、それ以外の場合は現在のソートで再レンダリング
    if (displayItems.length === 0) {
      sortItem(items, 'deliveredDate', -1);
    } else {
      sortItem(items, sort.item, sort.order);
    }
    setDisplayItems(items);
    // 指定されたIDを選択状態に、指定ない場合は1行目を選択
    const result = items.find(item => item.id === props.id);
    if (!props.id || !result) {
      props.setSelected(items[0]);
      setPage(1);
    } else {
      const index = items.indexOf(result) + 1;
      const page = Math.ceil(index / DELIVER_LIST.PER_PAGE);
      setPage(page);
      props.setSelected(result);
    }
  }, [props.delivers]);

  /** 指定したページを表示 */
  useEffect(() => {
    data.jump(page);
  }, [displayItems, page]);

  /** 絞り込み検索キーワード入力 */
  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
  }

  /** 絞り込み検索 */
  useMemo(() => {
    if (keyword === '') {
      const items = props.delivers.map(deliver => { return { ...deliver } });
      setDisplayItems(items);
      setNotFound(false);
      return;
    }
    // キーワードクレンジング
    const target = keyword.trim().replaceAll('/', '').replaceAll('-', '');
    // 絞り込み
    const filtered = props.delivers.filter(item => {
      if (item.productName.startsWith(target)) {
        return true;
      }
      if (item.orderDate.replaceAll('/', '').startsWith(target)) {
        return true;
      }
      if (item.deliveredDate.replaceAll('/', '').startsWith(target)) {
        return true;
      }
      if (item.expireDate.replaceAll('/', '').startsWith(target)) {
        return true;
      }
      return false;
    });
    setNotFound(filtered.length === 0);
    if (filtered.length !== 0) {
      setDisplayItems(filtered);
    }
  }, [keyword]);

  /** ソート */
  const handleClickSort = (event: any, selectedItem: string) => {
    // 選択中の項目であればソート順をトグル、そうでなければ昇順
    let order = 1;
    if (selectedItem === sort.item) {
      order = sort.order * -1
    }
    sortItem(displayItems, selectedItem, order);
    setDisplayItems(displayItems);
    setSort({ 'item': selectedItem, 'order': order });
  };

  /** ソート実行 */
  const sortItem = (target: DeliverInfo[], selectedItem: string, order: number) => {
    // @ts-ignore 動的なブラケット記法を許容
    const key: keyof DeliverInfo = selectedItem;
    target.sort((first, second) => {
      if (first[key]! > second[key]!) { return order; }
      else if (first[key]! < second[key]!) { return order * -1; }
      else {
        // 第2ソートは商品名昇順
        if (first['productName'] < second['productName']) { return order; }
        else if (first['productName'] > second['productName']) { return order * -1; }
        else { return 0; }
      }
    });
  }

  const handleClickRow = async (event: any, item: DeliverInfo) => {
    // セッションタイムアウトチェック
    try {
      await Auth.currentSession();
    } catch (noSession) {
      setHidden('');
      navigate("/");
      return false;
    }
    props.setSelected(item)
  }

  return (
    <>
      {/** エラーメッセージ */}
      <div className="text-error text-xs ml-2 -mt-3 mb-1">
        <span className={notFound ? "" : "invisible"}>
          条件に一致する商品が見つかりません
        </span>
      </div>
      <div className='flex flex-col gap-2 md:flex-row justify-between'>
        {/** 検索ボックス */}
        <div className="form-control">
          <input type="text" tabIndex={1} value={keyword}
            placeholder="商品名・日付で絞り込み"
            className="input input-bordered input-sm flex-none w-72 rounded-2xl"
            onChange={event => handleChangeSearch(event)} />
        </div>
        <button tabIndex={-1}
          className="btn btn-outline rounded-full btn-sm w-[68px]
           outline-button-typo text-button-typo 
           hover:bg-button-typo hover:outline-button-typo"
          onClick={event => setKeyword('')}>
          クリア
        </button>
        {/** ページャー */}
        <div className='flex md:ml-auto'>
          <button tabIndex={-1} className={data.currentPage <= 1 ? "pager-disabled" : "pager"}
            onClick={() => setPage(1)}
            style={data.currentPage <= 1 ? { pointerEvents: 'none' } : {}}>
            <span className='material-symbols-outlined'>first_page</span>
          </button>
          <button tabIndex={-1} className={data.currentPage <= 1 ? "pager-disabled" : "pager"}
            onClick={() => setPage(data.currentPage - 1)}
            style={data.currentPage <= 1 ? { pointerEvents: 'none' } : {}}>
            <span className='material-symbols-outlined'>navigate_before</span>
          </button>
          <div className="w-28 mt-1 ml-2">
            {data.currentBegin()}～{data.currentEnd()}／{displayItems.length}件
          </div>
          <button tabIndex={-1} className={data.currentPage === data.maxPage ? "pager-disabled" : "pager"}
            onClick={() => setPage(data.currentPage + 1)}
            style={data.currentPage === data.maxPage ? { pointerEvents: 'none' } : {}}>
            <span className='material-symbols-outlined'>navigate_next</span>
          </button>
          <button tabIndex={-1} className={data.currentPage === data.maxPage ? "pager-disabled" : "pager"}
            onClick={() => setPage(data.maxPage)}
            style={data.currentPage === data.maxPage ? { pointerEvents: 'none' } : {}}>
            <span className='material-symbols-outlined'>last_page</span>
          </button>
        </div>
      </div>
      <div className="overflow-x-auto pt-5 lg:pt-2.5 pb-5">
        <table className="w-full table-fixed">
          <thead>
            <tr>
              <th className='text-sm text-center rounded-none font-normal p-2 bg-table-header static w-16'>
                <span>選択</span>
              </th>
              <th className='text-sm text-left font-normal p-2 bg-table-header w-52'>
                <button tabIndex={-1} onClick={event => handleClickSort(event, 'productName')}>
                  商品名
                  <span className={sort.item === "productName" ? "text-main ml-2" : "invisible"}>
                    {sort.order === 1 ? '▲' : '▼'}
                  </span>
                </button>
              </th>
              <th className='text-sm text-left font-normal p-2 bg-table-header w-24'>
                <button tabIndex={-1} onClick={event => handleClickSort(event, 'orderDate')}>
                  注文日
                  <span className={sort.item === "orderDate" ? "text-main ml-2" : "invisible"}>
                    {sort.order === 1 ? '▲' : '▼'}
                  </span>
                </button>
              </th>
              <th className='text-sm text-left font-normal p-2 bg-table-header w-24'>
                <button tabIndex={-1} onClick={event => handleClickSort(event, 'deliveredDate')}>
                  納品日
                  <span className={sort.item === "deliveredDate" ? "text-main ml-2" : "invisible"}>
                    {sort.order === 1 ? '▲' : '▼'}
                  </span>
                </button>
              </th>
              <th className='text-sm rounded-none font-normal p-2 bg-table-header w-40'>
                <button tabIndex={-1} onClick={event => handleClickSort(event, 'expireDate')}>
                  データ保管期限
                  <span className={sort.item === "expireDate" ? "text-main ml-2" : "invisible"}>
                    {sort.order === 1 ? '▲' : '▼'}
                  </span>
                </button>
              </th>
              <th className='text-sm rounded-none font-normal p-2 pl-0 bg-table-header w-[5.5rem]'>
                <button tabIndex={-1} onClick={event => handleClickSort(event, 'deliveryStatus')}>
                  ﾀﾞｳﾝﾛｰﾄﾞ
                  <span className={sort.item === "deliveryStatus" ? "text-main ml-2" : "invisible"}>
                    {sort.order === 1 ? '▲' : '▼'}
                  </span>
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.currentData().map((item: any) => {
              return (
                <tr key={item.id}
                  className={"[&>td]:hover:bg-row-select " + ((item.id === props.selected!.id) ? '[&>td]:bg-row-select' : '')}
                  onClick={event => handleClickRow(event, item)}>
                  <td className='text-center pt-2 pb-0.5 cursor-pointer rounded-none'>
                    <span className="material-symbols-rounded text-orange-400 ml-0.5">
                      {item.id === props.selected?.id ? "folder_open" : "folder"}
                    </span>
                  </td>
                  <td className='p-1.5 cursor-pointer text-sm' title={item.productName}>
                    <span className='line-clamp-2'>{item.productName}</span>
                  </td>
                  <td className='p-1.5 cursor-pointer text-sm'>{item.orderDate}</td>
                  <td className='p-1.5 cursor-pointer text-sm'>{item.deliveredDate}</td>
                  <td className='p-1.5 cursor-pointer rounded-none text-sm'>
                    <div className='flex'>
                      <div className="-mt-0 text-sm">{item.expireDate}</div>
                      <div className="text-xs mt-px ml-4 text-accent">
                        {item.untilExpireDate}
                      </div>
                    </div>
                  </td>
                  <td className='p-1.5 pl-8 cursor-pointer text-sm'>{item.deliveryStatus === DELIVERY_STATUS.DOWNLOADED ? "済" : "未"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default DeliverList;
