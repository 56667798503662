import '@aws-amplify/ui-react/styles.css';
import awsconfig from 'aws-exports';
import { createContext, FC, useState } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { vocabularies } from 'pages/login/Vocablaries';
import { components } from 'pages/login/Compornents';
import { formFields } from 'pages/login/FormFields';
import NotFound from 'pages/notfound/NotFound';
import Error from 'pages/error/Error';
import LogoutIcon from 'icons/LogoutIcon';
import Deliver from 'pages/deliver/Deliver';
import Dashboard from 'pages/dashboard/Dashboard';
import Corporate from 'pages/corporate/Corporate';
import CorporateList from 'pages/corporate/CorporateList';

Amplify.configure(awsconfig);

// ログイン画面の日本語設定
vocabularies('ja');
// セッションタイムアウトメッセージContext
export const SessionTimeoutMsg = createContext<SessionTimeoutMsg>({} as SessionTimeoutMsg);
// 企業データ一覧 検索結果表示固定Context
export const viewCorporateListData = createContext({} as { viewCorporateList: any; setViewCorporateList: any });

const App: FC = () => {
  // サインアウト処理
  const handleSignOut = (signOut: any) => {
    setViewCorporateList("");
    sessionStorage.clear();
    window.history.replaceState("", "", "/");
    signOut();
  }
  // セッションタイムアウトメッセージ:非表示
  const [hidden, setHidden] = useState<string>('hidden');
  const sessionTimeoutMsgState = { hidden, setHidden };
  // 企業データ一覧画面、検索結果表示固定
  const [viewCorporateList, setViewCorporateList] = useState<any>("")
  return (
    <viewCorporateListData.Provider value={{ viewCorporateList, setViewCorporateList }}>
        <SessionTimeoutMsg.Provider value={sessionTimeoutMsgState}>
          <Authenticator.Provider>
            <Authenticator hideSignUp={true} components={components} formFields={formFields}>
              {({ signOut, user }) => (
                <div className="flex flex-col min-h-screen overflow-hidden">
                  {/** ナビゲーションバー */}
                  <nav className='navbar bg-main p-1' style={{ minHeight: '1px' }}>
                    <img src="/symbol-color-min.jpg" className='ml-3 mr-4 object-contain h-9' alt="株式会社東京商工リサーチ" title="東京商工リサーチ" />
                    <div className='text-white ml-auto mr-4 text-lg'>{user && user.attributes && user.attributes['email']}</div>
                    <div className='mr-5 ml-1'>
                      <button title='ログアウト' onClick={() => handleSignOut(signOut)}>
                        <LogoutIcon />
                      </button>
                    </div>
                  </nav>
                  {/** メイン */}
                  <main className="xl:flex flex-grow">
                    <HashRouter>
                      <Routes>
                        <Route index element={<Deliver user={user?.attributes} />} />
                        <Route path="/index" element={<Deliver user={user?.attributes} />} />
                        <Route path="/corporatelist" element={<CorporateList />} />
                        <Route path="/corporate/:corporateId" element={<Corporate />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/error" element={<Error />} />
                        <Route path="*" element={<NotFound />} />
                      </Routes>
                    </HashRouter>
                  </main>
                  {/** フッター */}
                  <footer className="footer p-1.5 bg-main flex flex-row-reverse">
                    <p className='text-white text-right mr-5'>&copy; 2023 TOKYO SHOKO RESEARCH, LTD.</p>
                  </footer>
                </div >
              )}
            </Authenticator>
          </Authenticator.Provider>
        </SessionTimeoutMsg.Provider>
    </viewCorporateListData.Provider>
  );
}

export default App;
