import { I18n } from "aws-amplify";
import { translations } from '@aws-amplify/ui-react';

export const vocabularies = (lang: string): void => {
  I18n.putVocabularies(translations);
  I18n.setLanguage(lang);
  I18n.putVocabularies({
    ja: {
      // ログイン画面
      'Enter your Email': 'メールアドレスを入力してください',
      'Enter your Password': 'パスワードを入力してください',
      'User does not exist.': 'メールアドレスまたはパスワードが違います',
      'Incorrect username or password.': "メールアドレスまたはパスワードが違います",
      'Sign In': 'ログイン',
      'Sign in': 'ログイン',
      'Signing in': 'ログイン中...',
      'Forgot your password?': 'パスワードをお忘れになった場合はこちら',
      'Temporary password has expired and must be reset by an administrator.': '初期パスワードの有効期限が切れています。東京商工リサーチの担当者へご連絡ください。',
      // パスワード変更
      'Password not long enough': '8文字以上を入力してください',
      'Password must have at least 8 characters': '8文字以上を入力してください',
      'Password must have numbers': '数字を1文字以上含めてください',
      'Password must have lower case letters': '英字は小文字を使用してください',
      'Your passwords must match': '新しいパスワードを正しく再入力してください',
      // パスワードリセット
      'Code': '確認コード',
      'Reset Password': "パスワードリセット",
      'Enter your email': 'メールアドレス',
      'Send code': '確認コードを送信',
      'Back to Sign In': "ログイン画面に戻る",
      'Username/client id combination not found.': 'メールアドレスが不正です',
      'User password cannot be reset in the current state.': '初回ログインが行われていないため、パスワードリセットを行うことができません。メールで送付された初期パスワードで初回ログインを行ってください。',
      // 確認コード入力
      'New Password': '新しいパスワード',
      'Resend Code': '確認コードを再送信',
      'Confirm Password': 'パスワードの確認',
      'Submit': '送信',
      "Username cannot be empty": "メールアドレスを入力してください",
      'Invalid verification code provided, please try again.': 'メールに送付された確認コードを入力してください',
      'Attempt limit exceeded, please try after some time.': '確認コード発行回数が制限を超えました。暫くしてからもう一度お試しください',
      'Confirmation code cannot be empty': '確認コードを入力してください',
      'Password cannot be empty': 'パスワードを入力してください',
      // 初期パスワード変更
      'Change Password': "初期パスワード変更",
      // システムエラー
      'Configuration error (see console) – please contact the administrator': 'システムエラーが発生しました。暫くしてからもう一度お試しください'

    }
  });
}