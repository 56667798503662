import '@aws-amplify/ui-react/styles.css';
import { useEffect, useState, FC, useContext, useRef } from 'react';
import checkExpiredStatus from 'shared/checkExpiredStatus';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { SessionTimeoutMsg, viewCorporateListData } from 'App';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import formatPhoneNumber from 'shared/formatPhoneNumber';
import { NOT_PURCHASED } from 'constant';
import useTruncated from 'hooks/Truncated';
import executeApi from 'shared/executeApi';
import getCurrentDateTime from 'shared/getCurrentDateTime';

export type CorporateList = {
  ratingRange: DistributionRange | null,
  riskRange: DistributionRange | null,
};

const _query = `query ListCorporatesByUploadId(
  $uploadId: String!
  $KCD: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCorporateFilterInput
  $limit: Int
  $nextToken: String
) {
  listCorporatesByUploadId(
    uploadId: $uploadId
    KCD: $KCD
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken) {
      nextToken
      items {
        id,
        uploadId,
        username,
        createdAt,
        updatedAt,
        _version,
        _lastChangedAt,
        KCD,
        RK_SZKN,
        JST_ADKN,
        JST_SZIC_ZNTI_TEL,
        SHNKN,
        JGYINS,
        SNTI_GYSI_URAG_1,
        SNTI_GYSI_REK_1,
        DHS_SIMIKN,
        HYTN,
        RSK_SCR,
        KKCD,
        SG_INKT
      }
  }
}
`

type TruncatableTextProps = {
  text: string | number | null;
}

// ページング機能
const usePagination = <T,>(data: T[]) => {
  // 検索結果表示数
  const displayNum = 50

  /** 現在ページ */
  const [currentPage, setCurrentPage] = useState(1);
  /** 総ページ数 */
  const maxPage = Math.ceil(data.length / displayNum);

  /** 現在ページのデータ */
  const currentData = () => {
    if (maxPage !== 0 && currentPage > maxPage) {
      // 絞り込みで現在ページが最大ページを超える場合、最終ページへ
      setCurrentPage(maxPage);
    }
    const begin = (currentPage - 1) * displayNum;
    const end = begin + displayNum;
    return data.slice(begin, end);
  }
  /** 現在ページデータの開始件数 */
  const currentBegin = () => {
    const result = (currentPage - 1) * displayNum + 1;
    return result < 0 ? 0 : result;
  }
  /** 現在ページデータの最終件数 */
  const currentEnd = () => {
    const result = (currentPage - 1) * displayNum + currentData().length
    return result < 0 ? 0 : result;
  }
  /** 次ページへ */
  const next = () => (
    setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage))
  )
  /** 前ページへ */
  const prev = () => (
    setCurrentPage(currentPage => Math.max(currentPage - 1, 1))
  )
  /** 指定ページへ */
  const jump = (page: number) => {
    const pageNumber = Math.max(1, page);
    setCurrentPage(currentPage => Math.min(pageNumber, maxPage));
  }

  return { next, prev, jump, currentData, currentBegin, currentEnd, currentPage, maxPage };
}

const CorporateList: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isFirstRender = useRef(true);
  /** 評点 */
  const [ratingRange, setRatingRange] = useState<any>();
  /** リスクスコア */
  const [riskRange, setRiskRange] = useState<any>();
  // 全データ
  const [corporateInfo, setCorporateInfo] = useState<CorporateInfo[]>([]);
  /** 企業情報一覧 */
  const [items, setItems] = useState<any[]>([]);
  /** 表示データ */
  const data = usePagination(items);
  /** 表示中のページ */
  const [page, setPage] = useState(1);
  /** 絞り込み結果 */
  const [notFound, setNotFound] = useState("success");
  /** セッションタイムアウトメッセージ */
  const { hidden, setHidden } = useContext(SessionTimeoutMsg);
  /** 企業データ一覧画面、検索結果表示固定 */
  const { viewCorporateList, setViewCorporateList } = useContext(viewCorporateListData)
  /** ソート項目・ソート順(1:昇順, -1:降順) */
  const [sort, setSort] = useState({ 'item': '', 'order': -1 });
  /** 納品番号 */
  const id = searchParams.get("id");
  /** アップロードID */
  const uploadId = searchParams.get("uploadId");
  /** 商品名 */
  const productName = searchParams.get("productName");
  /** 納品日付 */
  const deliveredDate = searchParams.get("deliveredDate");
  /** 有効期限日 */
  const expireDate = searchParams.get("expireDate");
  /** 検索条件をState管理 */
  // TSR企業コード
  const [searchTsrCode, setSearchTsrCode] = useState("")
  // 顧客コード
  const [searchClientCode, setSearchClientCode] = useState("")
  // 漢字商号
  const [searchBusinessName, setSearchBusinessName] = useState("")
  // カナ商号
  const [searcBusinessNameKana, setSearcBusinessNameKana] = useState("")
  // 代表者氏名
  const [searcRepresentativeName, setSearcRepresentativeName] = useState("")
  // 所在地
  const [searcAddress, setSearcAddress] = useState("")
  // 評点
  const [searcRatingMin, setSearcRatingMin] = useState<any>()
  const [searcRatingMax, setSearcRatingMax] = useState<any>()
  // リスクスコア
  const [searcRiskScoreMin, setSearcRiskScoreMin] = useState<any>()
  const [searcRiskScoreMax, setSearcRiskScoreMax] = useState<any>()
  // 従業員数
  const [searcEmployeeNumMin, setSearcEmployeeNumMin] = useState("")
  const [searcEmployeeNumMax, setSearcEmployeeNumMax] = useState("")
  // 資本金
  const [searcCapitalMin, setSearcCapitalMin] = useState("")
  const [searcCapitalMax, setSearcCapitalMax] = useState("")
  // 最新売上高
  const [searcLatestSalesMin, setSearcLatestSalesMin] = useState("")
  const [searcLatestSalesMax, setSearcLatestSalesMax] = useState("")
  // 最新利益金
  const [searcLatestProfitMin, setSearcLatestProfitMin] = useState("")
  const [searcLatestProfitMax, setSearcLatestProfitMax] = useState("")

  /** 入力項目エラーをState管理 */
  // TSR企業コード
  const [tsrCodeError, setTsrCodeError] = useState("success")
  // TSR企業コード
  const [clientCodeError, setClientCodeError] = useState("success")
  // 漢字商号
  const [businessNameError, setBusinessNameError] = useState("success")
  // カナ商号
  const [businessNameKanaError, setBusinessNameKanaError] = useState("success")
  // 代表者氏名
  const [representativeNameError, setRepresentativeNameError] = useState("success")
  // 所在地
  const [addressError, setAddressError] = useState("success")
  // 評点
  const [ratingError, setRatingError] = useState("success")
  // リスクスコア
  const [riskScoreError, setriskScoreError] = useState("success")
  // 従業員数
  const [employeeNumError, setEmployeeNumError] = useState("success")
  // 資本金
  const [capitalError, setCapitalError] = useState("success")
  // 最新売上高
  const [latestSalesError, setLatestSalesError] = useState("success")
  // 最新利益金
  const [latestProfitError, setLatestProfitError] = useState("success")

  // 検索クエリ
  const [query, setQuery] = useState<any>()

  // 検索条件アコーディオン
  const [accordion, setAccordion] = useState(true)

  // 評点 表示値
  // @ts-ignore
  const [displayRatingMin, setDisplayRatingMin] = useState<number>("")
  // @ts-ignore
  const [displayRatingMax, setDisplayRatingMax] = useState<number>("")

  // リスクスコア　表示値
  // @ts-ignore
  const [displayRiskScoreMin, setDisplayRiskScoreMin] = useState<number>("")
  // @ts-ignore
  const [displayRiskScoreMax, setDisplayRiskScoreMax] = useState<number>("")

  // 評点チェックボックス管理
  const [ratinCheckBox, setRatinCheckBox] = useState(false)

  // リスクスコアチェックボックス管理
  const [riskScoreCheckBox, setRiskScoreCheckBox] = useState(false)

  // 評点入力欄の管理
  const [ratinInputBox, setRatinInputBox] = useState(false)

  // リスクスコア入力欄の管理
  const [riskScoreInputBox, setRiskScoreInputBox] = useState(false)

  // ボタン活性、非活性の管理
  const [buttonDisabled, setButtonDisabled] = useState(false)

  // CSVダウンロードの連打防止ステータス管理
  const [downloadDelay, setdownloadDelay] = useState(true)
  // CSVダウンロードの権限
  const [csvButtonDisabled, setCsvButtonDisabled] = useState<boolean>(false)

  // クエリパラメータの存在、有効期限切れチェック
  useEffect(() => {
    setHidden('hidden');
    if (!(id && uploadId && productName && deliveredDate && expireDate) || checkExpiredStatus(expireDate)) {
      navigate('/error');
      return;
    }
    // ファイルダウンロードの権限判定
    const data = async () => {
      let sessionToken: any
      // セッションタイムアウトチェック
      try {
        sessionToken = await Auth.currentSession();
      } catch (noSession) {
        setHidden('');
        navigate("/");
        return;
      }
      // API実行
      const baseURL = process.env.REACT_APP_DASHBOARD_API_ROOT!;
      const path = "/dashboad/usagerole/execution";
      const request = {
        "id": id,
        "function": "DownloadCsv"
      };
      const response = await executeApi(baseURL, path, sessionToken.idToken.jwtToken, 1, request);
      if (!response || response.data.data.available === "") {
        navigate('/error');
        return;
      }
      setCsvButtonDisabled(response.data.data.available)
    }
    data()
  }, []);

  // 企業データ一覧画面、検索結果の表示を固定
  useEffect(() => {
    const list = {
      searchTsrCode, searchClientCode, searchBusinessName, searcRepresentativeName, searcAddress, searcRatingMin, searcRatingMax, searcBusinessNameKana,
      searcRiskScoreMin, searcRiskScoreMax, searcEmployeeNumMin, searcEmployeeNumMax, searcCapitalMin, searcCapitalMax, searcLatestSalesMin, searcLatestSalesMax,
      searcLatestProfitMin, searcLatestProfitMax, displayRatingMin, displayRatingMax, displayRiskScoreMin, displayRiskScoreMax, ratinCheckBox, riskScoreCheckBox, items, page, sort, corporateInfo
    }
    setViewCorporateList(list)
  }, [items, page, sort, corporateInfo]);

  // 評点チェックボンタン切り替え処理
  const RatinCheckBoxhandle = () => {
    if (ratinCheckBox) {
      // チェックが入っていないとき
      setRatinCheckBox(false)
      setRatinInputBox(false)
    } else {
      // チェックが入っているとき
      setRatinCheckBox(true)
      setRatinInputBox(true)
    }
  }
  // リスクスコアチェックボンタン切り替え処理
  const riskScoreCheckBoxhandle = () => {
    if (riskScoreCheckBox) {
      // チェックが入っていないとき
      setRiskScoreCheckBox(false)
      setRiskScoreInputBox(false)
    } else {
      // チェックが入っているとき
      setRiskScoreCheckBox(true)
      setRiskScoreInputBox(true)
    }
  }

  /** ページング指定したページを表示 */
  useEffect(() => {
    data.jump(page);
  }, [items, page]);

  /** 検索条件のチェック処理、正常ならstateにセット */
  // ---------------------------------------------------------------
  // TSR企業コード
  // ---------------------------------------------------------------
  const SearchTsrCode = (event: any) => {
    setTsrCodeError("success")
    if (!(event.target.value.length <= 9)) {
      setTsrCodeError("lengthError")
    }
    if (!(event.target.value.match(/^[0-9a-zA-Z]*$/))) {
      setTsrCodeError("NumError")
    }
    setSearchTsrCode(event.target.value)
  }
  // ---------------------------------------------------------------
  // 顧客コード
  // ---------------------------------------------------------------
  const ClientCode = (event: any) => {
    setClientCodeError("success")
    if (!(event.target.value.length <= 14)) {
      setClientCodeError("lengthError")
    }
    setSearchClientCode(event.target.value)
  }
  // ---------------------------------------------------------------
  // 漢字商号
  // ---------------------------------------------------------------
  const BusinessName = (event: any) => {
    setBusinessNameError("success")
    if (!(event.target.value.length <= 100)) {
      setBusinessNameError("lengthError")
    }
    setSearchBusinessName(event.target.value)
  }
  // ---------------------------------------------------------------
  // カナ商号
  // ---------------------------------------------------------------
  const BusinessNameKana = (event: any) => {
    setBusinessNameKanaError("success")
    if (!(event.target.value.match(/^[ァ-ンヴ－]*$/))) {
      setBusinessNameKanaError("kanaError")
    }
    if (!(event.target.value.length <= 60)) {
      setBusinessNameKanaError("lengthError")
    }
    setSearcBusinessNameKana(event.target.value)
  }
  // ---------------------------------------------------------------
  // 代表者氏名
  // ---------------------------------------------------------------
  const RepresentativeName = (event: any) => {
    setRepresentativeNameError("success")
    if (!(event.target.value.length <= 146)) {
      setRepresentativeNameError("lengthError")
    }
    setSearcRepresentativeName(event.target.value)
  }
  // ---------------------------------------------------------------
  // 所在地
  // ---------------------------------------------------------------
  const Address = (event: any) => {
    setAddressError("success")
    if (!(event.target.value.length <= 100)) {
      setAddressError("lengthError")
    }
    setSearcAddress(event.target.value)
  }
  // ---------------------------------------------------------------
  // 評点
  // ---------------------------------------------------------------
  // 評点min
  const RatingMin = (event: any) => {
    setDisplayRatingMin(event.target.value)
    setSearcRatingMin(event.target.value)
  }
  // 評点max
  const RatingMax = (event: any) => {
    setDisplayRatingMax(event.target.value)
    setSearcRatingMax(event.target.value)
  }
  // バリデーションチェック処理
  useEffect(() => {
    setRatingError("success")
    // 評点min
    if (searcRatingMin) {
      // 文字数チェック
      if (String(searcRatingMin).length > 4) {
        setRatingError("lengthError")
      }
      // 正規表現 正の整数、負の整数を許容
      if (!(String(searcRatingMin).match(/^-?[0-9]*$/)) || searcRatingMin === "-") {
        setRatingError("NumError")
      }
    }
    // 評点max
    if (searcRatingMax) {
      // 文字数チェック
      if (String(searcRatingMax).length > 4) {
        setRatingError("lengthError")
      }
      // 正規表現 正の整数、負の整数を許容
      if (!(String(searcRatingMax).match(/^-?[0-9]*$/)) || searcRatingMax === "-") {
        setRatingError("NumError")
      }
    }
    // 検索範囲
    if (searcRatingMin && searcRatingMax) {
      if ((Number(searcRatingMin) > Number(searcRatingMax))) {
        setRatingError("rangeError")
      }
    }
  }, [searcRatingMin, searcRatingMax]);
  // ---------------------------------------------------------------
  // リスクスコア
  // ---------------------------------------------------------------
  // リスクスコアmin
  const RiskScoreMin = (event: any) => {
    setDisplayRiskScoreMin(event.target.value)
    setSearcRiskScoreMin(event.target.value)
  }
  // リスクスコアmax
  const RiskScoreMax = (event: any) => {
    setDisplayRiskScoreMax(event.target.value)
    setSearcRiskScoreMax(event.target.value)
  }
  // バリデーションチェック処理
  useEffect(() => {
    setriskScoreError("success")
    // リスクスコアmin
    if (searcRiskScoreMin) {
      // 文字数チェック
      if (String(searcRiskScoreMin).length > 3) {
        setriskScoreError("lengthError")
      }
      // 正規表現 正の整数、負の整数を許容
      if (!(String(searcRiskScoreMin).match(/^-?[0-9]*$/)) || searcRiskScoreMin === "-") {
        setriskScoreError("NumError")
      }
    }
    // リスクスコアmax
    if (searcRiskScoreMax) {
      // 文字数チェック
      if (String(searcRiskScoreMax).length > 3) {
        setriskScoreError("lengthError")
      }
      // 正規表現 正の整数、負の整数を許容
      if (!(String(searcRiskScoreMax).match(/^-?[0-9]*$/)) || searcRiskScoreMax === "-") {
        setriskScoreError("NumError")
      }
    }
    // 検索範囲
    if (searcRiskScoreMin && searcRiskScoreMax) {
      if (Number(searcRiskScoreMin) > Number(searcRiskScoreMax)) {
        setriskScoreError("rangeError")
      }
    }
  }, [searcRiskScoreMin, searcRiskScoreMax]);
  // ---------------------------------------------------------------
  // 従業員数
  // ---------------------------------------------------------------
  // 従業員数min
  const EmployeeNumMin = (event: any) => {
    setSearcEmployeeNumMin(event.target.value)
  }
  // 従業員数max
  const EmployeeNumMax = (event: any) => {
    setSearcEmployeeNumMax(event.target.value)
  }
  // バリデーションチェック処理
  useEffect(() => {
    setEmployeeNumError("success")
    // 文字数チェック
    if (searcEmployeeNumMin.length > 8) {
      setEmployeeNumError("lengthError")
    }
    if (searcEmployeeNumMax.length > 8) {
      setEmployeeNumError("lengthError")
    }
    // 正規表現 正の整数
    if (!(searcEmployeeNumMin.match(/^[0-9]*$/))) {
      setEmployeeNumError("NumError")
    }
    if (!(searcEmployeeNumMax.match(/^[0-9]*$/))) {
      setEmployeeNumError("NumError")
    }
    // 検索範囲
    if (searcEmployeeNumMin && searcEmployeeNumMax) {
      if (Number(searcEmployeeNumMin) > Number(searcEmployeeNumMax)) {
        setEmployeeNumError("rangeError")
      }
    }
  }, [searcEmployeeNumMin, searcEmployeeNumMax]);
  // ---------------------------------------------------------------
  // 資本金
  // ---------------------------------------------------------------
  // 資本金（千円）min
  const CapitalMin = (event: any) => {
    setSearcCapitalMin(event.target.value)
  }
  // 資本金（千円）max
  const CapitalMax = (event: any) => {
    setSearcCapitalMax(event.target.value)
  }
  // バリデーションチェック処理
  useEffect(() => {
    setCapitalError("success")
    // 文字数チェック
    if (searcCapitalMin.length > 11) {
      setCapitalError("lengthError")
    }
    if (searcCapitalMax.length > 11) {
      setCapitalError("lengthError")
    }
    // 正規表現 正の整数、負の整数を許容
    if (!(searcCapitalMin.match(/^-?[0-9]*$/)) || searcCapitalMin === "-") {
      setCapitalError("NumError")
    }
    if (!(searcCapitalMax.match(/^-?[0-9]*$/)) || searcCapitalMax === "-") {
      setCapitalError("NumError")
    }
    // 検索範囲
    if (searcCapitalMin && searcCapitalMax) {
      if (Number(searcCapitalMin) > Number(searcCapitalMax)) {
        setCapitalError("rangeError")
      }
    }
  }, [searcCapitalMin, searcCapitalMax]);
  // ---------------------------------------------------------------
  // 売上高
  // ---------------------------------------------------------------
  // 最新売上高（千円）minをセット
  const LatestSalesMin = (event: any) => {
    setSearcLatestSalesMin(event.target.value)
  }
  // 最新売上高（千円）maxをセット
  const LatestSalesMax = (event: any) => {
    setSearcLatestSalesMax(event.target.value)
  }
  // バリデーションチェック処理
  useEffect(() => {
    setLatestSalesError("success")
    // 文字数チェック
    if (searcLatestSalesMin.length > 13) {
      setLatestSalesError("lengthError")
    }
    if (searcLatestSalesMax.length > 13) {
      setLatestSalesError("lengthError")
    }
    // 正規表現 正の整数、負の整数を許容
    if (!(searcLatestSalesMin.match(/^-?[0-9]*$/)) || searcLatestSalesMin === "-") {
      setLatestSalesError("NumError")
    }
    if (!(searcLatestSalesMax.match(/^-?[0-9]*$/)) || searcLatestSalesMax === "-") {
      setLatestSalesError("NumError")
    }
    // 検索範囲
    if (searcLatestSalesMin && searcLatestSalesMax) {
      if (Number(searcLatestSalesMin) > Number(searcLatestSalesMax)) {
        setLatestSalesError("rangeError")
      }
    }
  }, [searcLatestSalesMin, searcLatestSalesMax]);
  // ---------------------------------------------------------------
  // 最新利益金（千円）
  // ---------------------------------------------------------------
  // 最新利益金（千円）minをセット
  const LatestProfitMin = (event: any) => {
    setSearcLatestProfitMin(event.target.value)
  }
  // 最新利益金（千円）maxをセット
  const LatestProfitMax = (event: any) => {
    setSearcLatestProfitMax(event.target.value)
  }
  // バリデーションチェック処理
  useEffect(() => {
    setLatestProfitError("success")
    // 文字数チェック
    if (searcLatestProfitMin.length > 13) {
      setLatestProfitError("lengthError")
    }
    if (searcLatestProfitMax.length > 13) {
      setLatestProfitError("lengthError")
    }
    // 正規表現　正の整数、負の整数を許容
    if (!(searcLatestProfitMin.match(/^-?[0-9]*$/)) || searcLatestProfitMin === "-") {
      setLatestProfitError("NumError")
    }
    if (!(searcLatestProfitMax.match(/^-?[0-9]*$/)) || searcLatestProfitMax === "-") {
      setLatestProfitError("NumError")
    }
    // 検索範囲
    if (searcLatestProfitMin && searcLatestProfitMax) {
      if (Number(searcLatestProfitMin) > Number(searcLatestProfitMax)) {
        setLatestProfitError("rangeError")
      }
    }
  }, [searcLatestProfitMin, searcLatestProfitMax]);
  // ---------------------------------------------------------------
  // 入力、検索エラー処理一覧
  // ---------------------------------------------------------------
  function NoError() { return <></> }
  const notFoundError = "条件に一致する企業がありません"
  const rangeError = "範囲指定に誤りがあります"
  const numError = "半角数字で入力してください"
  const alphanumericError = "半角英数字で入力してください"
  const lengthError = "文字以内で入力してください"
  const notCsvData = "CSV出力するデータが検索されていません"
  const exceededError = "上限の10000件までをTSR企業コード順に表示しています"
  const searching = "検索中"
  const kanaError = "全角カタカナで入力してください"

  const NotFound = () => {
    // 検索件数0件
    if (notFound === "notFound") return <div className="text-rose-600 text-sm md:text-xl lg:text-base md:text-left text-center lg:text-center lg:ml-10">{notFoundError}</div>
    // 検索件数10000件超過エラー
    if (notFound === "exceeded") return <div className="text-rose-600 text-sm md:text-xl lg:text-base md:text-left text-center lg:text-center">{exceededError}</div>
    // 検索結果0件CSV出力エラー
    if (notFound === "notCsvData") return <div className="text-rose-600 text-sm md:text-xl lg:text-base md:text-left text-center lg:text-center lg:ml-10">{notCsvData}</div>
    // 検索中
    if (notFound === "searchingTime") return <div className="text-rose-600 text-sm md:text-xl lg:text-base md:text-left text-center lg:text-center lg:ml-36">{searching}</div>
    return (<div className="w-9/12 mb-3" />)
  }
  // TSRコード入力項目エラー
  const TsrCodeError = () => {
    if (tsrCodeError === "lengthError") return <div className="text-rose-600">9{lengthError}</div>
    if (tsrCodeError === "NumError") return <div className="text-rose-600">{alphanumericError}</div>
    return (NoError())
  }
  // 顧客コード入力項目エラー
  const ClientCodeError = () => {
    if (clientCodeError === "lengthError") return <div className="text-rose-600">14{lengthError}</div>
    return (NoError())
  }
  // 漢字商号入力項目エラー
  const BusinessNameError = () => {
    if (businessNameError === "lengthError") return <div className="text-rose-600">全角100{lengthError}</div>
    return (NoError())
  }
  // カナ商号入力項目エラー
  const BusinessNameKanaError = () => {
    if (businessNameKanaError === "lengthError") return <div className="text-rose-600">全角60{lengthError}</div>
    if (businessNameKanaError === "kanaError") return <div className="text-rose-600">{kanaError}</div>
    return (NoError())
  }
  // 代表者氏名入力項目エラー
  const RepresentativeNameError = () => {
    if (representativeNameError === "lengthError") return <div className="text-rose-600">146{lengthError}</div>
    return (NoError())
  }
  // 所在地入力項目エラー
  const AddressError = () => {
    if (addressError === "lengthError") return <div className="text-rose-600">全角100{lengthError}</div>
    return (NoError())
  }
  // 評点入力項目エラー
  const RatingError = () => {
    if (ratingError === "rangeError") return <div className="text-rose-600">{rangeError}</div>
    if (ratingError === "NumError") return <div className="text-rose-600">{numError}</div>
    if (ratingError === "lengthError") return <div className="text-rose-600">4{lengthError}</div>
    return (NoError())
  }
  // リスクスコア入力項目エラー
  const RiskScoreError = () => {
    if (riskScoreError === "rangeError") return <div className="text-rose-600">{rangeError}</div>
    if (riskScoreError === "NumError") return <div className="text-rose-600">{numError}</div>
    if (riskScoreError === "lengthError") return <div className="text-rose-600">3{lengthError}</div>
    return (NoError())
  }
  // 従業員入力項目エラー
  const EmployeeNumError = () => {
    if (employeeNumError === "rangeError") return <div className="text-rose-600">{rangeError}</div>
    if (employeeNumError === "NumError") return <div className="text-rose-600">{numError}</div>
    if (employeeNumError === "lengthError") return <div className="text-rose-600">8{lengthError}</div>
    return (NoError())
  }
  // 資本金入力項目エラー
  const CapitalError = () => {
    if (capitalError === "rangeError") return <div className="text-rose-600">{rangeError}</div>
    if (capitalError === "NumError") return <div className="text-rose-600">{numError}</div>
    if (capitalError === "lengthError") return <div className="text-rose-600">11{lengthError}</div>
    return (NoError())
  }
  // 最新売上高入力項目エラー
  const LatestSalesError = () => {
    if (latestSalesError === "rangeError") return <div className="text-rose-600">{rangeError}</div>
    if (latestSalesError === "NumError") return <div className="text-rose-600">{numError}</div>
    if (latestSalesError === "lengthError") return <div className="text-rose-600">13{lengthError}</div>
    return (NoError())
  }
  // 最新利益金入力項目エラー
  const LatestProfitError = () => {
    if (latestProfitError === "rangeError") return <div className="text-rose-600">{rangeError}</div>
    if (latestProfitError === "NumError") return <div className="text-rose-600">{numError}</div>
    if (latestProfitError === "lengthError") return <div className="text-rose-600">13{lengthError}</div>
    return (NoError())
  }

  // 検索ボタン押下
  const searchHandleClick = () => {

    let searchExecution: any[] = []

    // 検索条件入力内容がすべてsuccessだった場合検索をする。
    if (tsrCodeError === "success" &&
      clientCodeError === "success" &&
      businessNameError === "success" &&
      businessNameKanaError === "success" &&
      representativeNameError === "success" &&
      addressError === "success" &&
      ratingError === "success" &&
      riskScoreError === "success" &&
      employeeNumError === "success" &&
      capitalError === "success" &&
      latestSalesError === "success" &&
      latestProfitError === "success") {
      setQuery(searchExecution)
    }
  }

  // １万件検索処理
  useEffect(() => {

    if (viewCorporateList && isFirstRender.current) {
      // 検索結果の値が設定されていた場合、表示   
      setSearchTsrCode(viewCorporateList.searchTsrCode)
      setSearchClientCode(viewCorporateList.searchClientCode)
      setSearchBusinessName(viewCorporateList.searchBusinessName)
      setSearcBusinessNameKana(viewCorporateList.searcBusinessNameKana)
      setSearcRepresentativeName(viewCorporateList.searcRepresentativeName)
      setSearcAddress(viewCorporateList.searcAddress)
      setSearcRatingMin(viewCorporateList.searcRatingMin)
      setSearcRatingMax(viewCorporateList.searcRatingMax)
      setSearcRiskScoreMin(viewCorporateList.searcRiskScoreMin)
      setSearcRiskScoreMax(viewCorporateList.searcRiskScoreMax)
      setSearcEmployeeNumMin(viewCorporateList.searcEmployeeNumMin)
      setSearcEmployeeNumMax(viewCorporateList.searcEmployeeNumMax)
      setSearcCapitalMin(viewCorporateList.searcCapitalMin)
      setSearcCapitalMax(viewCorporateList.searcCapitalMax)
      setSearcLatestSalesMin(viewCorporateList.searcLatestSalesMin)
      setSearcLatestSalesMax(viewCorporateList.searcLatestSalesMax)
      setSearcLatestProfitMin(viewCorporateList.searcLatestProfitMin)
      setSearcLatestProfitMax(viewCorporateList.searcLatestProfitMax)
      setDisplayRatingMin(viewCorporateList.displayRatingMin)
      setDisplayRatingMax(viewCorporateList.displayRatingMax)
      setDisplayRiskScoreMin(viewCorporateList.displayRiskScoreMin)
      setDisplayRiskScoreMax(viewCorporateList.displayRiskScoreMax)
      if (viewCorporateList.ratinCheckBox) { RatinCheckBoxhandle() }
      if (viewCorporateList.riskScoreCheckBox) { riskScoreCheckBoxhandle() }
      setItems(viewCorporateList.items)
      setItems(viewCorporateList.items)
      setPage(viewCorporateList.page)
      setSort(viewCorporateList.sort)
      setCorporateInfo(viewCorporateList.corporateInfo)
      isFirstRender.current = false;
    } else {

      // queryの作成
      let query: any[] = []

      // ダッシュボードからの遷移処理、評点に検索条件がなく、ratingRangeに値があった場合
      if (searcRatingMin === undefined && searcRatingMax === undefined && location.state?.ratingRange) {
        isFirstRender.current = false;
        if (location.state?.ratingRange.min === -1) {
          RatinCheckBoxhandle()
          setSearcRatingMin(null)
          setSearcRatingMax(null)
          const ratingMaxSearch = 9999
          query.push({ not: { HYTN: { between: [0, ratingMaxSearch] } } },)
        } else {
          setSearcRatingMin(location.state?.ratingRange.min)
          setSearcRatingMax(location.state?.ratingRange.max)
          setDisplayRatingMin(location.state?.ratingRange.min)
          setDisplayRatingMax(location.state?.ratingRange.max)
          query.push({ HYTN: { between: [location.state?.ratingRange.min, location.state?.ratingRange.max] } },)
        }
      }
      // ダッシュボードからの遷移処理、リスクスコアに検索条件がなく、riskRangeに値があった場合
      if (searcRiskScoreMin === undefined && searcRiskScoreMax === undefined && location.state?.riskRange) {
        if (location.state?.riskRange.min === -1) {
          riskScoreCheckBoxhandle()
          setSearcRiskScoreMin(null)
          setSearcRiskScoreMax(null)
          const riskScoreMaxSearch = 999
          query.push({ not: { RSK_SCR: { between: [0, riskScoreMaxSearch] } } },)
        } else {
          setSearcRiskScoreMin(location.state?.riskRange.min)
          setSearcRiskScoreMax(location.state?.riskRange.max)
          setDisplayRiskScoreMin(location.state?.riskRange.min)
          setDisplayRiskScoreMax(location.state?.riskRange.max)
          query.push({ RSK_SCR: { between: [location.state?.riskRange.min, location.state?.riskRange.max] } },)
        }
      }

      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      }

      // 検索処理 実行
      setSort({ 'item': '', 'order': -1 })
      setPage(1)
      setNotFound("searchingTime")
      setButtonDisabled(true);

      // 顧客コード
      if (searchClientCode) {
        query.push({ KKCD: { contains: searchClientCode } })
      };
      // 漢字商号
      if (searchBusinessName) {
        query.push({ RK_SZKN: { contains: searchBusinessName } },)
      };
      // カナ商号
      if (searcBusinessNameKana) {
        query.push({ SG_INKT: { contains: searcBusinessNameKana } },)
      };
      // 代表者氏名
      if (searcRepresentativeName) {
        query.push({ DHS_SIMIKN: { contains: searcRepresentativeName } },)
      };
      // 所在地
      if (searcAddress) {
        query.push({ JST_ADKN: { contains: searcAddress } },)
      };
      // 評点
      if (!ratinCheckBox) {
        const ratingMaxSearch = 9999
        // minのみの場合
        if (searcRatingMin && !searcRatingMax) {
          query.push({ HYTN: { between: [searcRatingMin, ratingMaxSearch] } },)
        }
        // maxのみの場合
        if (searcRatingMax && !searcRatingMin) {
          query.push({ HYTN: { le: searcRatingMax } },)
        }
        // 両値があった場合
        if (searcRatingMin && searcRatingMax) {
          query.push({ HYTN: { between: [searcRatingMin, searcRatingMax] } },)
        }
      } else {
        const ratingMaxSearch = 9999
        query.push({ not: { HYTN: { between: [0, ratingMaxSearch] } } },)
      }

      // リスクスコア
      if (!riskScoreCheckBox) {
        const riskScoreMaxSearch = 999
        // minのみの場合
        if (searcRiskScoreMin && !searcRiskScoreMax) {
          query.push({ RSK_SCR: { between: [searcRiskScoreMin, riskScoreMaxSearch] } },)
        }
        // maxのみの場合
        if (searcRiskScoreMax && !searcRiskScoreMin) {
          query.push({ RSK_SCR: { le: searcRiskScoreMax } },)
        }
        // 両値があった場合
        if (searcRiskScoreMin && searcRiskScoreMax) {
          query.push({ RSK_SCR: { between: [searcRiskScoreMin, searcRiskScoreMax] } },)
        }
      } else {
        const riskScoreMaxSearch = 999
        query.push({ not: { RSK_SCR: { between: [0, riskScoreMaxSearch] } } },)
      }

      // 従業員数
      // minのみの場合
      if (searcEmployeeNumMin && !searcEmployeeNumMax) {
        const jgyoinsMaxSearch = 99999999
        query.push({ JGYINS: { between: [searcEmployeeNumMin, jgyoinsMaxSearch] } },)
      }
      // maxのみの場合
      if (searcEmployeeNumMax && !searcEmployeeNumMin) {
        query.push({ JGYINS: { le: searcEmployeeNumMax } },)
      }
      // 両値があった場合
      if (searcEmployeeNumMin && searcEmployeeNumMax) {
        query.push({ JGYINS: { between: [searcEmployeeNumMin, searcEmployeeNumMax] } },)
      }

      // 資本金
      // minのみの場合
      if (searcCapitalMin && !searcCapitalMax) {
        const shnknMaxSearch = 99999999999
        query.push({ SHNKN: { between: [searcCapitalMin, shnknMaxSearch] } },)
      }
      // maxのみの場合
      if (searcCapitalMax && !searcCapitalMin) {
        const shnknMinusSearch = -99999999999
        query.push({ SHNKN: { between: [shnknMinusSearch, searcCapitalMax] } },)
      }
      // 両値があった場合
      if (searcCapitalMin && searcCapitalMax) {
        query.push({ SHNKN: { between: [searcCapitalMin, searcCapitalMax] } },)
      }

      // 最新売上高
      // minのみの場合
      if (searcLatestSalesMin && !searcLatestSalesMax) {
        const uragMaxSearch = 9999999999999
        query.push({ SNTI_GYSI_URAG_1: { between: [searcLatestSalesMin, uragMaxSearch] } },)
      }
      // maxのみの場合
      if (searcLatestSalesMax && !searcLatestSalesMin) {
        const uragMinusSearch = -9999999999999
        query.push({ SNTI_GYSI_URAG_1: { between: [uragMinusSearch, searcLatestSalesMax] } },)
      }
      // 両値があった場合
      if (searcLatestSalesMin && searcLatestSalesMax) {
        query.push({ SNTI_GYSI_URAG_1: { between: [searcLatestSalesMin, searcLatestSalesMax] } },)
      }

      // 最新利益金
      // minのみの場合
      if (searcLatestProfitMin && !searcLatestProfitMax) {
        const rekMaxSearch = 9999999999999
        query.push({ SNTI_GYSI_REK_1: { between: [searcLatestProfitMin, rekMaxSearch] } },)
      }
      // maxのみの場合
      if (searcLatestProfitMax && !searcLatestProfitMin) {
        const rekMinusSearch = -9999999999999
        query.push({ SNTI_GYSI_REK_1: { between: [rekMinusSearch, searcLatestProfitMax] } },)
      }
      // 両値があった場合
      if (searcLatestProfitMin && searcLatestProfitMax) {
        query.push({ SNTI_GYSI_REK_1: { between: [searcLatestProfitMin, searcLatestProfitMax] } },)
      }

      // 企業情報取得
      const getCorporatesData = async () => {
        // セッションタイムアウトエラーメッセージを非表示
        setHidden('hidden');
        let response;
        let nextToken: string | null = null;
        const resData: CorporateInfo[] = [];
        const resAllData: CorporateInfo[] = [];
        try {
          do {
            if (uploadId) {
              // 全件検索の場合
              if (!searchTsrCode && query.length === 0) {
                response = await API.graphql(graphqlOperation(_query,
                  {
                    uploadId: uploadId,
                    limit: 10000000,
                    nextToken: nextToken
                  }
                ));
                // 企業コード検索以外の場合
              } else if (!searchTsrCode && query.length != 0) {
                response = await API.graphql(graphqlOperation(_query,
                  {
                    uploadId: uploadId,
                    filter: {
                      and: query
                    },
                    limit: 10000000,
                    nextToken: nextToken
                  }
                ));
                // 企業コード検索のみの場合
              } else if (searchTsrCode && query.length === 0) {
                response = await API.graphql(graphqlOperation(_query,
                  {
                    uploadId: uploadId,
                    KCD: { beginsWith: searchTsrCode },
                    limit: 10000000,
                    nextToken: nextToken
                  }
                ));
                // 他条件＋企業コード検索の場合
              } else {
                response = await API.graphql(graphqlOperation(_query,
                  {
                    uploadId: uploadId,
                    KCD: { beginsWith: searchTsrCode },
                    filter: {
                      and: query
                    },
                    limit: 10000000,
                    nextToken: nextToken
                  }
                ));
              }
            }
            if (resData.length >= 10001) {
              // @ts-ignore
              nextToken = null;
            } else {
              // @ts-ignore
              nextToken = response.data.listCorporatesByUploadId.nextToken;
            }
            Array.prototype.push.apply(resAllData,
              // @ts-ignore
              response.data.listCorporatesByUploadId.items.map(CorporateInfo => {
                return {
                  id: CorporateInfo.id, // 企業ID
                  uploadId: CorporateInfo.uploadId, // アップロードID
                  KCD: CorporateInfo.KCD // TSR企業コード
                };
              })
            );
            Array.prototype.push.apply(resData,
              // @ts-ignore
              response.data.listCorporatesByUploadId.items.map(CompanyDataList => {
                return {
                  KCD: CompanyDataList.KCD === null ? "" : CompanyDataList.KCD === "" ? "" : CompanyDataList.KCD, // TSR企業コード
                  RK_SZKN: CompanyDataList.RK_SZKN === null ? "" : CompanyDataList.RK_SZKN === "" ? "" : CompanyDataList.RK_SZKN, // 商号
                  JST_ADKN: CompanyDataList.JST_ADKN === null ? "" : CompanyDataList.JST_ADKN === "" ? "" : CompanyDataList.JST_ADKN, // 所在地
                  JST_SZIC_ZNTI_TEL: CompanyDataList.JST_SZIC_ZNTI_TEL === null ? "" : CompanyDataList.JST_SZIC_ZNTI_TEL === "" ? "" : formatPhoneNumber(CompanyDataList.JST_SZIC_ZNTI_TEL), // 電話番号
                  DHS_SIMIKN: CompanyDataList.DHS_SIMIKN === null ? "" : CompanyDataList.DHS_SIMIKN === "" ? "" : CompanyDataList.DHS_SIMIKN, // 代表者氏名
                  JGYINS: CompanyDataList.JGYINS === null ? "" : CompanyDataList.JGYINS === NOT_PURCHASED ? "" : CompanyDataList.JGYINS, // 従業員数
                  SHNKN: CompanyDataList.SHNKN === null ? "" : CompanyDataList.SHNKN === NOT_PURCHASED ? "" : CompanyDataList.SHNKN, // 資本金（千円）
                  SNTI_GYSI_URAG_1: CompanyDataList.SNTI_GYSI_URAG_1 === null ? "" : CompanyDataList.SNTI_GYSI_URAG_1 === NOT_PURCHASED ? "" : CompanyDataList.SNTI_GYSI_URAG_1, // 売上高（千円）
                  SNTI_GYSI_REK_1: CompanyDataList.SNTI_GYSI_REK_1 === null ? "" : CompanyDataList.SNTI_GYSI_REK_1 === NOT_PURCHASED ? "" : CompanyDataList.SNTI_GYSI_REK_1, // 利益金（千円）
                  JJ_KBN: CompanyDataList.JJ_KBN === null ? "" : CompanyDataList.JJ_KBN === "" ? "" : CompanyDataList.JJ_KBN, // 上場区分
                  HYTN: CompanyDataList.HYTN === null ? "" : CompanyDataList.HYTN === NOT_PURCHASED ? "" : CompanyDataList.HYTN, // 評点
                  RSK_SCR: CompanyDataList.RSK_SCR === null ? "" : CompanyDataList.RSK_SCR === NOT_PURCHASED ? "" : CompanyDataList.RSK_SCR, // リスクスコア
                  KKCD: CompanyDataList.KKCD === null ? "" : CompanyDataList.KKCD === "" ? "" : CompanyDataList.KKCD, // 顧客コード
                };
              })
            );
            // 初回の処理で評点レンジとリスクレンジがある場合
            if (!ratingRange && !riskRange && location.state?.ratingRange && location.state?.riskRange) {
              setRatingRange(location.state.ratingRange)
              setRiskRange(location.state?.riskRange)
            }

            setCorporateInfo(resAllData)
            setItems(resData.slice(0, 10000))
            if (!nextToken) {
              resData.length ? resData.length < 10001 ? setNotFound("success") : setNotFound("exceeded") : setNotFound("notFound")
            }
          } while (nextToken);
        } catch (e) {
          navigate('/error');
          return;
        }
        setButtonDisabled(false);
      }
      getCorporatesData();
    }
  }, [query]);

  /** Object の配列を受け取り CSV形式の文字列に変換する Func */
  const convertToCSV = (objArray: any) => {
    const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    /** 1. Objectの Key を headerとして取り出す */
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";
    // 2. 各オブジェクトの値をCSVの行として追加する
    return array.reduce((str: any, next: any) => {
      str +=
        `${Object.values(next)
          .map((value) => `"${value}"`)
          .join(",")}` + "\r\n";
      return str;
    }, str);
  };

  /** Download・処理 */
  const downloadCSV = (data: any, name: any) => {
    /** Blob Object を作成する Type. CSV */
    const blob = new Blob([data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", `${name}.csv`);
    a.click();
    a.remove();
  };

  const CsvDownload = () => {

    // 連打防止のため次のダウンロードまで1秒間隔をあける
    if (downloadDelay) {
      setdownloadDelay(false)
      setTimeout(() => {

        // csvのダウンロードする項目がない場合ここで判定しエラー文言を付けて返す
        if (items.length === 0) {
          setNotFound("notCsvData")
          NotFound()
          setdownloadDelay(true)
          return
        }

        const csv: CorporateInfo[] = [];

        Array.prototype.push.apply(csv,
          // @ts-ignore
          items.map(CompanyDataList => {
            return {
              "TSR企業コード": CompanyDataList.KCD,
              "漢字商号": CompanyDataList.RK_SZKN,
              "所在地": CompanyDataList.JST_ADKN,
              "電話番号": CompanyDataList.JST_SZIC_ZNTI_TEL,
              "代表者氏名": CompanyDataList.DHS_SIMIKN,
              "従業員数": CompanyDataList.JGYINS,
              "資本金（千円）": CompanyDataList.SHNKN,
              "最新売上高（千円）": CompanyDataList.SNTI_GYSI_URAG_1,
              "最新利益金（千円）": CompanyDataList.SNTI_GYSI_REK_1,
              "評点": CompanyDataList.HYTN,
              "リスクスコア": CompanyDataList.RSK_SCR,
              "顧客コード": CompanyDataList.KKCD,
            };
          })
        );

        /** CSVデータを作成 */
        const csvData = convertToCSV(csv);

        // CSV・Download
        downloadCSV(csvData, "企業データ一覧_" + getCurrentDateTime());
        setdownloadDelay(true)
      }, 1000);
    }
  }

  /** 企業詳細画面 */
  const CorporeteClickDetails = (index: number) => {

    if (!buttonDisabled) {
      // 企業データ一覧画面、検索結果の表示を固定
      const list = {
        searchTsrCode, searchClientCode, searchBusinessName, searcRepresentativeName, searcAddress, searcRatingMin, searcRatingMax, searcBusinessNameKana,
        searcRiskScoreMin, searcRiskScoreMax, searcEmployeeNumMin, searcEmployeeNumMax, searcCapitalMin, searcCapitalMax, searcLatestSalesMin, searcLatestSalesMax,
        searcLatestProfitMin, searcLatestProfitMax, displayRatingMin, displayRatingMax, displayRiskScoreMin, displayRiskScoreMax, ratinCheckBox, riskScoreCheckBox, items, page, sort, corporateInfo
      }
      setViewCorporateList(list)
      const result = corporateInfo.filter(value => {
        return value.KCD === data.currentData()[index].KCD
      })
      navigate(`/corporate/${result[0].id}/?id=${id}&uploadId=${uploadId}&productName=${productName}&deliveredDate=${deliveredDate}&expireDate=${expireDate}`);
    }
  };

  /** ソート */
  const handleClickSort = (event: any, selectedItem: string) => {
    // 選択中の項目であればソート順をトグル、そうでなければ昇順
    let order = 1;
    if (selectedItem === sort.item) {
      order = sort.order * -1
    }
    sortItem(items, selectedItem, order);
    setItems(items);
    setSort({ 'item': selectedItem, 'order': order });
  };

  /** ソート実行 */
  const sortItem = (target: any[], selectedItem: string, order: number) => {
    // @ts-ignore 動的なブラケット記法を許容
    const key: keyof CorporateInfo = selectedItem;
    target.sort((first, second) => {
      // 空の場合は他のものよりも後にソート
      if (first[key] === "") {
        return 1;
      }
      // 空の場合は他のものよりも後にソート
      else if (second[key] === "") {
        return -1;
      }
      // 昇順の場合は、最も低いものから順にソート
      else if (first[key]! > second[key]!) {
        return order;
      }
      // 降順の場合は、最も高いものから順にソート
      else if (first[key]! < second[key]!) {
        return order * -1;
      }
      else {
        // 第2ソートはTSRコード
        if (first['KCD'] < second['KCD']) { return order; }
        else if (first['KCD'] > second['KCD']) { return order * -1; }
        else { return 0; }
      }
    });
  }

  // 検索条件アコーディオンの開閉処理
  const Accordion = () => {
    accordion ? setAccordion(false) : setAccordion(true)
  }

  // 検索結果　表示文字数調整
  const TruncatableText: FC<TruncatableTextProps> = ({ text }) => {
    if (text === null) {
      text = "";
    }
    const [ref, truncated] = useTruncated(text);
    let truncateStyle = "";
    let dataTip = "";
    if (truncated) {
      truncateStyle = "truncate";
      dataTip = text.toString();
    }
    return (
      <>
        <div data-tip={dataTip} ref={ref}>
          <p className={`${truncateStyle}`}>{text}</p>
        </div>
      </>
    )
  }
  // 検索結果テーブルの項目内容
  const TableBodyMolecules = ({ value }: { value: any }) => {
    return (
      <tbody>
        {value.map((data: any, i: any) => {
          return (
            <tr key={i} className={buttonDisabled ? "lg:text-base md:text-lg border-b-2 indent-1.5 font-normal cursor-pointer text-inapplicable" : "lg:text-base md:text-lg border-b-2 indent-1.5 font-normal cursor-pointer"} onClick={() => CorporeteClickDetails(i)}>
              <TableBodyAtoms data={data.KCD} classNameCss={"text-center"} />
              <TableBodyAtoms data={data.RK_SZKN} classNameCss={"text-left"} />
              <TableBodyAtoms data={data.JST_ADKN} classNameCss={"text-left"} />
              <TableBodyAtoms data={data.JST_SZIC_ZNTI_TEL} classNameCss={""} />
              <TableBodyAtoms data={data.DHS_SIMIKN} classNameCss={""} />
              <TableBodyAtoms data={data.JGYINS === "" ? "" : data.JGYINS.toLocaleString()} classNameCss={"text-right pr-8"} />
              <TableBodyAtoms data={data.SHNKN === "" ? "" : data.SHNKN.toLocaleString()} classNameCss={"text-right pr-8"} />
              <TableBodyAtoms data={data.SNTI_GYSI_URAG_1 === "" ? "" : data.SNTI_GYSI_URAG_1.toLocaleString()} classNameCss={"text-right pr-8"} />
              <TableBodyAtoms data={data.SNTI_GYSI_REK_1 === "" ? "" : data.SNTI_GYSI_REK_1.toLocaleString()} classNameCss={"text-right pr-8"} />
              <TableBodyAtoms data={data.HYTN} classNameCss={"text-center"} />
              <TableBodyAtoms data={data.RSK_SCR} classNameCss={"text-center"} />
              <TableBodyAtoms data={data.KKCD} classNameCss={"text-center"} />
            </tr>
          );
        })}
      </tbody>
    )
  }
  const TableBodyAtoms = ({ data, classNameCss }: { data: string, classNameCss: string }) => {
    return (
      <td className={classNameCss} title={data}>
        <TruncatableText text={data} />
      </td>
    )
  }

  return (
    <>
      <div className='w-full'>
        <div className='flex w-11/12 flex-col md:flex-row md:justify-between mx-auto text-center'>
          <div className='flex flex-col lg:flex-row w-full'>
            <div className='mt-auto md:ml-0 w-full md:text-left'>{`${productName}（${deliveredDate}）> 企業データ一覧`}</div>
            <div className='w-7/12 frex md:text-left lg:text-right ml-8 md:ml-0 lg:mb-0 pt-2'>
              {/* 商品ダウンロードボタン */}
              <button
                tabIndex={-1}
                className="btn btn-sm w-[17rem] text-white flex-0 rounded-full border-none bg-gradient-to-b from-main to-main-to"
                onClick={() => navigate(`/index/?id=${id}&uploadId=${uploadId}&productName=${productName}&deliveredDate=${deliveredDate}&expireDate=${expireDate}`)}>
                <span className="material-symbols-outlined pr-1 md:pr-3 ">expand_circle_right</span>
                <span>商品ダウンロード画面に戻る</span>
              </button>
            </div>
          </div>
        </div>
        <div className='w-11/12 pt-2 mx-auto text-sm text-center md:text-left'>※表示したい企業を検索してください</div>
        <div className='w-11/12 mx-auto rounded-lg'>
          <details open={true}>
            <summary onClick={Accordion} className='relative btn bg-transparent hover:bg-transparent outline-white hover:outline-white border-0 text-lg flex items-left pt-2 mb-4 w-3/5 md:w-3/12 lg:w-2/12'>
              <div className=' material-symbols-outlined btn btn-outline rounded-full border-none bg-gradient-to-b text-white from-main to-main-to text-2xl p-0 w-12'>{accordion ? "Close" : "Add"}</div>
              <span className=' text-main ml-2'>検索条件</span>
            </summary>
            <div className='w-full lg:mx-auto bg-teal-100 pt-2 pb-4 mb-4 mt-2 lg:mt-6 pl-6'>
              <div className='triangle lg:flex hidden' />
              {/* 1段目検索 */}
              <div className="text-base mt-6 md:mx-6">
                {/* 1段目検索項目名 */}
                <div className='mx-auto w-full hidden md:flex'>
                  <div className="lg:w-3/12 md:w-5/12">TSR企業コード（前方一致）</div>
                  <div className="lg:w-3/12 ml-10 md:w-5/12">顧客コード</div>
                </div>
                {/* 1段目検索入力欄 */}
                <div className='mx-auto w-full hidden md:flex'>
                  <div className='flex flex-col lg:w-3/12 md:w-5/12'>
                    <input onChange={SearchTsrCode} maxLength={9} value={searchTsrCode} type="tel" className="no-spin appearance input input-bordered rounded-full input-xs" />
                    <TsrCodeError />
                  </div>
                  <div className='flex flex-col ml-10 lg:w-3/12 md:w-5/12'>
                    <input onChange={ClientCode} maxLength={14} value={searchClientCode} className="no-spin input input-bordered rounded-full input-xs " />
                    <ClientCodeError />
                  </div>
                </div>
                {/* スマホ */}
                <div className='md:hidden mr-4'>
                  <div>TSR企業コード（前方一致）</div>
                  <div className='flex flex-col '>
                    <input onChange={SearchTsrCode} maxLength={9} type="tel" value={searchTsrCode} className="no-spin appearance input input-bordered rounded-full input-xs" />
                    <TsrCodeError />
                  </div>
                  <div className="mt-2">顧客コード</div>
                  <div className='flex flex-col '>
                    <input onChange={ClientCode} maxLength={14} value={searchClientCode} className="no-spin input input-bordered rounded-full input-xs " />
                    <ClientCodeError />
                  </div>
                </div>
              </div>
              {/* 2段目検索 */}
              <div className="text-base mt-2 md:mx-6">
                {/* 2段目項目名 */}
                <div className='mx-auto w-full hidden md:flex'>
                  <div className="lg:w-3/12 md:w-5/12">漢字商号</div>
                  <div className="lg:w-3/12 ml-10 md:w-5/12">カナ商号</div>
                  <div className="lg:w-2/12 ml-10 md:hidden lg:flex">代表者氏名</div>
                </div>
                {/* 2段目検索入力欄 */}
                <div className='mx-auto w-full hidden md:flex'>
                  <div className='flex flex-col lg:w-3/12 md:w-5/12'>
                    <input onChange={BusinessName} maxLength={100} value={searchBusinessName} className="input input-bordered rounded-full input-xs" />
                    <BusinessNameError />
                  </div>
                  <div className='flex flex-col lg:w-3/12 md:w-5/12 ml-10'>
                    <input onChange={BusinessNameKana} maxLength={60} value={searcBusinessNameKana} className="input input-bordered rounded-full input-xs" />
                    <BusinessNameKanaError />
                  </div>
                  <div className='flex flex-col lg:w-3/12 ml-10 md:hidden lg:flex'>
                    <input onChange={RepresentativeName} maxLength={146} value={searcRepresentativeName} className="input input-bordered rounded-full input-xs" />
                    <RepresentativeNameError />
                  </div>
                </div>
                <div className='hidden md:flex flex-col lg:hidden'>
                  <div className="w-5/12 mt-2">代表者氏名</div>
                  <div className='flex flex-col w-5/12'>
                    <input onChange={RepresentativeName} maxLength={146} value={searcRepresentativeName} className="input input-bordered rounded-full input-xs" />
                    <RepresentativeNameError />
                  </div>
                </div>
                {/* スマホ */}
                <div className='md:hidden mr-4'>
                  <div className="mt-2">漢字商号</div>
                  <div className='flex flex-col'>
                    <input onChange={BusinessName} maxLength={100} value={searchBusinessName} className="input input-bordered rounded-full input-xs" />
                    <BusinessNameError />
                  </div>
                  <div className="mt-2">カナ商号</div>
                  <div className='flex flex-col'>
                    <input onChange={BusinessNameKana} maxLength={60} value={searcBusinessNameKana} className="input input-bordered rounded-full input-xs" />
                    <BusinessNameKanaError />
                  </div>
                  <div className="mt-2">代表者氏名</div>
                  <div className='flex flex-col'>
                    <input onChange={RepresentativeName} maxLength={146} value={searcRepresentativeName} className="input input-bordered rounded-full input-xs" />
                    <RepresentativeNameError />
                  </div>
                </div>
              </div>
              {/* 3段目検索 */}
              <div className="md:text-base mt-2 mr-4 md:mx-6">
                {/* 3段目項目名 */}
                <div className='mx-auto flex w-full text-base'>
                  <div className="lg:w-3/12">所在地</div>
                </div>
                {/* 3段目検索入力欄 */}
                <div className='mx-auto flex w-full '>
                  <input onChange={Address} maxLength={100} value={searcAddress} className="input input-bordered rounded-full text-sm input-xs lg:w-6/12 md:w-5/12 w-full" />
                </div>
                <AddressError />
              </div>
              {/* 4段目検索 */}
              <div className="text-base mt-2 md:mx-6 mr-4">
                {/* 4段目検索入力欄 */}
                <div className='mx-auto md:flex w-full  flex-col lg:flex-row'>
                  <div className='flex flex-col lg:w-3/12 md:w-full'>
                    <div className="lg:w-3/12 md:w-5/12 ">評点</div>
                    <div className='flex justify-between w-full'>
                      <input onChange={RatingMin} disabled={ratinInputBox} value={displayRatingMin} maxLength={4} type="tel" className="no-spin input input-bordered rounded-full text-sm input-xs w-5/12 " />
                      <span>~</span>
                      <input onChange={RatingMax} disabled={ratinInputBox} value={displayRatingMax} maxLength={4} type="tel" className="no-spin input input-bordered rounded-full text-sm input-xs w-5/12 " />
                    </div>
                    <label>
                      <input type="checkBox" onChange={RatinCheckBoxhandle} checked={ratinCheckBox} /><span className='ml-2'>評点無し</span>
                    </label>
                    <RatingError />
                  </div>
                  <div className='flex flex-col lg:w-3/12 md:w-full lg:ml-10  mt-2 lg:mt-0'>
                    <div className="w-full lg:flex">リスクスコア</div>
                    <div className='flex justify-between w-full'>
                      <input onChange={RiskScoreMin} disabled={riskScoreInputBox} value={displayRiskScoreMin} maxLength={3} type="tel" className="no-spin input input-bordered rounded-full text-sm input-xs w-5/12 " />
                      <span>~</span>
                      <input onChange={RiskScoreMax} disabled={riskScoreInputBox} value={displayRiskScoreMax} maxLength={3} type="tel" className="no-spin input input-bordered rounded-full text-sm input-xs w-5/12 " />
                    </div>
                    <label>
                      <input type="checkBox" onChange={riskScoreCheckBoxhandle} checked={riskScoreCheckBox} /><span className='ml-2'>リスクスコア無し</span>
                    </label>
                    <div>
                    </div>
                    <RiskScoreError />
                  </div>
                  <div className='flex flex-col lg:w-3/12 md:w-full lg:ml-10 mt-2 lg:mt-0'>
                    <div className="w-3/12 lg:flex">従業員数</div>
                    <div className='flex justify-between w-full'>
                      <input onChange={EmployeeNumMin} maxLength={8} value={searcEmployeeNumMin} type="tel" className="no-spin input input-bordered rounded-full text-sm input-xs w-5/12 " />
                      <span>~</span>
                      <input onChange={EmployeeNumMax} maxLength={8} value={searcEmployeeNumMax} type="tel" className="no-spin input input-bordered rounded-full text-sm input-xs w-5/12 " />
                    </div>
                    <EmployeeNumError />
                  </div>
                </div>
              </div>
              {/* 5段目検索 */}
              <div className="text-base mt-2 md:mx-6">
                {/* 5段目項目名 */}
                <div className='mx-auto hidden md:flex w-full'>
                  <div className="lg:w-3/12 sm:w-5/12">資本金（千円）</div>
                  <div className="w-3/12 ml-10 sm:hidden lg:flex">最新売上高（千円）</div>
                  <div className="w-3/12 ml-10 sm:hidden lg:flex">最新利益金（千円）</div>
                </div>
                {/* 5段目検索入力欄 */}
                <div className='mx-auto hidden md:flex w-full '>
                  <div className='flex flex-col w-3/12 lg:w-3/12 sm:w-full'>
                    <div className='flex justify-between'>
                      <input onChange={CapitalMin} maxLength={11} value={searcCapitalMin} type="tel" className="no-spin input input-bordered rounded-full text-sm input-xs w-5/12 " />
                      <span>~</span>
                      <input onChange={CapitalMax} maxLength={11} value={searcCapitalMax} type="tel" className="no-spin input input-bordered rounded-full text-sm input-xs w-5/12 " />
                    </div>
                    <CapitalError />
                  </div>
                  <div className='flex flex-col w-3/12 ml-10 md:hidden lg:flex'>
                    <div className='flex justify-between'>
                      <input onChange={LatestSalesMin} maxLength={13} value={searcLatestSalesMin} type="tel" className="no-spin input input-bordered rounded-full text-sm input-xs w-5/12 " />
                      <span>~</span>
                      <input onChange={LatestSalesMax} maxLength={13} value={searcLatestSalesMax} type="tel" className="no-spin input input-bordered rounded-full text-sm input-xs w-5/12 " />
                    </div>
                    <LatestSalesError />
                  </div>
                  <div className='flex flex-col w-3/12 ml-10 md:hidden lg:flex'>
                    <div className='flex justify-between'>
                      <input onChange={LatestProfitMin} maxLength={13} value={searcLatestProfitMin} type="tel" className="no-spin input input-bordered rounded-full text-sm input-xs w-5/12 " />
                      <span>~</span>
                      <input onChange={LatestProfitMax} maxLength={13} value={searcLatestProfitMax} type="tel" className="no-spin input input-bordered rounded-full text-sm input-xs w-5/12 " />
                    </div>
                    <LatestProfitError />
                  </div>
                </div>
                {/* タブレット */}
                <div className='hidden md:flex lg:hidden mt-2 flex-col'>
                  <div className="w-3/12 lg:hidden">最新売上高（千円）</div>
                  <div className='flex flex-col w-full sm:flex lg:hidden'>
                    <div className='flex justify-between'>
                      <input onChange={LatestSalesMin} maxLength={13} value={searcLatestSalesMin} type="tel" className="no-spin input input-bordered rounded-full text-sm input-xs w-5/12 " />
                      <span>~</span>
                      <input onChange={LatestSalesMax} maxLength={13} value={searcLatestSalesMax} type="tel" className="no-spin input input-bordered rounded-full text-sm input-xs w-5/12 " />
                    </div>
                    <LatestSalesError />
                  </div>
                </div>
                <div className='hidden md:flex mt-2 flex-col'>
                  <div className="w-3/12 lg:hidden ">最新利益金（千円）</div>
                  <div className='flex flex-col w-full sm:flex lg:hidden'>
                    <div className='flex justify-between'>
                      <input onChange={LatestProfitMin} maxLength={13} value={searcLatestProfitMin} type="tel" className="no-spin input input-bordered rounded-full text-sm input-xs w-5/12 " />
                      <span>~</span>
                      <input onChange={LatestProfitMax} maxLength={13} value={searcLatestProfitMax} type="tel" className="no-spin input input-bordered rounded-full text-sm input-xs w-5/12 " />
                    </div>
                    <LatestProfitError />
                  </div>
                </div>
                {/* スマホ */}
                <div className="mr-4 md:hidden">
                  <div className="mt-2">資本金（千円）</div>
                  <div className='flex flex-col'>
                    <div className='flex justify-between'>
                      <input onChange={CapitalMin} maxLength={11} value={searcCapitalMin} type="tel" className="no-spin input input-bordered rounded-full text-sm input-xs w-5/12 " />
                      <span>~</span>
                      <input onChange={CapitalMax} maxLength={11} value={searcCapitalMax} type="tel" className="no-spin input input-bordered rounded-full text-sm input-xs w-5/12 " />
                    </div>
                    <CapitalError />
                  </div>
                  <div className="mt-2">最新売上高（千円）</div>
                  <div className='flex flex-col'>
                    <div className='flex justify-between'>
                      <input onChange={LatestSalesMin} maxLength={13} value={searcLatestSalesMin} type="tel" className="no-spin input input-bordered rounded-full text-sm input-xs w-5/12 " />
                      <span>~</span>
                      <input onChange={LatestSalesMax} maxLength={13} value={searcLatestSalesMax} type="tel" className="no-spin input input-bordered rounded-full text-sm input-xs w-5/12 " />
                    </div>
                    <LatestSalesError />
                  </div>
                  <div className="mt-2">最新利益金（千円）</div>
                  <div className='flex flex-col'>
                    <div className='flex justify-between'>
                      <input onChange={LatestProfitMin} maxLength={13} value={searcLatestProfitMin} type="tel" className="no-spin input input-bordered rounded-full text-sm input-xs w-5/12 " />
                      <span>~</span>
                      <input onChange={LatestProfitMax} maxLength={13} value={searcLatestProfitMax} type="tel" className="no-spin input input-bordered rounded-full text-sm input-xs w-5/12 " />
                    </div>
                    <LatestProfitError />
                  </div>
                </div>
              </div>
              <div className="text-base md:mx-6 mr-4 mt-2 lg:mt-0">
                <div className="w-full text-center md:text-right mt-4 lg:mt-2">
                  <button onClick={searchHandleClick} disabled={buttonDisabled} className={buttonDisabled ? "btn btn-outline rounded-full btn-sm w-6/12 md:w-3/12 lg:w-1/12 border-none bg-inapplicable" : "btn btn-outline rounded-full btn-sm border-none bg-gradient-to-b from-main to-main-to w-6/12 md:w-3/12 lg:w-1/12"}>
                    <div className='text-white'>検 索</div>
                  </button>
                </div>
              </div>
            </div>
          </details>
        </div>
        {/* 検索エラー */}
        <div className='w-full lg:hidden text-center md:ml-10'>
          <NotFound />
        </div>
        <div className='w-11/12 mx-auto my-auto'>
          <div className='w-full flex flex-col md:flex-row text-center items-end pb-2'>
            {/** ページャー */}
            <div className='flex w-full'>
              <button tabIndex={-1} className={data.currentPage <= 1 ? "pager-disabled" : "pager"}
                onClick={() => setPage(1)}
                style={data.currentPage <= 1 ? { pointerEvents: 'none' } : {}}>
                <span className='material-symbols-outlined'>first_page</span>
              </button>
              <button tabIndex={-1} className={data.currentPage <= 1 ? "pager-disabled" : "pager"}
                onClick={() => setPage(data.currentPage - 1)}
                style={data.currentPage <= 1 ? { pointerEvents: 'none' } : {}}>
                <span className='material-symbols-outlined'>navigate_before</span>
              </button>
              <div className="w-44 mt-1 ml-2">
                {data.currentBegin()}～{data.currentEnd()}／{items.length}件
              </div>
              <button tabIndex={-1} className={data.currentPage === data.maxPage ? "pager-disabled" : "pager"}
                onClick={() => setPage(data.currentPage + 1)}
                style={data.currentPage === data.maxPage ? { pointerEvents: 'none' } : {}}>
                <span className='material-symbols-outlined'>navigate_next</span>
              </button>
              <button tabIndex={-1} className={data.currentPage === data.maxPage ? "pager-disabled" : "pager"}
                onClick={() => setPage(data.maxPage)}
                style={data.currentPage === data.maxPage ? { pointerEvents: 'none' } : {}}>
                <span className='material-symbols-outlined'>last_page</span>
              </button>
            </div>
            {/* 検索エラー */}
            <div className='w-full lg:flex hidden'>
              <NotFound />
            </div>
            <div className='w-full md:w-7/12 lg:text-right  md:ml-10 lg:ml-0'>
              {/* CSVダウンロードボタン */}
              <button onClick={CsvDownload} disabled={buttonDisabled || !csvButtonDisabled} className={buttonDisabled || !csvButtonDisabled ? "btn btn-sm ml-4 w-64 flex-0 rounded-full border-none bg-inapplicable" : "btn btn-sm  ml-4 w-64 flex-0 rounded-full border-none bg-gradient-to-b from-action-from to-action-to"}>
                <span className="material-symbols-outlined pr-1 text-white">file_download</span>
                <div className='text-white'>CSVダウンロード</div>
              </button>
            </div>
          </div>
        </div>
        {/* 企業情報一覧テーブル */}
        <div className="w-11/12 mx-auto overflow-scroll whitespace-pre max-h-96">
          <table className="w-full table-fixed">
            <thead>
              <tr className="lg:text-base md:text-lg font-normal bg-header border-b-2 text-left sticky top-0">
                <th className={"font-normal text-center w-32"}>
                  <button tabIndex={-1} onClick={event => handleClickSort(event, 'KCD')} disabled={buttonDisabled} className={buttonDisabled ? "text-slate-400" : "text-black"}>
                    TSR企業コード
                    <span className={sort.item === "KCD" ? "text-main ml-2" : "invisible"}>
                      {sort.order === 1 ? '▲' : '▼'}
                    </span>
                  </button>
                </th>
                <th className={"font-normal text-left w-40"}>
                  <button tabIndex={-1} onClick={event => handleClickSort(event, 'RK_SZKN')} disabled={buttonDisabled} className={buttonDisabled ? "text-slate-400" : "text-black"}>
                    漢字商号
                    <span className={sort.item === "RK_SZKN" ? "text-main ml-2" : "invisible"}>
                      {sort.order === 1 ? '▲' : '▼'}
                    </span>
                  </button>
                </th>
                <th className={"font-normal text-left w-40"}>
                  <button tabIndex={-1} onClick={event => handleClickSort(event, 'JST_ADKN')} disabled={buttonDisabled} className={buttonDisabled ? "text-slate-400" : "text-black"}>
                    所在地
                    <span className={sort.item === "JST_ADKN" ? "text-main ml-2" : "invisible"}>
                      {sort.order === 1 ? '▲' : '▼'}
                    </span>
                  </button>
                </th>
                <th className={"font-normal  w-40"}>
                  <button tabIndex={-1} onClick={event => handleClickSort(event, 'JST_SZIC_ZNTI_TEL')} disabled={buttonDisabled} className={buttonDisabled ? "text-slate-400" : "text-black"}>
                    電話番号
                    <span className={sort.item === "JST_SZIC_ZNTI_TEL" ? "text-main ml-2" : "invisible"}>
                      {sort.order === 1 ? '▲' : '▼'}
                    </span>
                  </button>
                </th>
                <th className={"font-normal text-left  w-40"}>
                  <button tabIndex={-1} onClick={event => handleClickSort(event, 'DHS_SIMIKN')} disabled={buttonDisabled} className={buttonDisabled ? "text-slate-400" : "text-black"}>
                    代表者氏名
                    <span className={sort.item === "DHS_SIMIKN" ? "text-main ml-2" : "invisible"}>
                      {sort.order === 1 ? '▲' : '▼'}
                    </span>
                  </button>
                </th>
                <th className={"font-normal text-center w-28"}>
                  <button tabIndex={-1} onClick={event => handleClickSort(event, 'JGYINS')} disabled={buttonDisabled} className={buttonDisabled ? "text-slate-400" : "text-black"}>
                    従業員数
                    <span className={sort.item === "JGYINS" ? "text-main ml-2" : "invisible"}>
                      {sort.order === 1 ? '▲' : '▼'}
                    </span>
                  </button>
                </th>
                <th className={"font-normal text-center w-40"}>
                  <button tabIndex={-1} onClick={event => handleClickSort(event, 'SHNKN')} disabled={buttonDisabled} className={buttonDisabled ? "text-slate-400" : "text-black"}>
                    資本金（千円）
                    <span className={sort.item === "SHNKN" ? "text-main ml-2" : "invisible"}>
                      {sort.order === 1 ? '▲' : '▼'}
                    </span>
                  </button>
                </th>
                <th className={"font-normal text-center w-40"}>
                  <button tabIndex={-1} onClick={event => handleClickSort(event, 'SNTI_GYSI_URAG_1')} disabled={buttonDisabled} className={buttonDisabled ? "text-slate-400" : "text-black"}>
                    最新売上高（千円）
                    <span className={sort.item === "SNTI_GYSI_URAG_1" ? "text-main ml-2" : "invisible"}>
                      {sort.order === 1 ? '▲' : '▼'}
                    </span>
                  </button>
                </th>
                <th className={"font-normal text-center w-40"}>
                  <button tabIndex={-1} onClick={event => handleClickSort(event, 'SNTI_GYSI_REK_1')} disabled={buttonDisabled} className={buttonDisabled ? "text-slate-400" : "text-black"}>
                    最新利益金（千円）
                    <span className={sort.item === "SNTI_GYSI_REK_1" ? "text-main ml-2" : "invisible"}>
                      {sort.order === 1 ? '▲' : '▼'}
                    </span>
                  </button>
                </th>
                <th className={"font-normal text-center w-24"}>
                  <button tabIndex={-1} onClick={event => handleClickSort(event, 'HYTN')} disabled={buttonDisabled} className={buttonDisabled ? "text-slate-400" : "text-black"}>
                    評点
                    <span className={sort.item === "HYTN" ? "text-main ml-2" : "invisible"}>
                      {sort.order === 1 ? '▲' : '▼'}
                    </span>
                  </button>
                </th>
                <th className={"font-normal text-center w-24"}>
                  <button tabIndex={-1} onClick={event => handleClickSort(event, 'RSK_SCR')} disabled={buttonDisabled} className={buttonDisabled ? "text-slate-400" : "text-black"}>
                    リスクスコア
                    <span className={sort.item === "RSK_SCR" ? "text-main ml-2" : "invisible"}>
                      {sort.order === 1 ? '▲' : '▼'}
                    </span>
                  </button>
                </th>
                <th className={"font-normal text-center w-40"}>
                  <button tabIndex={-1} onClick={event => handleClickSort(event, 'KKCD')} disabled={buttonDisabled} className={buttonDisabled ? "text-slate-400" : "text-black"}>
                    顧客コード
                    <span className={sort.item === "KKCD" ? "text-main ml-2" : "invisible"}>
                      {sort.order === 1 ? '▲' : '▼'}
                    </span>
                  </button>
                </th>
              </tr>
            </thead>
            <TableBodyMolecules value={data.currentData()} />
          </table>
        </div>
      </div>
    </>
  );
}

export default CorporateList;