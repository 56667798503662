import dayjs from 'shared/dayjs';

/** 納品物の有効期限切れチェック */
const checkExpiredStatus = (expireDate: string): boolean => {
  const today = dayjs(new Date()).format('YYYY/MM/DD');
  if (expireDate < today) {
    return true;
  } else {
    return false;
  }
}
export default checkExpiredStatus;
