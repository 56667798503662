import { FC } from "react";
import { Bar, Pie } from 'react-chartjs-2';
import { Chart, registerables } from "chart.js"
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(...registerables)
Chart.register(ChartDataLabels);

type Props = {
  barDataNumber: any
  pieDataNumber: any
}

/* ------------------------- 
  components
----------------------------*/
/* 評点分布 */
const RatingDistributionTab: FC<Props> = ({ barDataNumber, pieDataNumber }) => {
  // 棒グラフの設定値
  const barOptions = {
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        labels: {
          title: null
        },
      }
    }
  };

  // 円グラフの設定値
  const pieOptions = {
    maintainAspectRatio: false,
    plugins: { // グラフ横のラベル概要の表示設定
      legend: {
        position: 'right' as const,
        fullWidth: false,
      },
      tooltip: { // ツールチップの表示設定
        callbacks: {
          label: function (context: any) {
            let label = context.parsed + "%";
            return label;
          },
        },
      },
      datalabels: {
        color: '#fff',
        anchor: 'end', // ラベルをグラフの外側に表示
        align: 'start', // ラベルの位置を調整
        offset: 10, // ラベルのオフセットを調整
        clamp: true, // ラベルをグラフの内側に制限
        font: {
          size: 12, // フォントサイズを調整
          weight: 'normal' // フォントウェイトを調整
        },
        // グラフ内ラベルの表示
        formatter: (value: any, context: any) => {
          return value !== 0 ? `${context.chart.data.labels[context.dataIndex]}
${value}%` : '';
        },
      }
    },
    // グラフのサイズや位置を微調整するためのオプション
    layout: {
      padding: {
        left: 20,
        right: 20
      }
    }
  };

  const barData = {
    // x 軸のラベル
    labels: ['空白', '0~9', '10~19', '20~29', '30~39', '40~49', '50~59', '60~69', '70~79', '80~89', '90~100',],
    datasets: [
      {
        label: '評点レンジ別件数',
        // データの値
        data: barDataNumber,
        // グラフの背景色
        backgroundColor: [
          'rgba(0, 112, 192)',
        ],
      },
    ],
  };

  const pieData = {
    // ラベル
    labels: ['空白', '0~9', '10~19', '20~29', '30~39', '40~44', '45~49', '50~54', '55~59', '60~69', '70~79', '80~89', '90~100',],
    datasets: [
      {
        // データの値
        data: pieDataNumber,
        align: "end",
        // グラフの背景色
        backgroundColor: [
          'rgba(185, 205, 230)', // 空白
          'rgba(250, 150, 70)', // 0~9
          'rgba(75, 172, 200)', // 10~19
          'rgba(128, 100, 165)', // 20~29
          'rgba(155, 187, 89)', // 30~39
          'rgba(192, 80, 77)', // 40~44
          'rgba(79, 129, 189)', // 45~49
          'rgba(226, 107, 10)', // 50~54
          'rgba(49, 134, 155)', // 55~59
          'rgba(96, 73, 122)', // 60~69
          'rgba(118, 147, 60)', // 70~79
          'rgba(150, 54, 52)', // 80~89
          'rgba(54, 96, 146)', // 90~100
        ],
        // グラフの枠線の太さ
        borderWidth: 0,
      },
    ],
  };

  return (
    <>
      <div className="overflow-x-auto lg:overflow-hidden">
        <div className="inline-block min-w-full md:">
          <div className="flex my-5 ml-5">
            <div className="w-1/2">評点レンジ別件数</div>
            <div className="w-1/2 flex justify-between">
              <div>評点レンジ別比率</div>
              <div className="mr-10 mt-4">評点レンジ</div>
            </div>
          </div>
          <div className="flex w-full">
            <div className="mx-auto w-1/2">
              <Bar data={barData} width={450} height={350} options={barOptions} />
            </div>
            <div className="mx-auto w-1/2">
              {/* @ts-ignore */}
              <Pie data={pieData} width={450} height={350} options={pieOptions} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RatingDistributionTab;