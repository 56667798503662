export const formFields = {
  forceNewPassword: {
    password: {
      placeholder: '数字と英小文字を各1文字以上含む8文字以上',
      isRequired: true,
    },
    confirm_password: {
      labelHidden: true,
      placeholder: 'パスワードを再入力してください',
      isRequired: true,
    },
  },
  resetPassword: {
    username: {
      labelHidden: false,
      label: 'ご登録のメールアドレスに確認コードを送信します',
      placeholder: 'メールアドレス',
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      label: 'メールに送付された確認コードを入力してください',
      labelHidden: false,
      placeholder: '確認コード',
      isRequired: true,
    },
    password: {
      label: '新しいパスワード',
      labelHidden: false,
      placeholder: '数字と英小文字を各1文字以上含む8文字以上',
      isRequired: true,
    },
    confirm_password: {
      labelHidden: true,
      placeholder: '新しいパスワードを再入力してください',
      isRequired: true,
    },
  },
};