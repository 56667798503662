/** 郵便番号にハイフン付与 */
const formatZipcode = (zipcode: string | null) => {
  let formarttedZipcode = null;
  if (zipcode === "") {
    formarttedZipcode = "";
  } else if (zipcode) {
    formarttedZipcode = zipcode.substring(0, 3) + "-" + zipcode.substring(3)
  }
  return formarttedZipcode;
}
export default formatZipcode;
