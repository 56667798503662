/** 桁数に合わせて年月もしくは年月日に変換 */
const formatDate = (date: string | null) => {
  let formarttedYmd = null;
  if (date === "") {
    formarttedYmd = "";
  } else if (date) {
    let year = "";
    let month = "";
    let day = "";
    // 年月に変換
    year = date.substring(0, 4);
    month = date.substring(4, 6);
    if (date.length === 6) {
      formarttedYmd = year + "年" + month + "月";
      // 年月日に変換
    } else if (date.length === 8) {
      day = date.substring(6);
      formarttedYmd = year + "年" + month + "月" + day + "日";
    }
  }
  return formarttedYmd;
}
export default formatDate;
