/** 電話番号にハイフン付与 */
const formatPhoneNumber = (phoneNumber: string | null) => {
  let formarttedPhoneNumber = null;
  if (phoneNumber === "") {
    formarttedPhoneNumber = "";
  } else if (phoneNumber) {
    const areaCode = phoneNumber.substring(0, 7);
    const cotyCode = phoneNumber.substring(7, 11);
    const registerCode = phoneNumber.substring(11);
    formarttedPhoneNumber = areaCode.trim() + "-" + cotyCode.trim() + "-" + registerCode.trim();
  }
  return formarttedPhoneNumber;
}
export default formatPhoneNumber;
