import { FC, useContext } from "react";
import { CorporateInfo, CorporateTableData } from "./Corporate";
import formatDate from "shared/formatDate";
import formatNumberToLocaleString from "shared/formatNumberToLocaleString";
import convertCodeIntoValue from "shared/convertCodeIntoValue";
import formatZipcode from "shared/formatZipcode";
import formatPhoneNumber from "shared/formatPhoneNumber";
import { GYSI_ZIKB_NAME, ZUKB_NAME, TSN_KRK_KBN_NAME, SEBT_KBN_NAME } from "constant";

// 業種
type GYSH = {
  GSCD: string | null; // 業種コード
  GYSH_FBMI: string | null; // 業種名称
}
// 扱い品
type ATKN = {
  AGCD: string | null; // 扱い品コード
  ATKN: string | null; // 扱い品名称
}
// 事業内容
type JGNY = {
  JGNY: string | null; // 事業内容
  JGNY_KSHR: string | null; // 事業内容構成比率
}
// 業績
type GYSI = {
  GYSI_KSN_YM: string | null; // 決算年月
  GYSI_MSU: string | null; // 月数
  SNTI_GYSI_URAG: string | null; // 売上高（千円）
  GYSI_ZIKB: string | null; // 税込引区分
  SNTI_GYSI_REK: string | null; // 利益金（千円）
  GYSK_JSHR: string | null; // 自己資本比率
  HISG: string | null; // 配当総額（千円）
  ZUKB: string | null; // 財務有無
}
// 役員
type YI = {
  YIMI: string | null; // 役員名
  YSMI: string | null; // 役名
  YKSH_KGMI: string | null; // 肩書名
}
// 株主
type KN = {
  KNMI: string | null; // 株主名称
  CTPR_MCWR: string | null; // 持株比率
}
// 取引銀行
type FI = {
  FICD: string | null; // 銀行コード
  FIKN: string | null; // 取引銀行名
  TNKN: string | null; // 取引銀行店舗名
}

const CorporateDetail: FC = () => {
  // 企業情報
  const corporateInfo = useContext(CorporateInfo);
  if (!corporateInfo) {
    return (
      <></>
    )
  }
  /* ------------------------- 
    業種
  ----------------------------*/
  const CorporateIndustryTable: FC = () => {
    const GYSH: GYSH[] = [
      { GSCD: corporateInfo.GSCD_1, GYSH_FBMI: corporateInfo.GYSH_FBMI_1 },
      { GSCD: corporateInfo.GSCD_2, GYSH_FBMI: corporateInfo.GYSH_FBMI_2 },
      { GSCD: corporateInfo.GSCD_3, GYSH_FBMI: corporateInfo.GYSH_FBMI_3 },
      { GSCD: corporateInfo.GSCD_4, GYSH_FBMI: corporateInfo.GYSH_FBMI_4 },
      { GSCD: corporateInfo.GSCD_5, GYSH_FBMI: corporateInfo.GYSH_FBMI_5 },
      { GSCD: corporateInfo.GSCD_6, GYSH_FBMI: corporateInfo.GYSH_FBMI_6 }
    ];
    return (
      <table className="w-full lg:w-5/12 table-fixed">
        <caption className="text-left mb-1 md:-ml-3">業種</caption>
        <thead>
          <tr className="text-sm h-4 bg-header border-b-2 text-left">
            <th className="font-normal w-4/12 md:w-3/12 pl-2">業種コード</th>
            <th className="font-normal pl-2">業種</th>
          </tr>
        </thead>
        <tbody>
          {GYSH.map((g, index) => {
            return (
              <tr className="border-b-2 h-6" key={index}>
                <CorporateTableData data={g.GSCD} className="pl-2" />
                <CorporateTableData data={g.GYSH_FBMI} className="pl-2" />
              </tr>
            )
          })}
        </tbody>
      </table>
    );
  }
  /* ------------------------- 
    扱い品
  ----------------------------*/
  const ProductsTable: FC = () => {
    const ATKN: ATKN[] = [
      { AGCD: corporateInfo.AGCD_1, ATKN: corporateInfo.ATKN_1 },
      { AGCD: corporateInfo.AGCD_2, ATKN: corporateInfo.ATKN_2 },
      { AGCD: corporateInfo.AGCD_3, ATKN: corporateInfo.ATKN_3 },
      { AGCD: corporateInfo.AGCD_4, ATKN: corporateInfo.ATKN_4 },
      { AGCD: corporateInfo.AGCD_5, ATKN: corporateInfo.ATKN_5 },
      { AGCD: corporateInfo.AGCD_6, ATKN: corporateInfo.ATKN_6 }
    ];
    return (
      <table className="w-full lg:w-7/12 mt-8 lg:mt-0 table-fixed">
        <caption className="text-left mb-1 md:-ml-3">扱い品</caption>
        <thead>
          <tr className="text-sm h-4 bg-header border-b-2 text-left">
            <th className="font-normal w-4/12 md:w-3/12 pl-2">扱い品コード</th>
            <th className="font-normal pl-2">扱い品</th>
          </tr>
        </thead>
        <tbody>
          {ATKN.map((a, index) => {
            return (
              <tr className="border-b-2 h-6" key={index}>
                <CorporateTableData data={a.AGCD} className="pl-2" />
                <CorporateTableData data={a.ATKN} className="pl-2" />
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }
  /* ------------------------- 
    事業内容
  ----------------------------*/
  const BusinessTable: FC = () => {
    const JGNY: JGNY[] = [
      { JGNY: corporateInfo.JGNY_1, JGNY_KSHR: corporateInfo.JGNY_KSHR_1 },
      { JGNY: corporateInfo.JGNY_2, JGNY_KSHR: corporateInfo.JGNY_KSHR_2 },
      { JGNY: corporateInfo.JGNY_3, JGNY_KSHR: corporateInfo.JGNY_KSHR_3 },
      { JGNY: corporateInfo.JGNY_4, JGNY_KSHR: corporateInfo.JGNY_KSHR_4 },
      { JGNY: corporateInfo.JGNY_5, JGNY_KSHR: corporateInfo.JGNY_KSHR_5 },
      { JGNY: corporateInfo.JGNY_6, JGNY_KSHR: corporateInfo.JGNY_KSHR_6 }
    ];
    return (
      <div className="flex flex-row mt-8">
        <table className="w-full table-fixed">
          <caption className="text-left mb-1 md:-ml-3">事業内容</caption>
          <thead>
            <tr className="text-sm h-4 bg-header border-b-2 text-left">
              <th className="font-normal w-6/12 md:w-9/12 pl-2">事業内容</th>
              <th className="font-normal text-right pr-8">構成比率（％）</th>
            </tr>
          </thead>
          <tbody>
            {JGNY.map((j, index) => {
              return (
                <tr className="border-b-2 h-6" key={index}>
                  <CorporateTableData data={j.JGNY} className="pl-2" />
                  <CorporateTableData data={j.JGNY_KSHR} className="text-right pl-2 pr-14" />
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
  /* ------------------------- 
    業績
  ----------------------------*/
  const BusinessPerformanceTable: FC = () => {
    // 自己資本比率
    let GYSK_JSHR_1 = null;
    let GYSK_JSHR_2 = null;
    let GYSK_JSHR_3 = null;
    if (corporateInfo.GYSK_JSHR_1 === "") {
      GYSK_JSHR_1 = "";
    } else if (corporateInfo.GYSK_JSHR_1) {
      GYSK_JSHR_1 = corporateInfo.GYSK_JSHR_1;
    }
    if (corporateInfo.GYSK_JSHR_2 === "") {
      GYSK_JSHR_2 = "";
    } else if (corporateInfo.GYSK_JSHR_2) {
      GYSK_JSHR_2 = corporateInfo.GYSK_JSHR_2;
    }
    if (corporateInfo.GYSK_JSHR_3 === "") {
      GYSK_JSHR_3 = "";
    } else if (corporateInfo.GYSK_JSHR_3) {
      GYSK_JSHR_3 = corporateInfo.GYSK_JSHR_3;
    }
    const GYSI: GYSI[] = [
      {
        GYSI_KSN_YM: formatDate(corporateInfo.GYSI_KSN_YM_1),
        GYSI_MSU: corporateInfo.GYSI_MSU_1,
        SNTI_GYSI_URAG: formatNumberToLocaleString(corporateInfo.SNTI_GYSI_URAG_1),
        GYSI_ZIKB: convertCodeIntoValue(corporateInfo.GYSI_ZIKB_1, GYSI_ZIKB_NAME),
        SNTI_GYSI_REK: formatNumberToLocaleString(corporateInfo.SNTI_GYSI_REK_1),
        GYSK_JSHR: GYSK_JSHR_1,
        HISG: formatNumberToLocaleString(corporateInfo.HISG_1),
        ZUKB: convertCodeIntoValue(corporateInfo.ZUKB_1, ZUKB_NAME),
      },
      {
        GYSI_KSN_YM: formatDate(corporateInfo.GYSI_KSN_YM_2),
        GYSI_MSU: corporateInfo.GYSI_MSU_2,
        SNTI_GYSI_URAG: formatNumberToLocaleString(corporateInfo.SNTI_GYSI_URAG_2),
        GYSI_ZIKB: convertCodeIntoValue(corporateInfo.GYSI_ZIKB_2, GYSI_ZIKB_NAME),
        SNTI_GYSI_REK: formatNumberToLocaleString(corporateInfo.SNTI_GYSI_REK_2),
        GYSK_JSHR: GYSK_JSHR_2,
        HISG: formatNumberToLocaleString(corporateInfo.HISG_2),
        ZUKB: convertCodeIntoValue(corporateInfo.ZUKB_2, ZUKB_NAME),
      },
      {
        GYSI_KSN_YM: formatDate(corporateInfo.GYSI_KSN_YM_3),
        GYSI_MSU: corporateInfo.GYSI_MSU_3,
        SNTI_GYSI_URAG: formatNumberToLocaleString(corporateInfo.SNTI_GYSI_URAG_3),
        GYSI_ZIKB: convertCodeIntoValue(corporateInfo.GYSI_ZIKB_3, GYSI_ZIKB_NAME),
        SNTI_GYSI_REK: formatNumberToLocaleString(corporateInfo.SNTI_GYSI_REK_3),
        GYSK_JSHR: GYSK_JSHR_3,
        HISG: formatNumberToLocaleString(corporateInfo.HISG_3),
        ZUKB: convertCodeIntoValue(corporateInfo.ZUKB_3, ZUKB_NAME),
      },
    ]
    return (
      <>
        {/* SP画面 */}
        <div className="md:hidden mt-8">
          <table className="mt-1 w-full table-fixed">
            <caption className="text-left mb-1">
              <span>業績</span>
              <span className="float-right text-sm pt-1">単位：千円</span>
            </caption>
            <thead>
              <tr className="text-sm h-4 bg-header border-b-2 text-left">
                <th className="font-normal w-8 pl-2">決算年月</th>
                <th className="font-normal w-3 text-right pr-10">月数</th>
              </tr>
            </thead>
            <tbody>
              {GYSI.map((g, index) => {
                return (
                  <tr className="border-b-2 h-6" key={index}>
                    <CorporateTableData data={g.GYSI_KSN_YM} className="pl-2" />
                    <CorporateTableData data={g.GYSI_MSU} className="text-right pr-10" />
                  </tr>
                )
              })}
            </tbody>
          </table>
          <table className="w-full mt-6 table-fixed">
            <thead>
              <tr className="text-sm h-4 bg-header border-b-2 text-left">
                <th className="font-normal w-10 text-right pr-2">売上高</th>
                <th className="font-normal w-10 pl-10">税込引区分</th>
              </tr>
            </thead>
            <tbody>
              {GYSI.map((g, index) => {
                return (
                  <tr className="border-b-2 h-6" key={index}>
                    <CorporateTableData data={g.SNTI_GYSI_URAG} className="text-right pr-2" />
                    <CorporateTableData data={g.GYSI_ZIKB} className="pl-10" />
                  </tr>
                )
              })}
            </tbody>
          </table>
          <table className="w-full mt-6 table-fixed">
            <thead>
              <tr className="text-sm h-4 bg-header border-b-2 text-left">
                <th className="font-normal w-10 text-right pr-2">利益金</th>
                <th className="font-normal w-10 text-right pr-2">自己資本比率（％）</th>
              </tr>
            </thead>
            <tbody>
              {GYSI.map((g, index) => {
                return (
                  <tr className="border-b-2 h-6" key={index}>
                    <CorporateTableData data={g.SNTI_GYSI_REK} className="text-right pr-2" />
                    <CorporateTableData data={g.GYSK_JSHR} className="text-right pr-2" />
                  </tr>
                )
              })}
            </tbody>
          </table>
          <table className="w-full mt-6 table-fixed">
            <thead>
              <tr className="text-sm h-4 bg-header border-b-2 text-left">
                <th className="font-normal w-10 text-right pr-2">配当総額</th>
                <th className="font-normal w-10 pl-10">財務</th>
              </tr>
            </thead>
            <tbody>
              {GYSI.map((g, index) => {
                return (
                  <tr className="border-b-2 h-6" key={index}>
                    <CorporateTableData data={g.HISG} className="text-right pr-2" />
                    <CorporateTableData data={g.ZUKB} className="pl-10" />
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        {/* タブレット画面 */}
        <div className="hidden md:block lg:hidden mt-8">
          <table className="mt-1 w-full table-fixed">
            <caption className="text-left mb-1">
              <span className="-ml-3">業績</span>
              <span className="float-right text-sm pt-1">単位：千円</span>
            </caption>
            <thead>
              <tr className="text-sm h-4 bg-header border-b-2 text-left">
                <th className="font-normal w-10 pl-2">決算年月</th>
                <th className="font-normal w-7 text-right">月数</th>
                <th className="font-normal w-20 text-right pr-10">売上高</th>
                <th className="font-normal w-10 pl-2">税込引区分</th>
                <th className="font-normal w-20 text-right pr-10">利益金</th>
              </tr>
            </thead>
            <tbody>
              {GYSI.map((g, index) => {
                return (
                  <tr className="border-b-2 h-6" key={index}>
                    <CorporateTableData data={g.GYSI_KSN_YM} className="pl-2" />
                    <CorporateTableData data={g.GYSI_MSU} className="text-right" />
                    <CorporateTableData data={g.SNTI_GYSI_URAG} className="text-right pr-10" />
                    <CorporateTableData data={g.GYSI_ZIKB} className="pl-2" />
                    <CorporateTableData data={g.SNTI_GYSI_REK} className="text-right pr-10" />
                  </tr>
                )
              })}
            </tbody>
          </table>
          <table className="w-full mt-6 table-fixed">
            <thead>
              <tr className="text-sm h-4 bg-header border-b-2 text-left">
                <th className="font-normal w-10 text-right pr-2">自己資本比率（％）</th>
                <th className="font-normal w-12 text-right pr-10">配当総額</th>
                <th className="font-normal w-12 pl-10">財務</th>
              </tr>
            </thead>
            <tbody>
              {GYSI.map((g, index) => {
                return (
                  <tr className="border-b-2 h-6" key={index}>
                    <CorporateTableData data={g.GYSK_JSHR} className="text-right pr-2" />
                    <CorporateTableData data={g.HISG} className="text-right pr-10" />
                    <CorporateTableData data={g.ZUKB} className="pl-10" />
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        {/* PC画面 */}
        <div className="hidden lg:block mt-8">
          <table className="mt-1 w-full table-fixed">
            <caption className="text-left mb-1">
              <span className="-ml-3">業績</span>
              <span className="float-right text-sm pt-1">単位：千円</span>
            </caption>
            <thead>
              <tr className="text-sm h-4 bg-header border-b-2 text-left">
                <th className="font-normal w-10 pl-2">決算年月</th>
                <th className="font-normal w-7 text-right">月数</th>
                <th className="font-normal w-20 text-right pr-10">売上高</th>
                <th className="font-normal w-10 pl-2">税込引区分</th>
                <th className="font-normal w-20 text-right pr-10">利益金</th>
                <th className="font-normal w-20 text-right">自己資本比率（％）</th>
                <th className="font-normal w-20 text-right pr-10">配当総額</th>
                <th className="font-normal w-10 pl-10">財務</th>
              </tr>
            </thead>
            <tbody>
              {GYSI.map((g, index) => {
                return (
                  <tr className="border-b-2 h-6" key={index}>
                    <CorporateTableData data={g.GYSI_KSN_YM} className="pl-2" />
                    <CorporateTableData data={g.GYSI_MSU} className="text-right" />
                    <CorporateTableData data={g.SNTI_GYSI_URAG} className="text-right pr-10" />
                    <CorporateTableData data={g.GYSI_ZIKB} className="pl-2" />
                    <CorporateTableData data={g.SNTI_GYSI_REK} className="text-right pr-10" />
                    <CorporateTableData data={g.GYSK_JSHR} className="text-right" />
                    <CorporateTableData data={g.HISG} className="text-right pr-10" />
                    <CorporateTableData data={g.ZUKB} className="pl-10" />
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </>
    )
  }
  /* ------------------------- 
    業種内売上順位
  ----------------------------*/
  const SalesrankTable: FC = () => {
    // 全国順位
    let ZNKK_URJN: string | null = null;
    if (corporateInfo.ZNKK_URJN !== null && corporateInfo.ZNKK_URJN_TSHO_SASU !== null) {
      ZNKK_URJN = corporateInfo.ZNKK_URJN === "" && corporateInfo.ZNKK_URJN_TSHO_SASU === ""
        ? "" : `${Number(corporateInfo.ZNKK_URJN).toLocaleString()}位／${Number(corporateInfo.ZNKK_URJN_TSHO_SASU).toLocaleString()}社`;
    }
    // 県内順位
    let KNBT_URJN: string | null = null;
    if (corporateInfo.KNBT_URJN !== null && corporateInfo.KNBT_URJN_TSHO_SASU !== null) {
      KNBT_URJN = corporateInfo.KNBT_URJN === "" && corporateInfo.KNBT_URJN_TSHO_SASU === ""
        ? "" : `${Number(corporateInfo.KNBT_URJN).toLocaleString()}位／${Number(corporateInfo.KNBT_URJN_TSHO_SASU).toLocaleString()}社`;
    }
    return (
      <>
        {/* SP画面 */}
        <div className="md:hidden mt-8">
          <table className="w-full table-fixed">
            <caption className="text-left mb-1">業種内売上順位</caption>
            <thead>
              <tr className="text-sm h-4 bg-header border-b-2 text-left">
                <th className="font-normal w-4/12 pl-2">対象決算期</th>
                <th className="font-normal pl-2">全国順位</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b-2 h-6">
                <CorporateTableData data={formatDate(corporateInfo.URJN_TSHO_KSN_YM)} className="pl-2" />
                <CorporateTableData data={ZNKK_URJN} className="pl-2" />
              </tr>
            </tbody>
          </table>
          <table className="w-full mt-6 table-fixed">
            <thead>
              <tr className="text-sm h-4 bg-header border-b-2 text-left">
                <th className="font-normal w-5/12 pl-2">県内順位</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b-2 h-6">
                <CorporateTableData data={KNBT_URJN} className="pl-2" />
              </tr>
            </tbody>
          </table>
        </div>
        {/* タブレット・PC画面 */}
        <div className="hidden md:block mt-8">
          <table className="w-full table-fixed">
            <caption className="text-left mb-1 -ml-3">業種内売上順位</caption>
            <thead>
              <tr className="text-sm h-4 bg-header border-b-2 text-left">
                <th className="font-normal w-3/12 pl-2">対象決算期</th>
                <th className="font-normal w-3/12 pl-2">全国順位</th>
                <th className="font-normal w-6/12 pl-2">県内順位</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b-2 h-6">
                <CorporateTableData data={formatDate(corporateInfo.URJN_TSHO_KSN_YM)} className="pl-2" />
                <CorporateTableData data={ZNKK_URJN} className="pl-2" />
                <CorporateTableData data={KNBT_URJN} className="pl-2" />
              </tr>
            </tbody>
          </table>
        </div>
      </>
    )
  }
  /* ------------------------- 
    代表者
  ----------------------------*/
  const RepresentativeTable: FC = () => {
    // 就任
    let DHS_SHUN_YM = null;
    if (corporateInfo.DHS_SHUN_YMD === "") {
      DHS_SHUN_YM = "";
    } else if (corporateInfo.DHS_SHUN_YMD) {
      DHS_SHUN_YM = corporateInfo.DHS_SHUN_YMD.slice(0, 6);
    }
    // 最終学歴
    let DHS_GRK = null;
    if (!(corporateInfo.DHS_SIS_EDU_GKMI === null && corporateInfo.DHS_GRKN === null)) {
      DHS_GRK = "";
      if (!corporateInfo.DHS_GRKN) {
        DHS_GRK = corporateInfo.DHS_SIS_EDU_GKMI;
      } else if (!corporateInfo.DHS_SIS_EDU_GKMI) {
        DHS_GRK = corporateInfo.DHS_GRKN;
      } else {
        DHS_GRK = `${corporateInfo.DHS_SIS_EDU_GKMI}　${corporateInfo.DHS_GRKN}`
      }
    };
    // 趣味
    let DHS_HOMI: string | null = null;
    let DHS_HOMI_1: string = "";
    let DHS_HOMI_2: string = "";
    let DHS_HOMI_3: string = "";
    if (corporateInfo.DHS_HOMI_1 !== null && corporateInfo.DHS_HOMI_2 !== null && corporateInfo.DHS_HOMI_3 !== null) {
      DHS_HOMI = "";
      DHS_HOMI_1 = corporateInfo.DHS_HOMI_1;
      DHS_HOMI_2 = corporateInfo.DHS_HOMI_2;
      DHS_HOMI_3 = corporateInfo.DHS_HOMI_3;
      [DHS_HOMI_1, DHS_HOMI_2, DHS_HOMI_3].forEach((h) => {
        if (h) {
          DHS_HOMI += `、${h}`;
        }
      })
      // 文頭の「、」削除
      DHS_HOMI = DHS_HOMI?.slice(1);
    }
    return (
      <>
        {/* SP・タブレット画面 */}
        <div className="lg:hidden mt-8">
          <table className="w-full table-fixed">
            <caption className="text-left mb-1 md:-ml-3">代表者</caption>
            <tbody>
              <tr className=" h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2 w-6/12 md:w-3/12">氏名</th>
                <CorporateTableData data={corporateInfo.DHS_SIMIKN} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">氏名カナ</th>
                <CorporateTableData data={corporateInfo.DHS_SIMIKT} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">英文氏名</th>
                <CorporateTableData data={corporateInfo.DHS_SIMIEI} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">郵便番号</th>
                <CorporateTableData data={formatZipcode(corporateInfo.DHS_ADD_ZIP)} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">電話番号</th>
                <CorporateTableData data={formatPhoneNumber(corporateInfo.DHS_ZNTI_TEL)} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">性別</th>
                <CorporateTableData data={convertCodeIntoValue(corporateInfo.SEBT_KBN, SEBT_KBN_NAME)} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">生年月日</th>
                <CorporateTableData data={formatDate(corporateInfo.SNNGP_SRK)} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">干支</th>
                <CorporateTableData data={corporateInfo.DHS_ETMI} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">住所</th>
                <CorporateTableData data={corporateInfo.DHS_ADKN} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">住所カナ</th>
                <CorporateTableData data={corporateInfo.DHS_JSKT} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">役名</th>
                <CorporateTableData data={corporateInfo.DHS_YSMI} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">肩書名</th>
                <CorporateTableData data={corporateInfo.DHS_KGMI} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">就任</th>
                <CorporateTableData data={formatDate(DHS_SHUN_YM)} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">倒産経歴</th>
                <CorporateTableData data={convertCodeIntoValue(corporateInfo.TSN_KRK_KBN, TSN_KRK_KBN_NAME)} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">出身地</th>
                <CorporateTableData data={corporateInfo.DHS_HCMI} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">最終学歴</th>
                <CorporateTableData data={DHS_GRK} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">住居</th>
                <CorporateTableData data={corporateInfo.DHS_JUMI} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">趣味</th>
                <CorporateTableData data={DHS_HOMI} className="pl-2" />
              </tr>
            </tbody>
          </table>
        </div>
        {/* PC画面 */}
        <div className="hidden lg:block mt-8">
          <table className="w-full table-fixed">
            <caption className="text-left mb-1 -ml-3">代表者</caption>
            <tbody>
              <tr className=" h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">氏名</th>
                <CorporateTableData data={corporateInfo.DHS_SIMIKN} className="pl-2" colSpan={12} />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">氏名カナ</th>
                <CorporateTableData data={corporateInfo.DHS_SIMIKT} className="pl-2" colSpan={12} />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">英文氏名</th>
                <CorporateTableData data={corporateInfo.DHS_SIMIEI} className="pl-2" colSpan={12} />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">郵便番号</th>
                <CorporateTableData data={formatZipcode(corporateInfo.DHS_ADD_ZIP)} className="pl-2" colSpan={2} />
                <th className="font-normal text-sm bg-header pl-2">電話番号</th>
                <CorporateTableData data={formatPhoneNumber(corporateInfo.DHS_ZNTI_TEL)} className="pl-2" colSpan={2} />
                <th className="font-normal text-sm bg-header pl-2">性別</th>
                <CorporateTableData data={convertCodeIntoValue(corporateInfo.SEBT_KBN, SEBT_KBN_NAME)} className="pl-2" />
                <th className="font-normal text-sm bg-header pl-2">生年月日</th>
                <CorporateTableData data={formatDate(corporateInfo.SNNGP_SRK)} className="pl-2" colSpan={2} />
                <th className="font-normal text-sm bg-header pl-2">干支</th>
                <CorporateTableData data={corporateInfo.DHS_ETMI} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">住所</th>
                <CorporateTableData data={corporateInfo.DHS_ADKN} className="pl-2" colSpan={12} />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">住所カナ</th>
                <CorporateTableData data={corporateInfo.DHS_JSKT} className="pl-2" colSpan={12} />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">役名</th>
                <CorporateTableData data={corporateInfo.DHS_YSMI} className="pl-2" colSpan={2} />
                <th className="font-normal text-sm bg-header pl-2">肩書名</th>
                <CorporateTableData data={corporateInfo.DHS_KGMI} className="pl-2" colSpan={4} />
                <th className="font-normal text-sm bg-header pl-2">就任</th>
                <CorporateTableData data={formatDate(DHS_SHUN_YM)} className="pl-2" colSpan={2} />
                <th className="font-normal text-sm bg-header pl-2">倒産経歴</th>
                <CorporateTableData data={convertCodeIntoValue(corporateInfo.TSN_KRK_KBN, TSN_KRK_KBN_NAME)} className="pl-2" />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">出身地</th>
                <CorporateTableData data={corporateInfo.DHS_HCMI} className="pl-2" colSpan={5} />
                <th className="font-normal text-sm bg-header pl-2">最終学歴</th>
                <CorporateTableData data={DHS_GRK} className="pl-2" colSpan={6} />
              </tr>
              <tr className="h-4 border-y-2 text-left">
                <th className="font-normal text-sm bg-header pl-2">住居</th>
                <CorporateTableData data={corporateInfo.DHS_JUMI} className="pl-2" colSpan={2} />
                <th className="font-normal text-sm bg-header pl-2">趣味</th>
                <CorporateTableData data={DHS_HOMI} className="pl-2" colSpan={9} />
              </tr>
            </tbody>
          </table>
        </div>
      </>
    )
  }
  /* ------------------------- 
    役員
  ----------------------------*/
  const ExecutivesTable: FC = () => {
    const YI: YI[] = [
      { YIMI: corporateInfo.YIMI_1, YSMI: corporateInfo.YSMI_1, YKSH_KGMI: corporateInfo.YKSH_KGMI_1 },
      { YIMI: corporateInfo.YIMI_2, YSMI: corporateInfo.YSMI_2, YKSH_KGMI: corporateInfo.YKSH_KGMI_2 },
      { YIMI: corporateInfo.YIMI_3, YSMI: corporateInfo.YSMI_3, YKSH_KGMI: corporateInfo.YKSH_KGMI_3 },
      { YIMI: corporateInfo.YIMI_4, YSMI: corporateInfo.YSMI_4, YKSH_KGMI: corporateInfo.YKSH_KGMI_4 },
      { YIMI: corporateInfo.YIMI_5, YSMI: corporateInfo.YSMI_5, YKSH_KGMI: corporateInfo.YKSH_KGMI_5 },
      { YIMI: corporateInfo.YIMI_6, YSMI: corporateInfo.YSMI_6, YKSH_KGMI: corporateInfo.YKSH_KGMI_6 },
      { YIMI: corporateInfo.YIMI_7, YSMI: corporateInfo.YSMI_7, YKSH_KGMI: corporateInfo.YKSH_KGMI_7 },
      { YIMI: corporateInfo.YIMI_8, YSMI: corporateInfo.YSMI_8, YKSH_KGMI: corporateInfo.YKSH_KGMI_8 },
      { YIMI: corporateInfo.YIMI_9, YSMI: corporateInfo.YSMI_9, YKSH_KGMI: corporateInfo.YKSH_KGMI_9 },
      { YIMI: corporateInfo.YIMI_10, YSMI: corporateInfo.YSMI_10, YKSH_KGMI: corporateInfo.YKSH_KGMI_10 }
    ];
    return (
      <div className="mt-8">
        <table className="w-full table-fixed">
          <caption className="text-left mb-1 md:-ml-3">役員</caption>
          <thead>
            <tr className="text-sm h-4 bg-header border-b-2 text-left">
              <th className="font-normal w-4/12 md:w-7/12 pl-2">氏名</th>
              <th className="font-normal w-4/12 md:w-2/12 pl-2 lg:pl-11">役名</th>
              <th className="font-normal w-4/12 md:w-3/12 pl-2 lg:pl-6">肩書名</th>
            </tr>
          </thead>
          <tbody>
            {YI.map((y, index) => {
              return (
                <tr className="border-b-2 h-6" key={index}>
                  <CorporateTableData data={y.YIMI} className="pl-2" />
                  <CorporateTableData data={y.YSMI} className="pl-2 lg:pl-11" />
                  <CorporateTableData data={y.YKSH_KGMI} className="pl-2 lg:pl-6" />
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
  /* ------------------------- 
    株主
  ----------------------------*/
  const ShareholdersTable: FC = () => {
    const KN: KN[] = [
      { KNMI: corporateInfo.KNMI_1, CTPR_MCWR: corporateInfo.CTPR_MCWR_1 },
      { KNMI: corporateInfo.KNMI_2, CTPR_MCWR: corporateInfo.CTPR_MCWR_2 },
      { KNMI: corporateInfo.KNMI_3, CTPR_MCWR: corporateInfo.CTPR_MCWR_3 },
      { KNMI: corporateInfo.KNMI_4, CTPR_MCWR: corporateInfo.CTPR_MCWR_4 },
      { KNMI: corporateInfo.KNMI_5, CTPR_MCWR: corporateInfo.CTPR_MCWR_5 },
      { KNMI: corporateInfo.KNMI_6, CTPR_MCWR: corporateInfo.CTPR_MCWR_6 }
    ]
    return (
      <div className="mt-8">
        <table className="w-full mt-8 table-fixed">
          <caption className="text-left mb-1 md:-ml-3">株主</caption>
          <thead>
            <tr className="text-sm h-4 bg-header border-b-2 text-left">
              <th className="font-normal w-5/12 md:w-4/12 lg:w-7/12 pl-2">株主名称</th>
              <th className="font-normal md:w-3/12 lg:w-2/12 text-right pr-8">持ち株比率（％）</th>
            </tr>
          </thead>
          <tbody>
            {KN.map((k, index) => {
              return (
                <tr className="border-b-2 h-6" key={index}>
                  <CorporateTableData data={k.KNMI} className="pl-2" />
                  <CorporateTableData data={k.CTPR_MCWR} className="text-right pr-14" />
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
  /* ------------------------- 
    取引銀行
  ----------------------------*/
  const BanksTable: FC = () => {
    const FI: FI[] = [
      { FICD: corporateInfo.FICD_1, FIKN: corporateInfo.FIKN_1, TNKN: corporateInfo.TNKN_1 },
      { FICD: corporateInfo.FICD_2, FIKN: corporateInfo.FIKN_2, TNKN: corporateInfo.TNKN_2 },
      { FICD: corporateInfo.FICD_3, FIKN: corporateInfo.FIKN_3, TNKN: corporateInfo.TNKN_3 },
      { FICD: corporateInfo.FICD_4, FIKN: corporateInfo.FIKN_4, TNKN: corporateInfo.TNKN_4 },
      { FICD: corporateInfo.FICD_5, FIKN: corporateInfo.FIKN_5, TNKN: corporateInfo.TNKN_5 },
      { FICD: corporateInfo.FICD_6, FIKN: corporateInfo.FIKN_6, TNKN: corporateInfo.TNKN_6 },
      { FICD: corporateInfo.FICD_7, FIKN: corporateInfo.FIKN_7, TNKN: corporateInfo.TNKN_7 },
      { FICD: corporateInfo.FICD_8, FIKN: corporateInfo.FIKN_8, TNKN: corporateInfo.TNKN_8 },
      { FICD: corporateInfo.FICD_9, FIKN: corporateInfo.FIKN_9, TNKN: corporateInfo.TNKN_9 },
      { FICD: corporateInfo.FICD_10, FIKN: corporateInfo.FIKN_10, TNKN: corporateInfo.TNKN_10 }
    ]
    return (
      <>
        {/* SP画面 */}
        <div className="md:hidden mt-8">
          <table className="w-full table-fixed">
            <caption className="text-left mb-1">取引銀行</caption>
            <thead>
              <tr className="text-sm h-4 bg-header border-b-2 text-left">
                <th className="font-normal w-6/12 pl-2">銀行コード</th>
                <th className="font-normal w-6/12 pl-2">銀行名</th>
              </tr>
            </thead>
            <tbody>
              {FI.map((f, index) => {
                return (
                  <tr className="border-b-2 h-6" key={index}>
                    <CorporateTableData data={f.FICD} className="pl-2" />
                    <CorporateTableData data={f.FIKN} className="pl-2" />
                  </tr>
                )
              })}
            </tbody>
          </table>
          <table className="w-full mt-6 table-fixed">
            <thead>
              <tr className="text-sm h-4 bg-header border-b-2 text-left">
                <th className="font-normal w-full pl-2">店舗名</th>
              </tr>
            </thead>
            <tbody>
              {FI.map((f, index) => {
                return (
                  <tr className="border-b-2 h-6" key={index}>
                    <CorporateTableData data={f.TNKN} className="pl-2" />
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        {/* タブレット・PC画面 */}
        <div className="hidden md:block mt-8">
          <table className="w-full table-fixed">
            <caption className="text-left mb-1 -ml-3">取引銀行</caption>
            <thead>
              <tr className="text-sm h-4 bg-header border-b-2 text-left">
                <th className="font-normal w-3/12 pl-2">銀行コード</th>
                <th className="font-normal w-3/12 pl-2">銀行名</th>
                <th className="font-normal w-6/12 pl-2">店舗名</th>
              </tr>
            </thead>
            <tbody>
              {FI.map((f, index) => {
                return (
                  <tr className="border-b-2 h-6" key={index}>
                    <CorporateTableData data={f.FICD} className="pl-2" />
                    <CorporateTableData data={f.FIKN} className="pl-2" />
                    <CorporateTableData data={f.TNKN} className="pl-2" />
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </>
    )
  }
  /* ------------------------- 
    仕入先
  ----------------------------*/
  const VendorsTable: FC = () => {
    const SSK: (string | null)[] = [
      corporateInfo.SSKN_1,
      corporateInfo.SSKN_2,
      corporateInfo.SSKN_3,
      corporateInfo.SSKN_4,
      corporateInfo.SSKN_5,
      corporateInfo.SSKN_6,
    ]
    return (
      <div className="mt-8">
        <table className="w-full table-fixed">
          <caption className="text-left mb-1 md:-ml-3">仕入先</caption>
          <thead>
            <tr className="text-sm h-4 bg-header border-b-2 text-left">
              <th className="font-normal pl-2">仕入先名称</th>
            </tr>
          </thead>
          <tbody>
            {SSK.map((s, index) => {
              return (
                <tr className="border-b-2 h-6" key={index}>
                  <CorporateTableData data={s} className="pl-2" />
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
  /* ------------------------- 
    販売先
  ----------------------------*/
  const SuppliersTable: FC = () => {
    const HSK: (string | null)[] = [
      corporateInfo.HSKN_1,
      corporateInfo.HSKN_2,
      corporateInfo.HSKN_3,
      corporateInfo.HSKN_4,
      corporateInfo.HSKN_5,
      corporateInfo.HSKN_6,
    ]
    return (
      <div className="mt-8">
        <table className="w-full mt-8 table-fixed">
          <caption className="text-left mb-1 md:-ml-3">販売先</caption>
          <thead>
            <tr className="text-sm h-4 bg-header border-b-2 text-left">
              <th className="font-normal pl-2">販売先名称</th>
            </tr>
          </thead>
          <tbody>
            {HSK.map((h, index) => {
              return (
                <tr className="border-b-2 h-6" key={index}>
                  <CorporateTableData data={h} className="pl-2" />
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
  return (
    <>
      <div className="lg:flex lg:flex-row lg:gap-16 mt-8 lg:justify-between">
        <CorporateIndustryTable />
        <ProductsTable />
      </div>
      <BusinessTable />
      <BusinessPerformanceTable />
      <SalesrankTable />
      <RepresentativeTable />
      <ExecutivesTable />
      <ShareholdersTable />
      <BanksTable />
      <VendorsTable />
      <SuppliersTable />
    </>
  );
}
export default CorporateDetail;
