import { useState } from 'react';
import { DELIVER_LIST } from 'constant';

/**
 * ページネーション
 * @param {T[]} data
 * @param {number} itemsPerPage
 */
const usePagination = <T,>(data: T[]) => {
    /** 現在ページ */
    const [currentPage, setCurrentPage] = useState(1);
    /** 総ページ数 */
    const maxPage = Math.ceil(data.length / DELIVER_LIST.PER_PAGE);

    /** 現在ページのデータ */
    const currentData = () => {
        if (maxPage !== 0 && currentPage > maxPage) {
            // 絞り込みで現在ページが最大ページを超える場合、最終ページへ
            setCurrentPage(maxPage);
        }
        const begin = (currentPage - 1) * DELIVER_LIST.PER_PAGE;
        const end = begin + DELIVER_LIST.PER_PAGE;
        return data.slice(begin, end);
    }
    /** 現在ページデータの開始件数 */
    const currentBegin = () => {
        const result = (currentPage - 1) * DELIVER_LIST.PER_PAGE + 1;
        return result < 0 ? 0 : result;
    }
    /** 現在ページデータの最終件数 */
    const currentEnd = () => {
        const result = (currentPage - 1) * DELIVER_LIST.PER_PAGE + currentData().length
        return result < 0 ? 0 : result;
    }
    /** 次ページへ */
    const next = () => (
        setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage))
    )
    /** 前ページへ */
    const prev = () => (
        setCurrentPage(currentPage => Math.max(currentPage - 1, 1))
    )
    /** 指定ページへ */
    const jump = (page: number) => {
        const pageNumber = Math.max(1, page);
        setCurrentPage(currentPage => Math.min(pageNumber, maxPage));
    }

    return { next, prev, jump, currentData, currentBegin, currentEnd, currentPage, maxPage };
}

export default usePagination;