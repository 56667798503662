import axios from "axios";
import axiosRetry from "axios-retry";

/** API実行 */
const executeApi = async (
  baseURL: string,
  path: string,
  token: string | undefined,
  retries: number = 0,
  requestBody?: Object
) => {
  let response;
  const axiosInstance = axios.create({
    baseURL: baseURL
  })
  // リトライ設定
  axiosRetry(axiosInstance, {
    retries: retries,
    retryCondition: () => true,
    retryDelay: () => 1000
  });
  axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  try {
    response = await axiosInstance.post(
      path,
      requestBody,
      config
    )
  } catch (e) {
    return false;
  }
  return response;
};

export default executeApi;
