import { FC, createContext, useEffect, useContext, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import checkExpiredStatus from 'shared/checkExpiredStatus';
import CorporateOverview from "./CorporateOverview";
import CorporateDetail from "./CorporateDetail";
import useTruncated from "hooks/Truncated";
import { SessionTimeoutMsg } from 'App';
import { API, graphqlOperation } from 'aws-amplify';
import { getCorporate } from 'graphql/queries';

type CorporateTableDataProps = {
  data: string | number | null;
  className: string;
  colSpan?: number
}
type TruncatableTextProps = {
  text: string | number | null;
}
// 企業情報Context
export const CorporateInfo = createContext<CorporateInfo | undefined>({} as CorporateInfo);

/* ------------------------- 
  components
----------------------------*/
export const CorporateTableData: FC<CorporateTableDataProps> = ({ data, className, colSpan = 1 }) => {
  // 未購入項目グレーアウト
  const nutPurchased = data === null ? "bg-not-purchased" : "";
  return (
    <td className={`${className} ${nutPurchased} whitespace-nowrap`} colSpan={colSpan}>
      <TruncatableText text={data} />
    </td>
  )
}
export const TruncatableText: FC<TruncatableTextProps> = ({ text }) => {
  if (text === null) {
    text = "";
  }
  const [ref, truncated] = useTruncated(text);
  let tooltipStyle = "";
  let truncateStyle = "";
  let dataTip = "";
  if (truncated) {
    tooltipStyle = "tooltip tooltip-top block whitespace-normal";
    truncateStyle = "truncate";
    dataTip = text.toString();
  }
  return (
    <>
      <div className={`${tooltipStyle}`} data-tip={dataTip} ref={ref}>
        <p className={`${truncateStyle}`}>{text}</p>
      </div>
    </>
  )
}
const Corporate: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  /** 企業情報 */
  const [corporateInfo, setCorporateInfo] = useState<CorporateInfo>();
  /** ファイル名 */
  const [filename, setFilename] = useState<string>("");
  // セッションタイムアウトメッセージ
  const { hidden, setHidden } = useContext(SessionTimeoutMsg);
  /** 納品ID */
  const id = searchParams.get("id");
  /** アップロードID */
  const uploadId = searchParams.get("uploadId");
  /** 商品名 */
  const productName = searchParams.get("productName");
  /** 納品日付 */
  const deliveredDate = searchParams.get("deliveredDate");
  /** 有効期限日 */
  const expireDate = searchParams.get("expireDate");
  /** 企業ID */
  const corporateId = location.pathname.match(/^.+\/(.+?)\/*$/);
  /** 企業情報初期化 */
  useEffect(() => {
    setHidden('hidden');
    if (!(id && uploadId && productName && deliveredDate && expireDate && corporateId) || checkExpiredStatus(expireDate)) {
      navigate('/error');
      return;
    }
    const getCorporateInfo = async () => {
      let response;
      let extractedResData: CorporateInfo;
      try {
        response = await API.graphql(graphqlOperation(getCorporate, { id: decodeURIComponent(corporateId[1]) }));
        // @ts-ignore
        const resData = response.data.getCorporate;
        extractedResData = {
          id: resData.id,
          uploadId: resData.uploadId,
          KCD: resData.KCD,
          RK_SZKN: resData.RK_SZKN,
          HJTK_SGKT: resData.HJTK_SGKT,
          JST_SZIC_ZIP: resData.JST_SZIC_ZIP,
          JST_ADKN: resData.JST_ADKN,
          JST_SZIC_ZNTI_TEL: resData.JST_SZIC_ZNTI_TEL,
          JJ_KBN: resData.JJ_KBN,
          KBCD: resData.KBCD,
          EDCD: resData.EDCD,
          SGY_NNGT: resData.SGY_NNGT,
          STRT_YMD: resData.STRT_YMD,
          SHNKN: resData.SHNKN,
          JGYINS: resData.JGYINS,
          KJYS: resData.KJYS,
          JGYSH_S: resData.JGYSH_S,
          CHS_NNGP: resData.CHS_NNGP,
          R_CS_YMD: resData.R_CS_YMD,
          KDB_UP_YMD: resData.KDB_UP_YMD,
          SYRK_GKNY: resData.SYRK_GKNY,
          GKTK_SIJO_MI: resData.GKTK_SIJO_MI,
          HSKN_1: resData.HSKN_1,
          HSKN_2: resData.HSKN_2,
          HSKN_3: resData.HSKN_3,
          HSKN_4: resData.HSKN_4,
          HSKN_5: resData.HSKN_5,
          HSKN_6: resData.HSKN_6,
          SSKN_1: resData.SSKN_1,
          SSKN_2: resData.SSKN_2,
          SSKN_3: resData.SSKN_3,
          SSKN_4: resData.SSKN_4,
          SSKN_5: resData.SSKN_5,
          SSKN_6: resData.SSKN_6,
          YIMI_1: resData.YIMI_1,
          YSMI_1: resData.YSMI_1,
          YKSH_KGMI_1: resData.YKSH_KGMI_1,
          YIMI_2: resData.YIMI_2,
          YSMI_2: resData.YSMI_2,
          YKSH_KGMI_2: resData.YKSH_KGMI_2,
          YIMI_3: resData.YIMI_3,
          YSMI_3: resData.YSMI_3,
          YKSH_KGMI_3: resData.YKSH_KGMI_3,
          YIMI_4: resData.YIMI_4,
          YSMI_4: resData.YSMI_4,
          YKSH_KGMI_4: resData.YKSH_KGMI_4,
          YIMI_5: resData.YIMI_5,
          YSMI_5: resData.YSMI_5,
          YKSH_KGMI_5: resData.YKSH_KGMI_5,
          YIMI_6: resData.YIMI_6,
          YSMI_6: resData.YSMI_6,
          YKSH_KGMI_6: resData.YKSH_KGMI_6,
          YIMI_7: resData.YIMI_7,
          YSMI_7: resData.YSMI_7,
          YKSH_KGMI_7: resData.YKSH_KGMI_7,
          YIMI_8: resData.YIMI_8,
          YSMI_8: resData.YSMI_8,
          YKSH_KGMI_8: resData.YKSH_KGMI_8,
          YIMI_9: resData.YIMI_9,
          YSMI_9: resData.YSMI_9,
          YKSH_KGMI_9: resData.YKSH_KGMI_9,
          YIMI_10: resData.YIMI_10,
          YSMI_10: resData.YSMI_10,
          YKSH_KGMI_10: resData.YKSH_KGMI_10,
          KNMI_1: resData.KNMI_1,
          CTPR_MCWR_1: resData.CTPR_MCWR_1,
          KNMI_2: resData.KNMI_2,
          CTPR_MCWR_2: resData.CTPR_MCWR_2,
          KNMI_3: resData.KNMI_3,
          CTPR_MCWR_3: resData.CTPR_MCWR_3,
          KNMI_4: resData.KNMI_4,
          CTPR_MCWR_4: resData.CTPR_MCWR_4,
          KNMI_5: resData.KNMI_5,
          CTPR_MCWR_5: resData.CTPR_MCWR_5,
          KNMI_6: resData.KNMI_6,
          CTPR_MCWR_6: resData.CTPR_MCWR_6,
          JGNY_1: resData.JGNY_1,
          JGNY_2: resData.JGNY_2,
          JGNY_3: resData.JGNY_3,
          JGNY_4: resData.JGNY_4,
          JGNY_5: resData.JGNY_5,
          JGNY_6: resData.JGNY_6,
          JGNY_KSHR_1: resData.JGNY_KSHR_1,
          JGNY_KSHR_2: resData.JGNY_KSHR_2,
          JGNY_KSHR_3: resData.JGNY_KSHR_3,
          JGNY_KSHR_4: resData.JGNY_KSHR_4,
          JGNY_KSHR_5: resData.JGNY_KSHR_5,
          JGNY_KSHR_6: resData.JGNY_KSHR_6,
          GSCD_1: resData.GSCD_1,
          GSCD_2: resData.GSCD_2,
          GSCD_3: resData.GSCD_3,
          GSCD_4: resData.GSCD_4,
          GSCD_5: resData.GSCD_5,
          GSCD_6: resData.GSCD_6,
          GYSH_FBMI_1: resData.GYSH_FBMI_1,
          GYSH_FBMI_2: resData.GYSH_FBMI_2,
          GYSH_FBMI_3: resData.GYSH_FBMI_3,
          GYSH_FBMI_4: resData.GYSH_FBMI_4,
          GYSH_FBMI_5: resData.GYSH_FBMI_5,
          GYSH_FBMI_6: resData.GYSH_FBMI_6,
          AGCD_1: resData.AGCD_1,
          AGCD_2: resData.AGCD_2,
          AGCD_3: resData.AGCD_3,
          AGCD_4: resData.AGCD_4,
          AGCD_5: resData.AGCD_5,
          AGCD_6: resData.AGCD_6,
          ATKN_1: resData.ATKN_1,
          ATKN_2: resData.ATKN_2,
          ATKN_3: resData.ATKN_3,
          ATKN_4: resData.ATKN_4,
          ATKN_5: resData.ATKN_5,
          ATKN_6: resData.ATKN_6,
          GYSI_KSN_YM_1: resData.GYSI_KSN_YM_1,
          GYSI_MSU_1: resData.GYSI_MSU_1,
          SNTI_GYSI_URAG_1: resData.SNTI_GYSI_URAG_1,
          GYSI_ZIKB_1: resData.GYSI_ZIKB_1,
          SNTI_GYSI_REK_1: resData.SNTI_GYSI_REK_1,
          GYSK_JSHR_1: resData.GYSK_JSHR_1,
          HISG_1: resData.HISG_1,
          ZUKB_1: resData.ZUKB_1,
          GYSI_KSN_YM_2: resData.GYSI_KSN_YM_2,
          GYSI_MSU_2: resData.GYSI_MSU_2,
          SNTI_GYSI_URAG_2: resData.SNTI_GYSI_URAG_2,
          GYSI_ZIKB_2: resData.GYSI_ZIKB_2,
          SNTI_GYSI_REK_2: resData.SNTI_GYSI_REK_2,
          GYSK_JSHR_2: resData.GYSK_JSHR_2,
          HISG_2: resData.HISG_2,
          ZUKB_2: resData.ZUKB_2,
          GYSI_KSN_YM_3: resData.GYSI_KSN_YM_3,
          GYSI_MSU_3: resData.GYSI_MSU_3,
          SNTI_GYSI_URAG_3: resData.SNTI_GYSI_URAG_3,
          GYSI_ZIKB_3: resData.GYSI_ZIKB_3,
          SNTI_GYSI_REK_3: resData.SNTI_GYSI_REK_3,
          GYSK_JSHR_3: resData.GYSK_JSHR_3,
          HISG_3: resData.HISG_3,
          ZUKB_3: resData.ZUKB_3,
          URJN_TSHO_KSN_YM: resData.URJN_TSHO_KSN_YM,
          ZNKK_URJN: resData.ZNKK_URJN,
          ZNKK_URJN_TSHO_SASU: resData.ZNKK_URJN_TSHO_SASU,
          KNBT_URJN: resData.KNBT_URJN,
          KNBT_URJN_TSHO_SASU: resData.KNBT_URJN_TSHO_SASU,
          FICD_1: resData.FICD_1,
          FICD_2: resData.FICD_2,
          FICD_3: resData.FICD_3,
          FICD_4: resData.FICD_4,
          FICD_5: resData.FICD_5,
          FICD_6: resData.FICD_6,
          FICD_7: resData.FICD_7,
          FICD_8: resData.FICD_8,
          FICD_9: resData.FICD_9,
          FICD_10: resData.FICD_10,
          FIKN_1: resData.FIKN_1,
          TNKN_1: resData.TNKN_1,
          FIKN_2: resData.FIKN_2,
          TNKN_2: resData.TNKN_2,
          FIKN_3: resData.FIKN_3,
          TNKN_3: resData.TNKN_3,
          FIKN_4: resData.FIKN_4,
          TNKN_4: resData.TNKN_4,
          FIKN_5: resData.FIKN_5,
          TNKN_5: resData.TNKN_5,
          FIKN_6: resData.FIKN_6,
          TNKN_6: resData.TNKN_6,
          FIKN_7: resData.FIKN_7,
          TNKN_7: resData.TNKN_7,
          FIKN_8: resData.FIKN_8,
          TNKN_8: resData.TNKN_8,
          FIKN_9: resData.FIKN_9,
          TNKN_9: resData.TNKN_9,
          FIKN_10: resData.FIKN_10,
          TNKN_10: resData.TNKN_10,
          DHS_SIMIKN: resData.DHS_SIMIKN,
          DHS_YSMI: resData.DHS_YSMI,
          SNNGP_SRK: resData.SNNGP_SRK,
          SEBT_KBN: resData.SEBT_KBN,
          DHS_SIMIKT: resData.DHS_SIMIKT,
          DHS_KGMI: resData.DHS_KGMI,
          DHS_SHUN_YMD: resData.DHS_SHUN_YMD,
          DHS_ADD_ZIP: resData.DHS_ADD_ZIP,
          DHS_ADKN: resData.DHS_ADKN,
          DHS_JSKT: resData.DHS_JSKT,
          DHS_ZNTI_TEL: resData.DHS_ZNTI_TEL,
          TSN_KRK_KBN: resData.TSN_KRK_KBN,
          DHS_HCMI: resData.DHS_HCMI,
          DHS_SIS_EDU_GKMI: resData.DHS_SIS_EDU_GKMI,
          DHS_GRKN: resData.DHS_GRKN,
          DHS_ETMI: resData.DHS_ETMI,
          DHS_JUMI: resData.DHS_JUMI,
          DHS_HOMI_1: resData.DHS_HOMI_1,
          DHS_HOMI_2: resData.DHS_HOMI_2,
          DHS_HOMI_3: resData.DHS_HOMI_3,
          HYTN: resData.HYTN,
          DUNS: resData.DUNS,
          HJNO: resData.HJNO,
          TKSK_HKOJ_INNO: resData.TKSK_HKOJ_INNO,
          KGY_URL: resData.KGY_URL,
          SGEI: resData.SGEI,
          JST_ADEI: resData.JST_ADEI,
          DHS_SIMIEI: resData.DHS_SIMIEI,
          RSK_SCR: resData.RSK_SCR,
          KKCD: resData.KKCD,
          filename: resData.filename
        }
        setCorporateInfo(extractedResData);
        setFilename(extractedResData.filename);
      } catch (e) {
        navigate('/error');
        return;
      }
    };
    getCorporateInfo();
  }, []);
  return (
    <>
      <div className="mt-8 mb-20 px-8 md:px-16 w-full lg:w-11/12">
        <CorporateInfo.Provider value={corporateInfo} >
          <div className="lg:flex mb-12 lg:mb-8">
            <p className="text-sm">{`${productName}（${deliveredDate}）> ${filename} > 企業データ詳細`}</p>
            <div className="flex flex-col md:flex-row mr-0 ml-auto gap-4 my-3 lg:my-0">
              <button
                tabIndex={-1}
                className="btn btn-sm w-[17rem] text-white flex-0 rounded-full border-none bg-gradient-to-b from-main to-main-to"
                onClick={() => navigate(`/index/?id=${id}&uploadId=${uploadId}&productName=${productName}&deliveredDate=${deliveredDate}&expireDate=${expireDate}`)}>
                <span className="material-symbols-outlined pr-1 md:pr-3 ">expand_circle_right</span>
                <span>商品ダウンロード画面に戻る</span>
              </button>
              <button
                tabIndex={-1}
                className="btn btn-sm w-[17rem] text-white flex-0 rounded-full border-none bg-gradient-to-b from-main to-main-to"
                onClick={() =>
                  navigate(
                    `/corporatelist/?id=${id}&uploadId=${uploadId}&productName=${productName}&deliveredDate=${deliveredDate}&expireDate=${expireDate}`,
                    { state: { ratingRange: null, riskRange: null } }
                  )
                }>
                <span className="material-symbols-outlined pr-1 md:pr-3 ">database</span>
                <span>企業データ一覧画面に戻る</span>
              </button>
            </div>
          </div>
          <div className='w-full'>
            <CorporateOverview />
          </div>
          <div className='w-full'>
            <CorporateDetail />
          </div>
        </CorporateInfo.Provider>
      </div>
    </>
  );
}

export default Corporate;
